import { Module, createMapper } from 'vuex-smart-module';

import ReportsFacadeActions from '@/store/modules/reports-facade/actions';

const reportsFacade = new Module({
  actions: ReportsFacadeActions,
});

export const reportsFacadeMapper = createMapper(reportsFacade);

export default reportsFacade;
