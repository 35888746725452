import { Mutations } from 'vuex-smart-module';

import SubscriptionsState from '@/store/modules/subscriptions/state';

import { IUnsubFeedbackForm } from './state';

export default class SubscriptionsMutations extends Mutations<SubscriptionsState> {
  setUnsubFeedbackForm(form: IUnsubFeedbackForm): void {
    this.state.unsubFeedbackForm = { ...form };
  }

  clearSetUnsubFeedbackForm(): void {
    this.state.unsubFeedbackForm = {
      reasons: [],
      comment: '',
    };
  }
}
