type TErrorsText = Record<string, () => string>;
type TErrorCode = number;

export const ERRORS_PROFI_DICTIONARY = {
  22001: () => 'От Вас поступило большое количество запросов. Пожалуйста, повторите запрос позже.',
  24001: () => 'Мы не смогли найти машину с данным номером.',
  '-32602': () => 'Данный номер недействителен.',
  22002: () => 'Учетная запись заблокирована, так как Ваши действия похожи на поведение автоматических систем.',
  22003: () =>
    'Вы исчерпали пакет отчетов. Для продолжения работы вы можете сменить тариф или купить дополнительные отчеты на баланс.',
  19009: () => 'Генерация отчетов заблокирована.',
  17004: () => 'Тип продукта указан не корректно.',
  30001: () => 'Недостаточно данных для создания отчета о владельце автомобиля',
  40001: () => 'Этот номер уже кем-то занят. Повторите попытку или обратитесь в техническую поддержку.',
  40002: () => 'Этот номер уже кем-то занят. Повторите попытку или обратитесь в техническую поддержку.',
  21003: () => 'Такое значение поля email уже существует.',
  50005: () => 'Код устарел или введен не правильно.',
  50008: () => 'Попытки ввода закончились. Запросите код ещё раз.',
  50004: () => 'Попытки исчерпаны. Запросите код повторно через минуту.',
  29005: () => 'Старый пароль введён неверно.',
  29004: () => 'Старый и новый пароли не должны совпадать.',
};
export const ERRORS_ADS_DICTIONARY = {
  13002: () => `Отправка мгновенных уведомлений в Telegram невозможна.
    Создайте новый поиск с количеством объявлений до 5 000`,
};
export const DEFAULT_ERROR_MSG = 'Произошла непредвиденная ошибка';

function getDescription(code: number, errorsDescriptionDictionary: TErrorsText): string {
  const codeKey = String(code);

  if (codeKey in errorsDescriptionDictionary) {
    return errorsDescriptionDictionary[codeKey]();
  }
  return DEFAULT_ERROR_MSG;
}

export function getAdsErrorDescription(code: TErrorCode): string {
  return getDescription(code, ERRORS_ADS_DICTIONARY);
}

export function getProfiErrorDescription(code: TErrorCode): string {
  return getDescription(code, ERRORS_PROFI_DICTIONARY);
}
