export interface IAdvertBoard {
  regExp: RegExp;
  name: string;
  iconId: string;
  example: string;
}

const advertBoards: IAdvertBoard[] = [
  {
    regExp: /auto\.ru/,
    name: 'auto.ru',
    iconId: 'autoru',
    example: 'https://auto.ru/',
  },
  {
    regExp: /avito\.ru/,
    name: 'avito',
    iconId: 'avito',
    example: 'https://www.avito.ru/',
  },
  {
    regExp: /youla\.ru/,
    name: 'youla',
    iconId: 'youla',
    example: 'https://youla.ru/',
  },
  {
    regExp: /drom\.ru/,
    name: 'drom',
    iconId: 'drom',
    example: 'https://www.drom.ru/',
  },
  {
    regExp: /cars\.avtocod\.ru/,
    name: 'cars.avtocod.ru',
    iconId: 'avtocod',
    example: 'https://cars.avtocod.ru/',
  },
];

export default advertBoards;
