import { DirectiveFunction, DirectiveOptions } from 'vue';

/**
 * Директива v-click-outside
 *
 * Принимает в качестве аргумента функцию (handler) и объект со следующими опциями:
 *
 * - handler - Функция обработчик, срабатывающая при клике вне элемента
 * - middleware - Фукнция, которая определяет при каких условиях запустится функция-обработчик.
 *                Должна возвращать логическое значение (boolean)
 * - events - Массив с событиями которые должны обрабатываться, по умолчанию `click` или `touchstart`
 * - isActive - Активация и деактивация директивы, по умолчанию `true`
 *
 * Пример:
 *
 * <some-component
 *  v-click-outside="{
 *    handler: () => {
 *      // do something ...
 *    },
 *    middleware: (event) => {
 *      return event.target !== someHTMLElement;
 *    },
 *    events: ['dbclick', 'click'],
 *    isActive: false,
 *  }"
 * >
 *
 */

type TClickOutsideHandler = Function;
type TClickOutsideMiddleware = (event: Event) => boolean;
type TClickOutsideEvents = string[];

interface IClickOutsideOptions {
  handler: TClickOutsideHandler
  middleware: TClickOutsideMiddleware
  events: TClickOutsideEvents
  isActive: boolean
}

interface IClickOutsideElCache {
  event: string
  srcTarget: HTMLElement
  handler: (event: Event) => void
}

const CACHE_CLICK_OUTSIDE = '__v-click-outside__';
const IS_TOUCH = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
const EVENTS = IS_TOUCH ? ['touchstart'] : ['click'];

const initialOptions: IClickOutsideOptions = {
  handler: () => {},
  middleware: () => true,
  events: EVENTS,
  isActive: true,
};

const processDirectiveArguments = (bindingValue: IClickOutsideOptions | Function): IClickOutsideOptions => {
  const isFunction = (value: IClickOutsideOptions | Function): value is Function => typeof value === 'function';

  if (!isFunction(bindingValue) && typeof bindingValue !== 'object') {
    throw new Error(`v-click-outside: Binding value must be a function or an object, instead of ${bindingValue}`);
  }

  let options: IClickOutsideOptions = { ...initialOptions };

  if (isFunction(bindingValue)) options.handler = bindingValue;
  else options = { ...options, ...bindingValue };

  return options;
};

const execHandler = ({ event, handler, middleware }: { event: Event, handler: TClickOutsideHandler, middleware: TClickOutsideMiddleware }): void => {
  if (middleware(event)) {
    handler(event);
  }
};

const onEvent = ({
  el, event, handler, middleware,
}: { el: HTMLElement, event: Event, handler: TClickOutsideHandler, middleware: TClickOutsideMiddleware }): void => {
  const path = event.composedPath();
  const isClickOutside = path ? path.indexOf(el) < 0 : !el.contains(event.target as Node | null);

  if (!isClickOutside) return;

  execHandler({ event, handler, middleware });
};

const bind: DirectiveFunction = (el: HTMLElement, { value }): void => {
  const {
    events, handler, middleware, isActive,
  } = processDirectiveArguments(value);

  if (!isActive) return;

  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  el[CACHE_CLICK_OUTSIDE] = events.map(eventName => ({
    event: eventName,
    srcTarget: document.documentElement,
    handler: (event: Event) => onEvent({
      el, event, handler, middleware,
    }),
  }));

  // @ts-ignore
  // eslint-disable-next-line no-shadow
  el[CACHE_CLICK_OUTSIDE].forEach(({ event, srcTarget, handler }) => {
    setTimeout(() => {
      // @ts-ignore
      if (!el[CACHE_CLICK_OUTSIDE]) return;

      srcTarget.addEventListener(event, handler, false);
    }, 0);
  });
};

const unbind: DirectiveFunction = (el: HTMLElement): void => {
  // @ts-ignore
  const handlers = el[CACHE_CLICK_OUTSIDE] || [];
  handlers.forEach(({ event, srcTarget, handler }: IClickOutsideElCache) => {
    srcTarget.removeEventListener(event, handler, false);
  });
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  delete el[CACHE_CLICK_OUTSIDE];
};

const update: DirectiveFunction = (el, binding, vNode, oldVNode) => {
  if (JSON.stringify(binding.value) === JSON.stringify(binding.oldValue)) return;

  unbind(el, binding, vNode, oldVNode);
  bind(el, binding, vNode, oldVNode);
};

const directive: DirectiveOptions = {
  bind,
  unbind,
  update,
};

export default directive;
