import Vue from 'vue';

import { Mutations } from 'vuex-smart-module';

import { BankCard, UUID } from '@/rpc-types/profi';

import BankCardsState from '@/store/modules/bank-cards/state';

export default class BankCardsMutations extends Mutations<BankCardsState> {
  setBankCardsListItem({ bank_card, method = 'push' }: {
    bank_card: BankCard,
    method?: 'push' | 'unshift'
  }): void {
    this.state.list[method](bank_card);
  }

  deleteBankCardsListItem(uuid: UUID): void {
    const bankCardIndex = this.state.list.findIndex(card => card.uuid === uuid);

    if (bankCardIndex >= 0) {
      Vue.delete(this.state.list, bankCardIndex);
    }
  }

  setBankCardMain({ uuid, value }: { uuid: UUID, value: boolean}): void {
    const bankCardIndex = this.state.list.findIndex(card => card.uuid === uuid);

    if (bankCardIndex >= 0) {
      Vue.set(this.state.list[bankCardIndex], 'is_main', value);
    }
  }

  reset(): void {
    const state = new BankCardsState();
    Object.keys(state).forEach((key) => {
      // @ts-ignore
      this.state[key] = state[key];
    });
  }
}
