import { VueConstructor } from 'vue';
import MobileApp from '@/MobileApp.vue';
import store from './store';
import router from './router/mobile';

export default function mobileApp(Vue: VueConstructor) {
  return new Vue({
    router,
    store,
    render: h => h(MobileApp),
  });
}
