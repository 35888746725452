import AppSettingsStorage from '@/services/appSettingsStorage';
import RESTTransport from '@/services/httpTransport/RESTTransport';

const appSettingsStorage = new AppSettingsStorage();

const ppSpectrumClient = new RESTTransport({
  baseURL: appSettingsStorage.backendBaseUri('ppSpectrumdata'),
  headers: {
    Authorization: ` Bearer ${appSettingsStorage.backendBaseToken('ppSpectrumdata')}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}).transport;

export default ppSpectrumClient;
