// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--grid-gutter:0.8rem}.BaseHeaderButtonAction_button_DfIbh{display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:0;color:#fff;background:transparent;border:0;outline:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `BaseHeaderButtonAction_button_DfIbh`
};
export default ___CSS_LOADER_EXPORT___;
