<template>
  <BaseUniversalAlert
    :isOpen="isShowErrorAlert"
    @close="$emit('close')"
  >
    <div class="verified-alert">
      <h2 class="verified-alert__title">Спасибо!</h2>
      <h3 class="verified-alert__reason">Данные профиля подтверждены</h3>
      <p
        class="verified-alert__desc"
        :class="descriptionSizeClass"
      >
        Персональные данные всегда можно поменять в разделе «Профиль»
      </p>
      <UIButton
        w100
        type="outlined"
        :size="isMobile ? 'x14' : 'x15'"
        @click="$emit('close')"
      >
        Понятно
      </UIButton>
    </div>
  </BaseUniversalAlert>
</template>

<script lang="ts">
import { UIButton } from '@avto-internal/avtocod-meta-ui';
import { defineComponent, PropType } from 'vue';

import BaseUniversalAlert from '@/components/legacy/base/BaseUniversalAlert.vue';

export default defineComponent({
  name: 'SuccessfullyProfileVerifiedAlert',
  components: { BaseUniversalAlert, UIButton },
  props: {
    isMobile: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isShowErrorAlert: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  emits: ['close'],
  computed: {
    descriptionSizeClass(): string {
      return this.isMobile ? 'text-body-20' : 'text-body-10';
    },
  },
});
</script>

<style scoped lang="scss">
.verified-alert__title {
  margin: gridCalc(0, 0, 2);
}

.verified-alert__reason {
  margin: gridCalc(2, 0, 2);
}

.verified-alert__desc {
  margin-bottom: gridCalc(4);
}
</style>
