import advertBoards from '../../consts/advertBoards';

export const PROXY_URL = 'https://cdn.statically.io/img/';

/**
 * @description Return proxified image link
 */
export const proxyImage = (link: string, width?: number, height?: number): string => {
  const proxifiedUrl = `${PROXY_URL}${link.replace(/^(http|https):\/\//, '')}`;
  const params = {
    width: width ? `?w=${width}` : '',
    height: height ? `&h=${height}` : '',
  };
  return `${proxifiedUrl}${params.width}${params.height}`;
};

/**
 * @description normalize string to link format
 */
export const normalizeLink = (link: string): string => {
  if (link.startsWith('http://') || link.startsWith('https://')) {
    return link;
  }
  return `http://${link}`;
};

/**
 * @description Replace symbols from codes
 */
export const replaceUTFCodesToSymbols = (input: string): string => {
  const hashMap: { [key: string]: string } = {
    '&lt;': '<',
    '&gt;': '>',
  };

  const keys = Object.keys(hashMap);

  return keys.reduce((acc, key) => acc.replace(new RegExp(key, 'g'), hashMap[key]), input);
};

/**
 * @description Generate name to advert from link
 */
export const getDomainOfBoardFromLink = (link: string): string => {
  for (let i = 0; i < advertBoards.length; i += 1) {
    if (link.match(advertBoards[i].regExp)) {
      return advertBoards[i].name;
    }
  }
  // Любое количество символов // (любые символы) /
  const regExpForSiteName = /.*\/\/([^/]*).*$/;
  const defaultReturn = 'Объявление';
  const linkParsed = link.match(regExpForSiteName);
  if (linkParsed) {
    return linkParsed[1];
  }
  return defaultReturn;
};

/**
 * @description Transform string from camelCase to snake_case
 */
export const camelToSnake = (str: string): string => str.split(/(?=[A-Z])/).join('_').toLowerCase();

/**
 * @description Transform string from snake_case to camelCase
 */
export const snakeToCamel = (str: string): string => str.replace(/(_\w)/g, match => match[1].toUpperCase());

export type TRangeBound = string | number | null | undefined;

export interface IRangeOptions {
  delimiter?: string,
  fromPrefix?: string,
  toPrefix?: string,
}

/**
 * @description Формирует строку-диапазон, дополнительно принимает опции для кастомизации строки
 *
 * @example 1000 - 2000
 * @example от 1000 до 2000
 */
export const getRangeText = (from: TRangeBound, to: TRangeBound, options?: IRangeOptions): string | null => {
  const DEFAULT_OPTIONS = {
    delimiter: ' ',
    fromPrefix: '',
    toPrefix: '',
  };

  function setPrefixForRangeTextItem(prefix: string, rangeItem: string) {
    const filteredTextRangeItem = [prefix, rangeItem].filter(el => !!el?.length);
    return filteredTextRangeItem.join(' ');
  }

  const { delimiter, fromPrefix, toPrefix } = Object.assign({}, DEFAULT_OPTIONS, options);

  const fromText = from ? String(from) : null;
  const toText = to ? String(to) : null;

  const fromWithPrefix = fromText ? setPrefixForRangeTextItem(fromPrefix, fromText) : null;
  const toWithPrefix = toText ? setPrefixForRangeTextItem(toPrefix, toText) : null;

  if (fromWithPrefix || toWithPrefix) {
    const filteredTextRange = [fromWithPrefix, toWithPrefix].filter(item => !!item);
    const isNeedDelimiter = filteredTextRange.length < 2;
    return isNeedDelimiter ? filteredTextRange.join() : filteredTextRange.join(delimiter);
  }
  return null;
};
