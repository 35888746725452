import {
  ClientPassword,
  IRPCProfileDelete,
  IRPCProfileUpdate,
  IRPCProfileUpdatePassword,
  IRPCProfileUpdatePhone,
  Phone,
  Reason,
} from '@/rpc-types/profi';
import HttpClient from '@/services/httpClient/index';
import { IProfile } from '@/types/entities/profile';

export default class ProfileApi extends HttpClient {
  updateProfile = (updatedProfile: IProfile) => this.call<IRPCProfileUpdate>('profile.update', updatedProfile, 'profi');

  updatePassword = (old_password: ClientPassword, new_password: ClientPassword) =>
    this.call<IRPCProfileUpdatePassword>('profile.update.password', { old_password, new_password }, 'profi');

  updateProfilePhone = (phone: Phone) => this.call<IRPCProfileUpdatePhone>('profile.update.phone', { phone }, 'profi');

  deleteProfile = (reason: Reason) => this.call<IRPCProfileDelete>('profile.delete', { reason }, 'profi');
}
