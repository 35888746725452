import { render, staticRenderFns } from "./BaseSvg.vue?vue&type=template&id=16d89765&scoped=true"
import script from "./BaseSvg.vue?vue&type=script&lang=ts"
export * from "./BaseSvg.vue?vue&type=script&lang=ts"
import style0 from "./BaseSvg.vue?vue&type=style&index=0&id=16d89765&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d89765",
  null
  
)

export default component.exports