<template>
  <span
    class="progress-circular"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="indeterminate ? undefined : normalizedValue"
    :style="styles"
    v-on="$listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="progress-circular__svg"
      :class="{ 'progress-circular--indeterminate': indeterminate }"
      :viewBox="`${viewBoxSize} ${viewBoxSize} ${2 * viewBoxSize} ${2 * viewBoxSize}`"
      :style="svgStyles"
    >
      <defs>
        <linearGradient :id="gradientId">
          <template v-if="Array.isArray(validColor) && validColor.length > 0">
            <stop
              offset="0"
              :stop-color="validColor[0]"
              data-test="gradient-stop"
            />
            <stop
              offset="1"
              :stop-color="validColor[1]"
              data-test="gradient-stop"
            />
          </template>
        </linearGradient>
      </defs>
      <template>
        <circle
          v-if="!indeterminate"
          class="progress-circular__underlay"
          fill="transparent"
          stroke-linecap="round"
          :cx="2 * viewBoxSize"
          :cy="2 * viewBoxSize"
          :r="radius"
          stroke-dashoffset="0"
          :stroke-width="1"
          :stroke-dasharray="strokeDashArray"
        />
        <circle
          class="progress-circular__overlay"
          fill="transparent"
          stroke-linecap="round"
          :cx="2 * viewBoxSize"
          :cy="2 * viewBoxSize"
          :r="radius"
          :stroke-width="strokeWidth"
          :stroke-dasharray="indeterminate ? '' : strokeDashArray"
          :stroke-dashoffset="strokeDashOffset"
          :stroke="Array.isArray(validColor) ? `url(#${gradientId})` : validColor"
        />
      </template>
    </svg>
    <span
      v-if="pulse"
      class="progress-circular__pulse"
    />
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { generateID } from '@/utils/helpers';

export default defineComponent({
  name: 'BaseProgressCircular',
  props: {
    color: {
      type: [String, Array] as PropType<string | string[]>,
      default: '#000',
    },
    indeterminate: {
      type: Boolean,
    },
    pulse: {
      type: Boolean,
    },
    rotate: {
      type: [Number, String],
      deafult: 0,
    },
    size: {
      type: [Number, String],
      deafult: 50,
    },
    width: {
      type: [Number, String],
      default: 2,
    },
    value: {
      type: [Number, String],
      deafult: 0,
    },
  },
  data() {
    return {
      radius: 20,
      min: 0,
      max: 100,
    };
  },
  computed: {
    circumference(): number {
      return Math.PI * (this.radius * 2);
    },
    normalizedValue() {
      const numberFloated = parseFloat(this.value as string);
      if (numberFloated < this.min) {
        return this.min;
      }
      if (numberFloated > this.max) {
        return this.max;
      }

      return numberFloated;
    },
    strokeDashArray(): number {
      const magicNumber = 1000;

      return Math.round(this.circumference * magicNumber) / magicNumber;
    },
    strokeDashOffset(): string {
      return `${((100 - this.normalizedValue) / 100) * this.circumference}px`;
    },
    strokeWidth(): number {
      return (Number(this.width) / Number(this.size)) * this.viewBoxSize * 2;
    },
    styles(): { width: string; height: string; color: string | string[] | undefined } {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        color: this.validColor,
      };
    },
    svgStyles(): { transform: string } {
      return {
        transform: `rotate(${Number(this.rotate)}deg)`,
      };
    },
    viewBoxSize(): number {
      return this.radius / (1 - Number(this.width) / Number(this.size));
    },
    validColor(): string | string[] | undefined {
      const color = this.color;
      const isString = typeof color === 'string' && this.isCssColor(color);
      const isArray = Array.isArray(color) && color.length > 0 && color.every(this.isCssColor);

      return isString || isArray ? color : undefined;
    },
    gradientId(): string {
      return `progress-circular-gradient-${generateID()}`;
    },
  },
  methods: {
    isCssColor(color: string) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/);
    },
  },
});
</script>

<style lang="scss" scoped>
.progress-circular {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50%;

  &__svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  &__underlay {
    z-index: $z-idx-main;
    opacity: 0.4;
    stroke: currentColor;
  }

  &__overlay {
    z-index: $z-idx-over;
    transition: transform 0.6s ease-in-out;
  }

  &__pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 77%;
    height: 77%;
    border-radius: 50%;
    box-shadow: 0 0 rem(3px) currentColor;
    transform: translate(-50%, -50%);
  }

  &--indeterminate {
    transition: transform 0.2s ease-in-out;
    transform-origin: center center;
    animation: progress-circular-rotate 1.4s linear infinite;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
