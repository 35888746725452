<template>
  <div
    id="app"
    :class="[$style.application, $style[resolution]]"
  >
    <svg-icons />

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import SvgIcons from '@/components/legacy/base/svg/SvgIcons.vue';
import { appMapper } from '@/store/modules/app';

export default defineComponent({
  name: 'App',
  components: {
    SvgIcons,
  },
  mounted(): void {
    this.setMetaColors();

    // eslint-disable-next-line no-console
    console.log(`You use profi frontend v${this.version}`);
  },
  props: {
    resolution: {
      type: String as PropType<'desktop' | 'mobile'>,
    },
  },
  computed: {
    ...appMapper.mapGetters(['version']),
  },
  methods: {
    setMetaColors(): void {
      const selectors = ['meta[name=theme-color]', 'meta[name=msapplication-TileColor]'];

      selectors.forEach((selector) => {
        const el = document.querySelector(selector);
        if (el) {
          el.setAttribute('content', '#fff');
        }
      });
    },
  },
});
</script>

<style lang="scss">
@import './src/styles/app';
</style>

<style lang="scss" module>
.application {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &.desktop {
    min-height: 100vh;
  }
}
</style>
