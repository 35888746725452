/* eslint-disable import/no-unresolved */
import { DirectiveOptions } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

interface IFocusBinding extends DirectiveBinding {
  value?: boolean
}

export default {
  inserted(el, binding: IFocusBinding) {
    if (!binding.value) {
      return;
    }

    el.focus();
  },
} as DirectiveOptions;
