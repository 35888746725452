import { Module, createMapper } from 'vuex-smart-module';

import AuthState from '@/store/modules/auth/state';
import AuthGetters from '@/store/modules/auth/getters';
import AuthMutations from '@/store/modules/auth/mutations';
import AuthActions from '@/store/modules/auth/actions';

const auth = new Module({
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions,
});

export const authMapper = createMapper(auth);

export default auth;
