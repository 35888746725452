import Vue from 'vue';
import VueLazyload from 'vue-lazyload';

const loadImage = require('../../public/img/loader.svg');
const errorImage = require('../../public/img/brand-placeholder.svg');

Vue.use(VueLazyload, {
  loading: loadImage,
  error: errorImage,
});
