<template>
  <form
    id="reset-form"
    name="reset-form"
    class="form-reset"
    @submit.prevent="onSubmitResetForm"
  >
    <div class="form-reset__field">
      <UIInput
        id="email"
        v-model="email"
        name="email"
        placeholder="E-mail для восстановления пароля"
        type="text"
        size="x12"
        autocomplete="email"
        :category="inputCategory"
        :invalid="v$.email.$invalid"
        :state="stateEmail"
        @focus="v$.email.$reset"
        @blur="v$.email.$touch"
      />
      <BaseHint :visible="isShowNotValidEmailText" type="error">
        Введите корректный e-mail
      </BaseHint>
      <BaseHint :visible="isShowRequiredEmailText" type="error">
        Поле e-mail обязательно
      </BaseHint>
    </div>
    <UIButton v-if="!isMobile"
              class="form-reset__btn"
              type="submit"
              form="reset-form"
              :isLoading="isLoading"
              w100
              size="x15"
    >Восстановить</UIButton>
    <Portal v-else to="action">
      <UIButton
        type="submit"
        form="reset-form"
        size="x14"
        class="form-reset__btn"
        :isLoading="isLoading"
        w100
      >Восстановить</UIButton>
    </Portal>
  </form>
</template>

<script setup lang="ts">
import {
  computed, PropType,
  ref,
} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { emailValidator } from '@/utils/vuelidateRules';
import { Email } from '@/rpc-types/profi';
import { Status } from '@/types/statuses';
import BaseHint from '@/components/legacy/base/BaseHint.vue';
import {
  UIInput, UIButton,
} from '@avto-internal/avtocod-meta-ui';

interface IEmits {
  (e: 'reset-form:submit', value: { email: Email }): void,
}

const props = defineProps({
  status: {
    type: String as PropType<Status>,
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits<IEmits>();

const rules = computed(() => ({
  email: {
    required,
    emailValidator,
  },
}));

const email = ref<Email>('');
const v$ = useVuelidate(rules, { email });

async function onSubmitResetForm(): Promise<void> {
  if (isLoading.value) {
    return;
  }

  v$.value.$touch();

  if (v$.value.$invalid) return;

  emit('reset-form:submit', { email: email.value });
}

const isShowNotValidEmailText = computed(() => v$.value.email.emailValidator.$invalid && v$.value.email.$error);
const isShowRequiredEmailText = computed(() => v$.value.email.required.$invalid && v$.value.email.$error);
const stateEmail = computed(() => ((v$.value.email.$dirty && v$.value.email.$invalid) ? 'error' : ''));
const isLoading = computed(() => props.status === 'loading');
const inputCategory = computed(() => (props.isMobile ? 'invert' : 'stroke'));
</script>

<style lang="scss" scoped>
.form-reset__btn {
  margin-top: gridCalc(4);
}
</style>
