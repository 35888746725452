// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--grid-gutter:0.8rem}.BaseButton_button_ajVnA{display:flex;padding:0;border:none;outline:none;background-color:transparent;text-decoration:none;cursor:pointer}.BaseButton_button_ajVnA.BaseButton_disabled_MAfeC{pointer-events:none;cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `BaseButton_button_ajVnA`,
	"disabled": `BaseButton_disabled_MAfeC`
};
export default ___CSS_LOADER_EXPORT___;
