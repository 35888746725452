type TIOSMethods = 'onLogin' | 'onLogout' | 'onRegister' | 'onSucceededEvent' | 'onSucceededEventOwnerReport' | 'onSucceededEventNotOwnerReport' | 'onFailedEvent' | 'onInvalidUUID' | 'onSubsTermsPrivacyRules' | 'onSubsTermsUserAgreement';

type TAndroidMethods = 'onLogin' | 'copyToClipboard' | 'onRegister' | 'authFormMounted';

declare const webkit: {
  messageHandlers: {
    [key in TIOSMethods]: {
      postMessage: (...args: any[]) => void,
    }
  }
};
declare const NativeAndroid: {
  [key in TAndroidMethods]: (...args: any[]) => void
};

class webViewIOSClass {
  static onLogin({ email, password }: { email: string, password: string}): void {
    webkit.messageHandlers.onLogin.postMessage({ email, password });
  }

  static onRegister({ email, password }: { email: string, password: string}): void {
    webkit.messageHandlers.onRegister.postMessage({ email, password });
  }

  static onSucceededEvent(): void {
    webkit.messageHandlers.onSucceededEvent.postMessage(true);
  }

  static onSucceededEventOwnerReport(): void {
    webkit.messageHandlers.onSucceededEventOwnerReport.postMessage(true);
  }

  static onSucceededEventNotOwnerReport(): void {
    webkit.messageHandlers.onSucceededEventNotOwnerReport.postMessage(true);
  }

  static onFailedEvent(): void {
    webkit.messageHandlers.onFailedEvent.postMessage(true);
  }

  static onInvalidUUID(): void {
    webkit.messageHandlers.onInvalidUUID.postMessage(true);
  }

  static onSubsTermsUserAgreement(): void {
    webkit.messageHandlers.onSubsTermsUserAgreement.postMessage(true);
  }

  static onSubsTermsPrivacyRules(): void {
    webkit.messageHandlers.onSubsTermsPrivacyRules.postMessage(true);
  }

  static onLogout(): void {
    webkit.messageHandlers.onLogout.postMessage(true);
  }
}

class webViewAndroidClass {
  static async copyToClipboard(str: string): Promise<void> {
    await NativeAndroid.copyToClipboard(str);
  }

  static onLogin({ email, password }: { email: string, password: string}): void {
    NativeAndroid.onLogin(email, password);
  }

  static onRegister({ email, password }: { email: string, password: string}): void {
    NativeAndroid.onRegister(email, password);
  }

  static authFormMounted(): void {
    NativeAndroid.authFormMounted();
  }
}


export const webViewIOS = new Proxy(webViewIOSClass, {
  get(target, prop, receiver) {
    return (...args: any[]) => {
      try {
        return Reflect.get(target, prop, receiver)(...args);
      } catch (e) {} // eslint-disable-line no-empty
      return null;
    };
  },
});

export const webViewAndroid = new Proxy(webViewAndroidClass, {
  get(target, prop, receiver) {
    return async (...args: any[]) => {
      try {
        return await Reflect.get(target, prop, receiver)(...args);
      } catch (e) {} // eslint-disable-line no-empty
      return null;
    };
  },
});
