import { Getters } from 'vuex-smart-module';

import {
  ProductCarOwnerReport,
  ProductAccess,
  ProductReportPack,
  ProductSubscription,
} from '@/rpc-types/profi';

import ProductsState from '@/store/modules/products/state';

export default class ProductsGetters extends Getters<ProductsState> {
  get allProductsArray(): Array<ProductSubscription | ProductAccess | ProductReportPack | ProductCarOwnerReport> {
    return [...this.state.subscriptionsList, ...this.state.accessList, ...this.state.reportPacksList, ...this.state.carOwnerReportTypeList];
  }

  get productCarOwnerReport(): ProductCarOwnerReport {
    return this.state.carOwnerReportTypeList[0];
  }

  get isProductCarOwnerReportExist(): boolean {
    return this.state.carOwnerReportTypeList.length > 0;
  }
}
