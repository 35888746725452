import { requireAuth } from '@/router/utils';

const AboutService = () => import('@/pages/common/about/PageAboutService.vue');
const UserAgreement = () => import('@/pages/common/about/PageUserAgreement.vue');
const PrivacyPolicy = () => import('@/pages/common/about/PagePrivacyPolicy.vue');
const LicenseAgreement = () => import('@/pages/common/about/PageLicenseAgreement.vue');

export default [
  {
    path: '/about',
    name: 'about-service',
    component: AboutService,
    meta: {
      title: 'О нас',
      description: 'Сервис безлимитных детальных проверок автомобилей для профессионалов рынка. Присоединяйтесь к нам!',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/about/agreement',
    name: 'user-agreement',
    component: UserAgreement,
    meta: {
      title: 'Пользовательское соглашение',
      description: 'Сервис безлимитных детальных проверок автомобилей для профессионалов рынка — Автокод Профи',
    },
  },
  {
    path: '/about/privacy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Политика обработки персональных данных',
      description: 'Политика обработки персональных данных пользователей сервиса Автокод Профи',
    },
  },
  {
    path: '/about/license',
    name: 'license-agreement',
    component: LicenseAgreement,
    meta: {
      title: 'Лицензионное соглашение',
      description: 'Лицензионное соглашение пользователей сервиса Автокод Профи',
    },
  },
];
