/**
 * This file was automatically generated
 */

 export const empty_short_report_matrix_with_items = {
  additional_info: {
    vehicle: {
      category: {
        code: null,
        description: null
      },
      owner: {
        type: null,
        phone_number: null,
        enforcement_proceedings: {
          has_proceedings: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null,
          postal_code: null
        },
        taxi_history: {
          probable_taxi_driver: null,
          taxi_licenses: {
            items: [
              {
                vehicle: {
                  reg_num: null,
                  brand_name_original: null,
                  model_name_original: null
                },
                full_number: null,
                number: null,
                issued: null,
                region: {
                  code: null
                }
              }
            ],
            actuality: {
              date: null
            }
          },
          probable_use: {
            items: [
              {
                reg_num: null,
                date: null
              }
            ]
          }
        }
      },
      passport: {
        date: {
          receive: null
        },
        has_dublicate: null,
        number: null,
        org: {
          name: null
        }
      },
      sts: {
        date: {
          receive: null
        }
      },
      modifications: {
        was_modificated: null
      },
      notes: null,
      segment: {
        euro: {
          code: null,
          description: null
        }
      },
      exported: null,
      techreglament_category: {
        code: null,
        description: null
      }
    },
    catalog: {
      transdekra: {
        items: [
          {
            rsa_code: null,
            modification_id: null,
            relevance: null
          }
        ]
      },
      rsa: {
        items: [
          {
            code: {
              rsa: null,
              transdekra: null,
              vehicle: null
            },
            relevance: null,
            vehicle: {
              type: null,
              brand: {
                name: null
              },
              model: {
                name: null
              },
              modifications: {
                name: null
              },
              transmission: {
                type: null
              },
              drive: {
                type: null
              },
              engine: {
                type: null,
                power: {
                  hp: null
                },
                volume: null
              },
              doors: {
                count: null
              },
              seats: {
                count: null
              },
              weight: {
                max: null
              },
              category: {
                code: null
              },
              body: {
                type: null
              },
              manufactured: {
                year: {
                  start: null,
                  end: null
                }
              }
            }
          }
        ]
      },
      tecdoc: {
        items: [
          {
            manufacturer_id: null,
            model_id: null,
            id: null,
            name: null,
            description: null
          }
        ]
      },
      oats: {
        items: [
          {
            code: null,
            vehicle: {
              brand: {
                name: null
              },
              model: {
                name: null
              },
              modification: {
                name: null
              },
              engine: {
                model: {
                  name: null
                },
                type: null,
                power: {
                  hp: null,
                  kw: null
                },
                volume: null
              },
              category: {
                name: null
              },
              manufactured: {
                year: {
                  start: null,
                  end: null
                }
              }
            }
          }
        ]
      }
    },
    identifiers: {
      vin: {
        checksum_validated: null
      }
    },
    driver: {
      mileages: {
        average_annual: {
          value: null,
          calculation_options: {
            period: {
              start: null,
              end: null
            },
            vehicle: {
              count: null
            },
            mileages: {
              count: null
            }
          },
          is_calculated: false
        }
      }
    }
  },
  identifiers: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    },
    manufacture: {
      vin: null
    }
  },
  images: {
    photos: {
      items: [
        {
          vehicle: {
            brand: {
              name: null
            },
            model: {
              name: null
            }
          },
          date: {
            issued: null
          },
          uri: null
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  tech_data: {
    manufacturer: {
      name: null
    },
    brand: {
      id: null,
      name: {
        original: null,
        rus: null,
        normalized: null
      },
      logotype: {
        uri: null
      }
    },
    model: {
      id: null,
      name: {
        original: null,
        normalized: null
      }
    },
    type: {
      name: null,
      type_id: null,
      code: null
    },
    body: {
      number: null,
      color: {
        name: null,
        type: null
      }
    },
    year: null,
    chassis: {
      number: null
    },
    engine: {
      fuel: {
        type: null,
        type_id: null
      },
      number: null,
      model: {
        name: null
      },
      volume: null,
      power: {
        hp: null,
        kw: null
      },
      standarts: {
        emission: {
          euro: null
        }
      }
    },
    weight: {
      netto: null,
      max: null
    },
    transmission: {
      type: null,
      type_id: null
    },
    drive: {
      type: null,
      type_id: null
    },
    wheel: {
      position: null,
      position_id: null,
      position_code: null
    },
    generations: [
      {
        id: null,
        name: null,
        is_restyling: null,
        years: {
          start: null,
          end: null
        },
        bodies: [
          {
            id: null,
            type: null,
            modifications: [
              {
                id: null,
                name: null,
                engine: {
                  fuel: {
                    type: null
                  },
                  power: {
                    hp: null
                  },
                  volume: null
                },
                transmission: {
                  type: null
                },
                drive: {
                  type: null
                }
              }
            ]
          }
        ]
      }
    ],
    date: {
      update: null
    }
  }
}