<template>
  <component
    :is="tag"
    :to="to || false"
    :class="[$style.button, { [$style.disabled]: disabledProp }]"
    v-bind="$attrs"
    @mouseover="$emit('mouseover', $event)"
    @mouseout="$emit('mouseout', $event)"
    @click="handleClick"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';

export default defineComponent({
  name: 'BaseButton',
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: '',
    },
    disabledProp: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    tag(): string {
      return this.to ? 'router-link' : 'button';
    },
  },
  methods: {
    handleClick(event: MouseEvent): void {
      if (this.disabledProp) {
        event.preventDefault();

        return;
      }

      this.$emit('click', event);
    },
  },
});
</script>

<style lang="scss" module>
$button-disabled-color: lighten($border-color, 7%);

.button {
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>
