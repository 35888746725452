import Vue from 'vue';

import dateOwners from '@/filters/date-owners';
import digit from '@/filters/digit';
import unit from '@/filters/unit';
import range from '@/filters/range';
import plural from '@/filters/plural';
import phoneNumber from '@/filters/phone-number';
import dayjsFormat from '@/filters/dayjsFormat';

Vue.filter('dateOwners', dateOwners);
Vue.filter('digit', digit);
Vue.filter('unit', unit);
Vue.filter('range', range);
Vue.filter('plural', plural);
Vue.filter('phoneNumber', phoneNumber);
Vue.filter('dayjs', dayjsFormat);

Vue.prototype.$filters = (Vue as any).options.filters;
