import { Sort, Pagination } from '@/rpc-types/profi';

// eslint-disable-next-line import/prefer-default-export
export const SEARCH_PARAMS = {
  get DEFAULT_SORT(): Sort {
    return {
      order: 'desc',
      key: 'created_at',
    };
  },
  get DEFAULT_PAGINATION(): Pagination {
    return {
      page: 1,
      limit: 20,
    };
  },
};
