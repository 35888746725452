import { Actions } from 'vuex-smart-module';

import {
  CardCryptogram, Ip, Auth3Ds,
  UUID, BankCard,
} from '@/rpc-types/profi';
import { IRPCError } from '@/services/httpClient/types';

import BankCardsState from '@/store/modules/bank-cards/state';
import BankCardsGetters from '@/store/modules/bank-cards/getters';
import BankCardsMutations from '@/store/modules/bank-cards/mutations';

import BankCardsApi from '@/services/httpClient/api/profi/bank-cards.api';

export default class BankCardsActions extends Actions<
  BankCardsState,
  BankCardsGetters,
  BankCardsMutations,
  BankCardsActions
> {
  api!: BankCardsApi;

  $init(): void {
    this.api = new BankCardsApi();
  }

  async addBankCard({ card_cryptogram, ip }: {
    card_cryptogram: CardCryptogram,
    ip?: Ip
  }): Promise<{ bank_card: BankCard } | { auth3ds: Auth3Ds } | { error: IRPCError } | {}> {
    let response = {};
    const { data: { result, error } } = await this.api.addBankCard(card_cryptogram, ip);

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('addBankCard error: ', error);
      response = { error };
    }

    if (result) {
      if (result.bank_card) {
        if (this.state.list.some(card => card.uuid === result.bank_card!.uuid)) {
          return {
            error: {
              data: {
                message: 'Эта карта уже есть в вашем списке',
              },
            },
          };
        }
        this.mutations.setBankCardsListItem({ bank_card: result.bank_card });
        response = { bank_card: result.bank_card };
      }

      if (result.auth_3ds) {
        response = { auth3ds: result.auth_3ds };
      }
    }

    return response;
  }

  async updateMainCard(uuid: UUID): Promise<void> {
    const prevMainCard = this.state.list.find(card => card.is_main);

    this.mutations.setBankCardMain({ uuid, value: true });
    if (prevMainCard) {
      this.mutations.setBankCardMain({ uuid: prevMainCard.uuid, value: false });
    }

    const { data: { error } } = await this.api.setMainBankCard(uuid);
    if (error) {
      this.mutations.setBankCardMain({ uuid, value: false });
      if (prevMainCard) {
        this.mutations.setBankCardMain({ uuid: prevMainCard.uuid, value: true });
      }
    }
  }

  async deleteBankCard(uuid: UUID): Promise<void> {
    const deletedCard = this.state.list.find(card => card.uuid === uuid);

    if (deletedCard) {
      this.mutations.deleteBankCardsListItem(uuid);

      const { data: { error } } = await this.api.deleteBankCard(uuid);

      if (error) {
        // eslint-disable-next-line no-console
        console.warn('deleteBankCard error: ', error);
        this.mutations.setBankCardsListItem({ bank_card: deletedCard });
      }
    }
  }
}
