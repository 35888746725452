import dayjs from 'dayjs';
import preciseDiff from 'dayjs-precise-range';
import plural from '../../filters/plural';

interface IDiffDatesOptions { years: boolean, months: boolean, days: boolean }
interface IDiffDatesDuration { value: number, active: boolean, plurals: [string, string, string] }

dayjs.extend(preciseDiff);

/**
 * @description transform amount of month to string format (x лет y месяцев)
 */
export const transformMonthToString = (value: number): string => {
  const years = Math.floor(value / 12);
  const months = value % 12;
  const explotationInString = `${years ? `${plural(years, ['год', 'года', 'лет'])}` : ''}${years && months ? ' ' : ''}${months ? `${plural(months, ['месяц', 'месяца', 'месяцев'])}` : ''}`;
  return explotationInString || '0\u00A0месяцев';
};
/**
 * @description transform period object to array format ['x лет', 'x месяцев', 'x дней']
 */
export const convertPeriodObjectToStringArray = (period: dayjs.PreciseRangeValueObject, options: IDiffDatesOptions): string[] => {
  const isDaysOnly = !period.years && !period.months;

  const durations: Array<IDiffDatesDuration> = [
    { value: period.years, active: options.years, plurals: ['год', 'года', 'лет'] },
    { value: period.months, active: options.months, plurals: ['месяц', 'месяца', 'месяцев'] },
    { value: period.days, active: options.days || isDaysOnly, plurals: ['день', 'дня', 'дней'] },
  ];

  const format: string[] = [];

  durations.forEach((dur: IDiffDatesDuration) => {
    if (dur.value > 0 && dur.active) {
      format.push(plural(dur.value, dur.plurals));
    }
  });
  return format;
};

/**
 * @description return diff of 2 dates in form (x лет y месяцев z дней)
 */
export const getAccurateDiffDates = (startDate: string, endDate: string | null, options?: IDiffDatesOptions): string => {
  const start = dayjs(startDate);
  const end = dayjs(endDate || new Date());

  const period = dayjs.preciseDiff(start, end, true);

  const defaultOptions: IDiffDatesOptions = { years: true, months: true, days: true };

  const format = convertPeriodObjectToStringArray(period, options || defaultOptions);

  return format.length ? `${format.join('\u00A0')}` : '1\u00A0день';
};

export const getApproximateDiffDates = (period: number): string => {
  const start = dayjs(new Date(0));
  const end = dayjs(new Date(period));

  const preciseRange = dayjs.preciseDiff(start, end, true);
  const format = convertPeriodObjectToStringArray(preciseRange, { days: false, months: true, years: true });

  return format.length ? `${format.join('\u00A0')}` : '1\u00A0день';
};

/**
 * @description generate date of car release from year and last action
 */
export const generateDateOfCarRelease = (releaseYear: number, lastAction: string | null): string => {
  if (!lastAction) {
    return dayjs(`${releaseYear}-07-01`).toISOString();
  }

  const lastActionDate = dayjs(lastAction);
  if (lastActionDate.year() > releaseYear) {
    return dayjs(`${releaseYear}-07-01`).toISOString();
  }
  if (lastActionDate.month() === 1 && lastActionDate.day() <= 15) {
    return lastActionDate.toISOString();
  }
  return dayjs(lastActionDate.valueOf() - ((lastActionDate.valueOf() - dayjs(`${releaseYear}-01-01`).valueOf()) / 2)).toISOString();
};

/**
 * @description transform ISO day week to local format day week (0 - Monday)
 */
export const transformISOtoLocalDayWeek = (day: number) => (day > 0 && day <= 6 ? day - 1 : 6);

/**
 * @description в некоторых блоках отчета дата приходит в формате `1980-01-01 01:00`
 * на беке на данный момент нет возможности изменить формат даты.
 * Данный хелпер приводит дату получаемую с бека в формат ISO
 */

export const formatB2BDateToISO = (date: string): string | null => {
  const b2bDateRegexp = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  try {
    if (b2bDateRegexp.test(date)) {
      return `${date.replace(' ', 'T')}+00:00`;
    }
    throw new Error('Not valid date');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
