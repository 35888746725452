import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';

import { Payment } from '@/rpc-types/profi';

import PaymentState from '@/store/modules/payments/state';

export default class PaymentMutations extends Mutations<PaymentState> {
  setPaymentsHistoryItem({ payment, method = 'push' }: {
    payment: Payment,
    method?: 'push' | 'unshift'
  }): void {
    this.state.history[method](payment);
  }

  clearPaymentsHistory(): void {
    Vue.set(this.state, 'history', []);
  }

  reset(): void {
    const state = new PaymentState();
    Object.keys(state).forEach((key) => {
      // @ts-ignore
      this.state[key] = state[key];
    });
  }
}
