import Vue, { DirectiveOptions } from 'vue';

const CACHE_SCROLL_TO = '__vueScrollSaveViewport__';
const DEFAULT_SCROLLER_ELEMENT = document.documentElement;

/**
 * Директива v-scroll-save-viewport
 *
 * Директива запоминает положение target элемента относительно viewport'a экрана
 * пользователя, выполняет переданный callbackHandler и возвращает target
 * на тоже место относительно viewport'а, скроля scrollerElement.
 *
 * Директива принимает в качестве аргумента объект со следующими ключами:
 * - targetId?: string - id target элемента, если его не передать, то
 *              в качестве target элемента принимается тот DOM элемент,
 *              на котором задействована директива
 * - scrollerElement?: HTMLElement - DOM элемента на которым происходит скролл,
 *              если его не передать, то директива продолжает работу по ветке
 *              поиска scrollerElement через scrollerElementId
 * - scrollerElementId?: string | null  - id DOM элемента на которым происходит
 *              скролл, если его не передать, то в качестве scrollerElement
 *              будет взят document.documentElement (HTML - узел).
 *              Если передан scrollerElement, то scrollerElementId игнорируется
 * - callbackHandler?: function - функция которая выполняется после запоминания
 *              положения target элемента относительно viewport'a экрана
 * - callbackSaveTargetPosition?:CallbackSaveTargetPosition -
 *              если нужно переопределить логику запоминания положения
 *              target элемента относительно viewport'a, должна вернуть
 *              отступ (number) относительно верха viewport'a
 *              Дефолтное поведение определено в директиве
 * - callbackRecoveryTargetPosition?:CallbackRecoveryTargetPosition -
 *              если нужно переопределить логику возврата положения
 *              target элемента относительно viewport'a
 *              Дефолтное поведение определено в директиве
 */

type CallbackSaveTargetPosition = (
  target: HTMLElement,
  scrollerElement: HTMLElement,
) => number

type CallbackRecoveryTargetPosition = (
  target: HTMLElement,
  scrollerElement: HTMLElement,
  viewPortTargetPosition: number,
) => void

function saveScrollHandler(
  target: HTMLElement,
  scrollerElement: HTMLElement,
  callbackHandler?: Function,
  callbackSaveTargetPosition?: CallbackSaveTargetPosition,
  callbackRecoveryTargetPosition?: CallbackRecoveryTargetPosition,
) {
  const saveViewPort = callbackSaveTargetPosition || saveViewPortTargetPosition;
  const viewPortTargetPosition = saveViewPort(target, scrollerElement);

  if (callbackHandler) {
    callbackHandler();
  }

  Vue.nextTick()
    .then(() => {
      const recoveryView = callbackRecoveryTargetPosition || recoveryViewPortTargetPosition;
      recoveryView(target, scrollerElement, viewPortTargetPosition);
    });
}

export const saveViewPortTargetPosition: CallbackSaveTargetPosition = (
  target,
  scrollerElement,
) => {
  const { offsetTop: targetOffsetBefore } = target;
  const scrollerElementTop = scrollerElement.scrollTop;
  return targetOffsetBefore - scrollerElementTop;
};

export const recoveryViewPortTargetPosition: CallbackRecoveryTargetPosition = (
  target: HTMLElement,
  scrollerElement: HTMLElement,
  viewPortTargetPosition: number,
) => {
  const { offsetTop: btnOffsetAfter } = target;
  scrollerElement.scroll(0, btnOffsetAfter - viewPortTargetPosition);
};

export default {
  inserted(el, binding) {
    const target = binding.value?.targetId
      ? document.getElementById(binding.value.targetId) as HTMLElement
      : el;

    // scrollerElement
    let scrollerElement: HTMLElement;
    if (binding.value?.scrollerElement) {
      // eslint-disable-next-line prefer-destructuring
      scrollerElement = binding.value.scrollerElement;
    } else {
      scrollerElement = binding.value?.scrollerElementId
        ? document.getElementById(binding.value.scrollerElementId) as HTMLElement
        : DEFAULT_SCROLLER_ELEMENT;
    }

    const {
      callbackHandler,
      callbackSaveTargetPosition,
      callbackRecoveryTargetPosition,
    } = binding.value;

    if (target) {
      const handler = () => saveScrollHandler(
        target,
        scrollerElement,
        callbackHandler,
        callbackSaveTargetPosition,
        callbackRecoveryTargetPosition,
      );

      el.addEventListener('click', handler);
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      el[CACHE_SCROLL_TO] = handler;
    }
  },

  unbind(el) {
    // @ts-ignore
    el.removeEventListener('click', el[CACHE_SCROLL_TO]);
  },
} as DirectiveOptions;
