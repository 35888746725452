<script lang="ts">
import Vue from 'vue';
import createGrid from '@/grid/createGrid';

export default Vue.extend({ ...createGrid('g-row') });
</script>

<style lang="scss" scoped>
.g-row {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;

  &.nav-bar {
    padding-bottom: rem($bar-height);
  }

  &.header {
    padding-top: rem($bar-height);
  }

  &.float-button {
    padding-bottom: rem($bar-height + $grid-gutter * 3 + $button-float-size);

    @include media-min($button-float-breakpoint) {
      padding-bottom: rem($bar-height + $grid-gutter * 3 + $button-float-size-lg);
    }
  }
}
</style>
