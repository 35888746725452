import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import {
  AuthRegisterResponse,
  Client,
  ClientPassword,
  Email,
  FirstName,
  LastName,
  Phone,
  ResendVerificationCodeResponse,
  UUID,
  VerificationCode,
  VerifyProfileResponse,
} from '@/rpc-types/profi';
import authStorage from '@/services/authTokenStorage';
import { setUserIdCybertonica } from '@/services/cybertonica';
import AuthApi from '@/services/httpClient/api/profi/auth.api';
import { IRPCError } from '@/services/httpClient/types';
import AuthGetters from '@/store/modules/auth/getters';
import AuthMutations from '@/store/modules/auth/mutations';
import AuthState from '@/store/modules/auth/state';
import profile from '@/store/modules/profile';

export default class AuthActions extends Actions<AuthState, AuthGetters, AuthMutations, AuthActions> {
  profile!: Context<typeof profile>;
  api!: AuthApi;

  $init(store: Store<unknown>): void {
    this.profile = profile.context(store);
    this.api = new AuthApi();
  }

  async login({ email, password }: { email: Email; password: ClientPassword }): Promise<Client | void> {
    const {
      data: { error, result },
    } = await this.api.login(email, password);

    if (error) {
      throw new Error(error.data?.message || error.message);
    }

    if (result) {
      authStorage.token = result.token;
      this.profile.mutations.setProfile(result);
      setUserIdCybertonica(result.uuid);
      if (!result.is_phone_verified) {
        this.profile.mutations.setNotVerifiedUuid(result.uuid);
      }
    }
    return result;
  }

  async register({
    email,
    phone,
    password,
    firstName,
    lastName,
  }: {
    email: Email;
    phone: Phone;
    password: ClientPassword;
    firstName?: FirstName;
    lastName?: LastName;
  }): Promise<void | AuthRegisterResponse> {
    const {
      data: { error, result },
    } = await this.api.register(email, phone, password, firstName, lastName);

    if (error) {
      throw error;
    }

    if (result) {
      this.profile.mutations.setNotVerifiedUuid(result.client_verify_uuid);
      setUserIdCybertonica(result.client_verify_uuid);
    }
    return result;
  }

  async verifyProfile({
    uuid,
    verificationCode,
  }: {
    uuid: UUID;
    verificationCode: VerificationCode;
  }): Promise<void | VerifyProfileResponse> {
    const {
      data: { error, result },
    } = await this.api.verifyProfile(uuid, verificationCode);

    if (error) {
      throw error;
    }

    if (result) {
      authStorage.token = result.token;
      this.profile.mutations.setProfile(result);
      setUserIdCybertonica(result.uuid);
    }
    return result;
  }

  async resendVerificationCode(uuid: UUID): Promise<IRPCError | ResendVerificationCodeResponse> {
    const {
      data: { error, result },
    } = await this.api.resendVerificationCode(uuid);

    if (error) {
      throw error;
    }
    return result;
  }

  logout(): void {
    authStorage.logout();
  }

  // restore(email: string) {
  //   return apiAuth.restore(email);
  // }
  //
  // resetPassword({ token, password }: { token: string, password: string }) {
  //   return apiAuth.resetPassword(token, password);
  // }
}
