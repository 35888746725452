import { Module, createMapper } from 'vuex-smart-module';

import ClientsState from '@/store/modules/clients/state';
import ClientsGetters from '@/store/modules/clients/getters';
import ClientsMutations from '@/store/modules/clients/mutations';
import ClientsActions from '@/store/modules/clients/actions';

const clients = new Module({
  state: ClientsState,
  getters: ClientsGetters,
  mutations: ClientsMutations,
  actions: ClientsActions,
});

export const clientsMapper = createMapper(clients);

export default clients;
