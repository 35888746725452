<template>
  <div class="container-form">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContainerLoginMain',
});
</script>

<style lang="scss" scoped>
.container-form {
  width: 384px;
}
</style>
