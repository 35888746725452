import TokenApi from '@/services/httpClient/api/profi/token.api';

const tokenApi = new TokenApi();

// eslint-disable-next-line import/prefer-default-export
export async function refreshCentrifugeTokenHandler(): Promise<string> {
  let token = '';

  try {
    const { data } = await tokenApi.getToken();
    const { result } = data;

    token = result?.token || '';
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error refresh token: ', e);
  }

  return token;
}
