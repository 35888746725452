<template>
  <div :class="contentClasses">
    <div
      ref="msg"
      :class="$style.msg"
    >
      {{ message.msg }}
    </div>

    <g-row v-if="message.timer">
      <div :class="$style.loader">
        <base-progress-circular
          :color="progressColor"
          :value="timerValue"
          rotate="-90"
        >
          <div
            ref="timer"
            :class="$style.timer"
          >
            {{ currentTime }}
          </div>
        </base-progress-circular>
      </div>

      <button
        ref="btn-cancel"
        :class="$style.buttonAction"
        @click="onClickAction"
      >
        Отменить
      </button>
    </g-row>

    <div
      ref="btn-close"
      :class="$style.buttonClose"
      @click="close"
    >
      <base-svg
        ref="svg"
        icon-id="close"
        :class="$style.icon"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { INotification } from '@/types/components/common-app-notification';

import BaseSvg from '@/components/legacy/base/svg/BaseSvg.vue';

import BaseProgressCircular from '@/components/legacy/base/BaseProgressCircular.vue';
import { appMapper } from '@/store/modules/app';

interface IData {
  defaultValue: number;
  currentTime: number;
  interval: number;
}

export default defineComponent({
  name: 'AppNotificationItem',
  components: {
    BaseSvg,
    BaseProgressCircular,
  },
  props: {
    message: {
      type: Object as PropType<INotification>,
      default: () => ({}),
    },
  },
  data(): IData {
    const defaultValue = 3;

    return {
      defaultValue: defaultValue,
      currentTime: this.message.time || defaultValue,
      interval: 0,
    };
  },
  mounted() {
    this.interval = window.setInterval(() => {
      this.currentTime -= 1;

      if (this.currentTime === 0) {
        this.close();
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  computed: {
    ...appMapper.mapState(['isMobile']),
    timerValue(): number {
      return (this.currentTime / (this.message.time || this.defaultValue)) * 100;
    },
    contentClasses() {
      const classes = [];
      classes.push(this.$style.content);

      if (this.isMobile) {
        classes.push(this.$style.contentMobile);
      }

      return classes;
    },
    progressColor(): string {
      return this.$style.lockedColor;
    },
  },
  methods: {
    onClickAction(): void {
      this.$emit('click-action', this.message.id);
      this.close();
    },
    close(): void {
      this.$emit('close', this.message.id);
    },
  },
});
</script>

<style lang="scss" module>
// stylelint-disable property-no-unknown
:export {
  lockedColor: $brand-color;
}

// stylelint-enable
.content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 700px;
  padding: rem($grid-gutter) 0 rem($grid-gutter) rem($grid-gutter * 2);
  color: $text-color;
  background-color: $neutral-color;
  box-shadow: 0 0 rem(10px) rgba(12, 69, 111, 0.15);
}

.contentMobile {
  width: calc(100% - #{$grid-gutter * 4});
  color: $neutral-color;
  white-space: normal;
  background-color: $text-color;
  border-radius: rem(5px);
  box-shadow: 0 0 15px rgba(26, 50, 135, 0.2);
}

.msg {
  font-size: rem(16px);
}

.buttonAction {
  margin-left: rem($grid-gutter * 2);
  font-size: rem(16px);
  color: $brand-color;
  background: transparent;
  border: 0;
  outline: 0;

  &:hover {
    color: darken($brand-color, 10%);
    cursor: pointer;
  }
}

.buttonClose {
  width: rem($grid-gutter * 2);
  height: rem($grid-gutter * 2);
  margin: rem($grid-gutter * 3);
}

.icon {
  width: rem(16px);
  height: rem(16px);
  fill: $border-color;

  &:hover {
    cursor: pointer;
    fill: $brand-color;
  }
}

.timer {
  font-size: rem(14px);
  color: $brand-color;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(27px);
  height: rem(27px);
  margin-left: rem($grid-gutter * 3);
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss" scoped>
.push {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  &-enter-active,
  &-move,
  &-leave-active {
    transition:
      transform 0.25s ease,
      opacity 1s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    position: absolute;
    transform: translate(-100%, -100%);
  }
}
</style>
