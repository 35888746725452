import { requireAuth, requireProductExist } from '@/router/utils';
import multiguard from 'vue-router-multiguard';

const PageSubs = () => import('@/pages/common/subs/PageSubscription/PageSubscription.vue');
const PageSubsManagement = () => import('@/pages/desktop/subs/PageSubsManagement.vue');
const PageSubsUnsub = () => import('@/pages/common/subs/PageSubsUnsub.vue');
const PageSubsPayment = () => import('@/pages/common/subs/PageSubscriptionPayments/PageSubscriptionPayments.vue');
const PageSubscriptionPaymentsUnavailable = () => import('@/pages/common/subs/PageSubscriptionPayments/PageSubscriptionPaymentsUnavailable.vue');
const PageSubsOptions = () => import('@/pages/common/subs/PageSubsOptions.vue');
const PageSubsHistory = () => import('@/pages/desktop/subs/PageSubsHistory.vue');
const PageSubs3dsMiddleware = () => import('@/pages/desktop/subs/PageSubs3dsMiddleware.vue');
const PageSubscriptionTariffs = () => import('@/pages/common/subs/PageSubscriptionTariffs/PageSubscriptionTariffs.vue');
const PageReportsPack = () => import('@/pages/common/subs/PageReportsPack/PageReportsPack.vue');

export default [
  {
    path: '/subs',
    name: 'subs',
    component: PageSubs,
    meta: {
      title: 'Подписка и тарифы',
      description: 'Ежемесячная подписка на безлимитное количество проверок автомобилей для профессионалов — Автокод Профи',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/subs/tariffs',
    name: 'subs-tariffs',
    component: PageSubscriptionTariffs,
    beforeEnter: requireAuth,
    meta: {
      title: 'Тарифы',
    },
  },
  {
    path: '/subs/tariffs/payments',
    name: 'subs-tariffs-payments',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата',
    },
  },
  {
    path: '/subs/management',
    name: 'subs-management',
    component: PageSubsManagement,
    meta: {
      title: 'Способ оплаты и автопродление',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/subs/management/payments',
    name: 'subs-management-payments',
    component: PageSubsPayment,
    beforeEnter: multiguard([requireAuth, requireProductExist]),
    meta: {
      title: 'Возобновление подписки',
    },
  },
  {
    path: '/subs/management/unavailable',
    name: 'subs-management-unavailable',
    component: PageSubscriptionPaymentsUnavailable,
    beforeEnter: requireAuth,
    meta: {
      title: 'Возобновление подписки',
    },
  },
  {
    path: '/subs/management/unsub',
    name: 'subs-unsub',
    component: PageSubsUnsub,
    meta: {
      title: 'Отмена подписки',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/subs/additional-reports',
    name: 'subs-additional-reports',
    component: PageReportsPack,
    beforeEnter: requireAuth,
    meta: {
      title: 'Дополнительные отчеты',
    },
  },
  {
    path: '/subs/additional-reports/payment',
    name: 'subs-additional-reports-payment',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата отчетов',
    },
  },
  {
    path: '/subs/management/options',
    name: 'subs-options',
    component: PageSubsOptions,
    meta: {
      title: 'Настройки платежа',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/subs/history',
    name: 'subs-history',
    component: PageSubsHistory,
    meta: {
      title: 'История покупок',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/subs/reg',
    name: 'subs-reg',
    redirect: { name: 'guest-buy' },
  },
  {
    path: '/subs/auth',
    name: 'subs-auth',
    redirect: { name: 'guest-buy' },
  },
  {
    path: '/subs/3ds-middleware',
    name: 'subs-3ds-middleware',
    component: PageSubs3dsMiddleware,
    meta: {
      layout: 'LayoutEmpty',
    },
  },
];
