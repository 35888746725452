import { TRoutes } from '@/services/httpClient/types';

// Base URI to backend (only base URI, with schema but without URI path).
// This URI will be used for requests sending to the backend
type TBackendBaseUri = string;
// RPC route is a relative path for request sending the backend (a usual - `/rpc`)
type TBackendRpcRoute = string;

type TBackendBaseToken = string;

type TTokenRoutes = 'ppSpectrumdata';

// Environment is used for setting "application mode" (different modes may enable/disable some features or services).
// For more information look into application readme file or documentation
type TEnvironment = 'development' | 'review' | 'staging' | 'production';

// URL for reset user password. It can be URI to another domain
type TUserPasswordResetUri = string;

// Sentry DSN (Data Source Name). The DSN is the first and most important thing to configure
// because it tells the SDK where to send events.
// @see https://docs.sentry.io/clients/java/config/#setting-the-dsn
type TSentryDsn = string;

// Public key for CloudPayments service
// @see https://developers.cloudpayments.ru/#ustanovka-vidzheta
type TCloudPaymentsPublicKey = string;

interface IAppSettings {
  backend: {
    uris: {
      [key in TRoutes]: TBackendBaseUri;
    };
    tokens: {
      [key in TTokenRoutes]: TBackendBaseToken;
    };
    rpc_route: TBackendRpcRoute;
  };
  external: {
    uris: {
      [key in string]: string;
    };
  };
  environment: TEnvironment;
  user: {
    password_reset_uri: TUserPasswordResetUri;
  };
  sentry: {
    sentry_dsn: TSentryDsn;
  };
  cloudpayments: {
    public_key: TCloudPaymentsPublicKey;
  };
  insapp: {
    insapp_url: string;
    insapp_key: string;
  };
  cybertonica: {
    cybertonica_team: string;
  };
}

interface IAppSettingsStorage {
  readonly backendBaseUri: (route: TRoutes) => TBackendBaseUri;
  readonly backendRpcRoute: TBackendRpcRoute;
  readonly environment: TEnvironment;
  readonly userPasswordResetUri: TUserPasswordResetUri;

  readonly sentryDsn: TSentryDsn;

  readonly cloudPaymentsPublicKey: TCloudPaymentsPublicKey;
}

declare global {
  interface Window {
    appSettings: IAppSettings;
  }
}

export default class AppSettingsStorage implements IAppSettingsStorage {
  private settings: IAppSettings = {
    backend: {
      uris: {
        profi: '',
        ppSpectrumdata: '',
      },
      tokens: {
        ppSpectrumdata: '',
      },
      rpc_route: '',
    },
    external: {
      uris: {
        centrifuge: '',
      },
    },
    environment: 'development',
    user: {
      password_reset_uri: '',
    },
    sentry: {
      sentry_dsn: '',
    },
    cloudpayments: {
      public_key: '',
    },
    insapp: {
      insapp_url: '',
      insapp_key: '',
    },
    cybertonica: {
      cybertonica_team: '',
    },
  };

  constructor() {
    this.settings = { ...this.settings, ...window.appSettings };
  }

  backendBaseUri(route: TRoutes): TBackendBaseUri {
    return this.settings.backend.uris[route];
  }

  backendBaseToken(route: TTokenRoutes): TBackendBaseToken {
    return this.settings.backend.tokens[route];
  }

  get backendRpcRoute(): TBackendRpcRoute {
    return this.settings.backend.rpc_route;
  }

  get environment(): TEnvironment {
    return this.settings.environment;
  }

  get userPasswordResetUri(): TUserPasswordResetUri {
    return this.settings.user.password_reset_uri;
  }

  get sentryDsn(): TSentryDsn {
    return this.settings.sentry.sentry_dsn;
  }

  get cloudPaymentsPublicKey(): TCloudPaymentsPublicKey {
    return this.settings.cloudpayments.public_key;
  }

  get centrifugeUri(): string {
    return this.settings.external.uris.centrifuge;
  }

  get mfMntgUri(): string {
    return this.settings.external.uris.mf_mntg;
  }

  get insappUri(): string {
    return this.settings.insapp.insapp_url;
  }

  get insappKey(): string {
    return this.settings.insapp.insapp_key;
  }

  get cybertonicaTeam(): string {
    return this.settings.cybertonica.cybertonica_team;
  }
}
