import { Reasons, UnsubscribeComment } from '@/rpc-types/profi';

export interface IUnsubFeedbackForm {
  reasons: Reasons;
  comment: UnsubscribeComment;
}
export default class SubscriptionsState {
  unsubFeedbackForm: IUnsubFeedbackForm = {
    reasons: [],
    comment: '',
  };
}
