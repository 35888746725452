import {
  UUID, IRPCReportGet, IRPCCommentsGetByReportUuid,
  IRPCProfileMe, IRPCBankCardsList, IRPCBankCardsSetMain, IRPCClientsAccessCurrent, IRPCClientsAccessLast, IRPCProductsList,
} from '@/rpc-types/profi';

import { ITupleMethodData } from '@/services/httpClient/types';


import HttpClient from '@/services/httpClient';

export default class BatchApi extends HttpClient {
  fetchReportWithUserContent = (uuid: UUID) => {
    interface IReportWithUserContent extends ITupleMethodData {
      input:
        [IRPCReportGet['input'], IRPCCommentsGetByReportUuid['input']]
      output:
        [IRPCReportGet['output'], IRPCCommentsGetByReportUuid['output']]
    }
    const batch: IReportWithUserContent['input'] = [
      {
        method: 'report.get',
        params: { uuid },
        route: 'profi',
      },
      {
        method: 'comments.getByReportUuid',
        params: { uuid },
        route: 'profi',
      },
    ];
    return this.batch<IReportWithUserContent>(batch, 'profi');
  }

  fetchDataForNotAuthUser = () => {
    interface INotAuthUserData extends ITupleMethodData {
      input: [IRPCProductsList['input']]
      output: [IRPCProductsList['output']]
    }
    const batch: INotAuthUserData['input'] = [
      {
        method: 'products.list',
        params: {},
        route: 'profi',
      },
    ];
    return this.batch<INotAuthUserData>(batch, 'profi');
  }

  fetchDataForAuthUser = () => {
    interface IAuthUserData extends ITupleMethodData {
      input: [IRPCProfileMe['input'], IRPCClientsAccessCurrent['input'], IRPCClientsAccessLast['input'], IRPCBankCardsList['input']]
      output: [IRPCProfileMe['output'], IRPCClientsAccessCurrent['output'], IRPCClientsAccessLast['output'], IRPCBankCardsList['output']]
    }
    const batch: IAuthUserData['input'] = [
      {
        method: 'profile.me',
        params: {},
        route: 'profi',
      },
      {
        method: 'clients.access.current',
        params: {},
        route: 'profi',
      },
      {
        method: 'clients.access.last',
        params: {},
        route: 'profi',
      },
      {
        method: 'bankCards.list',
        params: {},
        route: 'profi',
      },
    ];
    return this.batch<IAuthUserData>(batch, 'profi');
  }

  updateMainCard = (cardUUID: UUID) => {
    interface IUpdateMainCardMethod extends ITupleMethodData {
      input: [IRPCBankCardsSetMain['input'], IRPCClientsAccessCurrent['input']],
      output: [IRPCBankCardsSetMain['output'], IRPCClientsAccessCurrent['output']],
    }
    const batch: IUpdateMainCardMethod['input'] = [
      {
        method: 'bankCards.setMain',
        params: {
          uuid: cardUUID,
        },
        route: 'profi',
      },
      {
        method: 'clients.access.current',
        params: {},
        route: 'profi',
      },
    ];
    return this.batch<IUpdateMainCardMethod>(batch, 'profi');
  }
}
