import { CreateElement, RenderContext, VNode } from 'vue';

export default function createGrid(name: string) {
  return {
    name,
    functional: true,
    props: {
      tag: {
        type: String,
        default: 'div',
      },
    },
    render(h: CreateElement, context: RenderContext): VNode {
      const { data, children, props: { tag } } = context;
      data.staticClass = name;

      // add attributes as css classes
      const { attrs } = data;
      if (attrs) {
        // reset attrs to extract utility classes
        data.attrs = {};
        const classes = Object.keys(attrs).filter((key) => {
          const value = attrs[key];

          // add back data attributes like data-foo="bar"
          // and dont't add them as classes
          if (key.startsWith('data-') && data.attrs) {
            data.attrs[key] = value;

            return false;
          }

          return value || typeof value === 'string';
        });

        if (classes.length) {
          data.staticClass += ` ${classes.join(' ')}`;
        }
      }

      return h(tag, data, children);
    },
  };
}
