import {
  ProductCarOwnerReport,
  ProductAccess,
  ProductReportPack,
  ProductSubscription,
} from '@/rpc-types/profi';

export default class ProductsState {
  subscriptionsList: ProductSubscription[] = [];
  accessList: ProductAccess[] = [];
  reportPacksList: ProductReportPack[] = [];
  carOwnerReportTypeList: ProductCarOwnerReport[] = [];
}
