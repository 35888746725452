import { Module, createMapper } from 'vuex-smart-module';

import PaymentState from '@/store/modules/payments/state';
import PaymentGetters from '@/store/modules/payments/getters';
import PaymentMutations from '@/store/modules/payments/mutations';
import PaymentActions from '@/store/modules/payments/actions';

const payments = new Module({
  state: PaymentState,
  getters: PaymentGetters,
  mutations: PaymentMutations,
  actions: PaymentActions,
});

export const paymentsMapper = createMapper(payments);

export default payments;
