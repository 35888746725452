import { isObject } from '@/utils/helpers';

// Renderer exceptions in Vue component
class VueErrorRenderer {
  public handle(exception: any) {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const message = isObject(exception) ? exception.message : exception;
    // TODO: Send message into Vue Component
  }
}

const vueErrorRenderer = new VueErrorRenderer();

export default vueErrorRenderer;
