import { Report } from '@/rpc-types/profi';
import { IReportWithUserContent, IReportListEntityWithUserContent } from '@/types/entities/report';

export default class ReportsState {
  list: IReportListEntityWithUserContent[] = [];
  loading: boolean = false;
  listNeedRefetch: boolean = false;

  reportShort = {} as Report;
  reportFull = {} as IReportWithUserContent;

  promoShortCount: number = 0;
  isReportDataLoaded: boolean = false;
}
