import { Module, createMapper } from 'vuex-smart-module';

import SubscriptionsState from '@/store/modules/subscriptions/state';
import SubscriptionsGetters from '@/store/modules/subscriptions/getters';
import SubscriptionsMutations from '@/store/modules/subscriptions/mutations';
import SubscriptionActions from '@/store/modules/subscriptions/actions';

const subscriptions = new Module({
  state: SubscriptionsState,
  getters: SubscriptionsGetters,
  mutations: SubscriptionsMutations,
  actions: SubscriptionActions,
});

export const subscriptionsMapper = createMapper(subscriptions);

export default subscriptions;
