import { cloneDeep } from 'lodash';

/**
 * @description Recursively zeroing items count. Need for report sample normalize.
 */
export const zeroingItemsCount = <T extends Object>(obj: T): T => {
  const copyOfObject = cloneDeep(obj);
  (function insideReqursive(objReq: any): void {
    if (objReq && typeof objReq === 'object') {
      Object.keys(objReq).forEach((key) => {
        if (key === 'items') {
          if (objReq.count) {
            // eslint-disable-next-line no-param-reassign
            objReq.count = 0;
          }
        }
        insideReqursive(objReq[key]);
      });
    }
  }(copyOfObject));
  return copyOfObject;
};

/**
 * @description Recursively clear all Arrays. Need for report sample normalize.
 */
export const clearArrays = <T extends Object>(obj: T): T => {
  const copyOfObject = cloneDeep(obj);
  (function insideReqursive(objReq: any): void {
    if (objReq && typeof objReq === 'object') {
      Object.keys(objReq).forEach((key) => {
        if (Array.isArray((objReq as any)[key])) {
          // eslint-disable-next-line no-param-reassign
          (objReq as any)[key] = [];
        }
        insideReqursive((objReq as any)[key]);
      });
    }
  }(copyOfObject));
  return copyOfObject;
};

/**
 * @description It make mapping with matrix object and original object. If properties is undefined in original object it copies them from matrix.
 * WARNING! It mutates the original object!
 */
export const fillObjectFromMatrix = <T extends Object>(object: T, matrix: T): T => {
  (function recursiveRewrite(real: any, sample: any) {
    if (sample && typeof sample === 'object') {
      Object.keys(sample).forEach((key) => {
        if (Array.isArray(sample[key])) {
          if (real[key] === undefined || real[key] === null) {
            // eslint-disable-next-line no-param-reassign
            real[key] = [];
          } else if (Array.isArray(real[key]) && sample[key][0]) {
            real[key].forEach((element: any) => {
              recursiveRewrite(element, sample[key][0]);
            });
          }
          return;
        }

        if (real[key] === undefined) {
          const updatedEl = clearArrays(zeroingItemsCount(sample[key]));
          // eslint-disable-next-line no-param-reassign
          real[key] = updatedEl;
          return;
        }

        if (real[key] && typeof real[key] === 'object') {
          recursiveRewrite(real[key], sample[key]);
        }
      });
    }
  }(object, matrix));

  return object;
};
