import { Store } from 'vuex';
import {
  Module, Getters, Mutations, Actions, createMapper, Context,
} from 'vuex-smart-module';

import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import bankCards from '@/store/modules/bank-cards';
import clients from '@/store/modules/clients';
import comments from '@/store/modules/comments';
import payments from '@/store/modules/payments';
import profile from '@/store/modules/profile';
import products from '@/store/modules/products';
import reports from '@/store/modules/reports';
import reportsFacade from '@/store/modules/reports-facade';
import subscriptions from '@/store/modules/subscriptions';
import batch from '@/store/modules/batch';
import device from '@/store/modules/device';
import contentScrollPosition from '@/store/modules/content-scroll-position';
import ppSpectrumdata from '@/store/modules/ppSpectrumdata';
import policies from '@/store/modules/policies';

class RootState {}

class RootGetters extends Getters<RootState> {}

class RootMutations extends Mutations<RootState> {}

class RootActions extends Actions<
  RootState,
  RootGetters,
  RootMutations,
  RootActions
> {
  modulesForReset!: {
    bankCards: Context<typeof bankCards>
    clients: Context<typeof clients>
    payments: Context<typeof payments>
    reports: Context<typeof reports>
    batch: Context<typeof batch>
    ppSpectrumdata: Context<typeof ppSpectrumdata>
  }

  $init(store: Store<any>) {
    this.modulesForReset = {
      bankCards: bankCards.context(store),
      clients: clients.context(store),
      payments: payments.context(store),
      reports: reports.context(store),
      batch: batch.context(store),
      ppSpectrumdata: ppSpectrumdata.context(store),
    };
  }

  resetState(): void {
    Object.values(this.modulesForReset).forEach(module => module.mutations.reset());
  }
}

const root = new Module({
  state: RootState,
  getters: RootGetters,
  mutations: RootMutations,
  actions: RootActions,
  modules: {
    app,
    auth,
    bankCards,
    clients,
    comments,
    payments,
    profile,
    products,
    reports,
    reportsFacade,
    subscriptions,
    batch,
    contentScrollPosition,
    device,
    ppSpectrumdata,
    policies,
  },
});

export const rootMapper = createMapper(root);

export default root;
