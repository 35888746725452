<template>
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
    v-on="$listeners"
  >
    <use :href="`#icon-${iconId}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseSvg',
  props: {
    iconId: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
