<template>
  <!-- eslint-disable -->
  <svg v-bind="$attrs" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.636 3.264a.9.9 0 0 1 0 1.272L6.073 11.1H20.1a.9.9 0 1 1 0 1.8H6.073l6.563 6.564a.9.9 0 1 1-1.272 1.272l-8.1-8.1a.9.9 0 0 1 0-1.272l8.1-8.1a.9.9 0 0 1 1.272 0Z" fill="#2C303B"/></svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'IconButtonBack',
};
</script>
