<template>
  <div class="login-mobile-container">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLoginContainerMobile',
});
</script>

<style lang="scss" scoped>
.login-mobile-container {
  padding: gridCalc(0, 2);
}
</style>
