/* eslint-disable no-param-reassign */
import { DirectiveOptions } from 'vue';

let scrollbarWidth: number = 0;

const disableScroll = (target: HTMLElement): void => {
  scrollbarWidth = target.offsetWidth - target.scrollWidth;

  target.style.paddingRight = `${parseFloat(target.style.paddingRight || '0') + scrollbarWidth}px`;
  target.style.overflow = 'hidden';
};

const enableScroll = (target: HTMLElement): void => {
  target.style.overflow = '';
  target.style.paddingRight = '';
};

const handleScroll = (shouldDisable: string | undefined, el: HTMLElement) => {
  if (shouldDisable) {
    disableScroll(el);
  } else {
    enableScroll(el);
  }
};

export default {
  inserted(el, binding) {
    handleScroll(binding.arg, el);
  },
  update(el, binding) {
    handleScroll(binding.arg, el);
  },
} as DirectiveOptions;
/* eslint-enable */
