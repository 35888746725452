import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';
import { Identifier, Type } from '@/rpc-types/profi';

import reports from '@/store/modules/reports';
import clients from '@/store/modules/clients';

export default class ReportsFacadeActions extends Actions<any, any, any, ReportsFacadeActions> {
  reports!: Context<typeof reports>
  clients!: Context<typeof clients>;

  $init(store: Store<any>): void {
    this.clients = clients.context(store);
    this.reports = reports.context(store);
  }

  async createReport({ query, type }: { query: Identifier, type: Type }): Promise<void> {
    if (this.reports.getters.hasPromoShortReports && this.clients.getters.isAccessSometimeNotExisted) {
      this.reports.mutations.setPromoShortReportCount(this.reports.state.promoShortCount - 1);
    }

    await this.reports.actions.createReport({ query, type });

    if (this.clients.getters.isAccessSometimeNotExisted) {
      this.actions.fetchPromoReportsCount();
    }
  }

  async fetchPromoReportsCount(): Promise<void> {
    if (this.clients.getters.isAccessSometimeNotExisted) {
      await this.reports.actions.fetchPromoReportsCount();
    }
  }
}
