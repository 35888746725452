declare namespace AFCYBERTONICA {
  interface InitOptions {
    team: string;
    proxyAddress?: string;
    userId?: string;
  }

  function init(options: InitOptions): string;

  function setUserId(userId: string): void;
}

export default function initCybertonica(cybertonicaTeam: string): void {
  AFCYBERTONICA.init({ team: cybertonicaTeam, proxyAddress: 'https://session.avtocod.ru' });
}

export function setUserIdCybertonica(userId: string): void {
  AFCYBERTONICA.setUserId(userId);
}
