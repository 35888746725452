import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';

import { Client, Phone, UUID } from '@/rpc-types/profi';
import ProfileState from '@/store/modules/profile/state';

export default class ProfileMutations extends Mutations<ProfileState> {
  setProfile(profile: Client): void {
    // GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ userId: profile.uuid });
    Vue.set(this.state, 'profile', profile);
  }

  updateProfile(updatedProfile: Client): void {
    Vue.set(this.state, 'profile', {
      ...updatedProfile,
    });
  }

  setNotVerifiedPhone(phone: Phone): void {
    this.state.notVerifiedPhone = phone;
  }

  setNotVerifiedUuid(uuid: UUID): void {
    this.state.notVerifiedUuid = uuid;
  }
}
