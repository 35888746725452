<template>
  <app resolution="desktop">
    <canvas
      v-show="elementHighlighted"
      ref="canvas"
      class="canvas-overlay"
      :style="{ 'animation-duration': animateDuration + 's' }"
    ></canvas>
    <component :is="layout">
      <router-view />
    </component>

    <SuccessfullyRegisteredAlert
      :is-show-error-alert="isShowRegisteredAlert"
      :is-mobile="false"
      @close="closeAlert"
    />
    <SuccessfullyProfileVerifiedAlert
      :is-show-error-alert="isShowProfileVerifiedAlert"
      :is-mobile="false"
      @close="closeAlert"
    />

    <portal-target
      name="modal"
      multiple
    />
    <portal-target
      name="tooltip-chart--market-value"
      multiple
    />
    <portal-target
      name="tooltip-chart--mileage"
      multiple
    />
    <portal-target
      name="photoswiper"
      multiple
    />
    <portal-target
      name="popup"
      multiple
    />
    <app-notifications />
  </app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SuccessfullyProfileVerifiedAlert from '@/components/particles/successfully-profile-verified-alert/SuccessfullyProfileVerifiedAlert.vue';
import SuccessfullyRegisteredAlert from '@/components/particles/successfully-registered-alert/SuccessfullyRegisteredAlert.vue';

import AppNotifications from '@/components/legacy/common/desktop/notifications/AppNotifications.vue';
import App from '@/components/legacy/common/general/App.vue';
import { SEARCH_PARAMS } from '@/consts/search';
import { routerCatchErrorHandler } from '@/router/utils';
import { isAuthenticationException } from '@/services/appError/exceptionChecker';
import authStorage from '@/services/authTokenStorage';
import { FirebaseAnalyticsManager } from '@/services/firebaseAnalytics';
import { TRPCResponseData } from '@/services/httpClient/types';
import wsClientWrapper from '@/services/wsClient/wsWrapper';
import { batchMapper } from '@/store/modules/batch';
import { reportsMapper } from '@/store/modules/reports';
import { reportsFacadeMapper } from '@/store/modules/reports-facade';
import eventBus from '@/utils/eventBus';

const LayoutDefault = () => import('@/layouts/desktop/LayoutDefault.vue');
const LayoutPrint = () => import('@/layouts/general/LayoutPrint.vue');
const LayoutEmpty = () => import('@/layouts/general/LayoutEmpty.vue');
const LayoutGuest = () => import('@/layouts/desktop/LayoutGuest.vue');

export default defineComponent({
  name: 'DesktopApp',
  components: {
    SuccessfullyProfileVerifiedAlert,
    SuccessfullyRegisteredAlert,
    App,
    AppNotifications,
    LayoutDefault,
    LayoutPrint,
    LayoutEmpty,
    LayoutGuest,
  },
  data() {
    return {
      elementHighlighted: false,
      highlightInProgress: false,
      animateDuration: 2,
      isShowRegisteredAlert: false,
      isShowProfileVerifiedAlert: false,
    };
  },
  watch: {
    $route(to) {
      if (to.hash) {
        this.onHashchange(to.hash);
      } else {
        if (this.isShowRegisteredAlert) this.isShowRegisteredAlert = false;
        if (this.isShowProfileVerifiedAlert) this.isShowProfileVerifiedAlert = false;
      }
    },
  },
  async created(): Promise<void> {
    eventBus.on('goToAuth', this.goToAuthHandler).on('highlightElement', this.openOverlay);

    this.fetchDataForNotAuthUser();

    if (authStorage.isLoggedIn) {
      const dataForAuthUser = await this.fetchDataForAuthUser();
      // FIXME-VUE3 !92 исправить типы после обновления на vue3
      // @ts-ignore
      const responseData = Object.values(dataForAuthUser) as TRPCResponseData<unknown>[];
      const isHasAuthenticationException = responseData.some((answer) => isAuthenticationException(answer.error));
      if (!isHasAuthenticationException) {
        // FIXME-VUE3 !92 исправить типы после обновления на vue3
        // @ts-ignore
        const { profileResponse } = dataForAuthUser;
        const userUUID = profileResponse?.result?.uuid || '';
        FirebaseAnalyticsManager.setUserUUID(userUUID);

        await this.fetchReportList({
          pagination: SEARCH_PARAMS.DEFAULT_PAGINATION,
          sort: SEARCH_PARAMS.DEFAULT_SORT,
        });
        await this.fetchPromoReportsCount();
      }
    }
  },
  beforeDestroy(): void {
    eventBus.off('goToAuth', this.goToAuthHandler).off('highlightElement', this.openOverlay);
  },
  computed: {
    layout(): string {
      return this.$route.meta?.layout || (authStorage.isLoggedIn ? 'LayoutDefault' : 'div');
    },
  },
  methods: {
    ...reportsMapper.mapActions(['fetchReportList', 'updateNotReadyReportsInList']),
    ...reportsFacadeMapper.mapActions(['fetchPromoReportsCount']),
    ...batchMapper.mapActions(['fetchDataForNotAuthUser', 'fetchDataForAuthUser']),

    closeAlert() {
      window.location.hash = '';
    },

    onHashchange(hash: string): void {
      if (hash === '#show-registered-alert') {
        this.isShowRegisteredAlert = true;
        return;
      }

      if (hash === '#show-profile-verified-alert') {
        this.isShowProfileVerifiedAlert = true;
        return;
      }
    },

    openOverlay(elem: HTMLElement, timeInSeconds = 2, timeoutBeforeAnimate = 0, padding = 0): void {
      if (this.highlightInProgress) {
        return;
      }
      this.highlightInProgress = true;
      this.animateDuration = timeInSeconds;
      setTimeout(() => {
        this.elementHighlighted = true;
        const location = elem.getBoundingClientRect();
        const canvas = this.$refs.canvas as HTMLCanvasElement;
        const ctx = canvas.getContext('2d')!;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.clearRect(
          location.left - padding,
          location.top - padding,
          location.width + padding * 2,
          location.height + padding * 2
        );

        setTimeout(() => {
          this.hideOverlay();
        }, timeInSeconds * 1000);
      }, timeoutBeforeAnimate * 1000);
    },
    hideOverlay(): void {
      this.elementHighlighted = false;
      this.highlightInProgress = false;
    },
    goToAuthHandler(): void {
      // TODO Fix multiple router.push (main path + logout path) before main finish create error
      wsClientWrapper.unsubscribeAllCentrifugeInstances();
      const redirectUrl = this.$route.query.redirect || this.$route.path;
      this.$router.push({ name: 'logout', query: { redirect: redirectUrl } }).catch(routerCatchErrorHandler);
    },
  },
});
</script>

<style scoped lang="scss">
.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-idx-super;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation-name: canvasFade;
  animation-timing-function: ease;

  @keyframes canvasFade {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
}
</style>
