import { Mutations } from 'vuex-smart-module';

import {
  ClientAccessCurrentResponse,
  ClientAccessLastResponse,
  IsRecurrent,
} from '@/rpc-types/profi';

import ClientsState from '@/store/modules/clients/state';
import { instanceOfInfoSubscription } from '@/types/guards';

export default class ClientsMutations extends Mutations<ClientsState> {
  setCurrentAccessRecurrent(is_recurrent: IsRecurrent): void {
    if (this.state.currentAccess && instanceOfInfoSubscription(this.state.currentAccess.info)) {
      this.state.currentAccess.info.is_recurrent = is_recurrent;
    }
  }

  setCurrentAccess(currentAccess: ClientAccessCurrentResponse): void {
    this.state.currentAccess = currentAccess;
  }

  incrementCurrentAccessRestOfReports(count: number) {
    if (this.state.currentAccess?.info.rest_of_reports) {
      this.state.currentAccess.info.rest_of_reports += count;
    }
  }

  setLastAccess(lastAccess: ClientAccessLastResponse): void {
    this.state.lastAccess = lastAccess;
  }

  reset(): void {
    type TClientsStateKeys = keyof ClientsState;
    const state = new ClientsState();
    Object.keys(state).forEach((key: string) => {
      this.state[key as TClientsStateKeys] = state[key as TClientsStateKeys];
    });
  }
}
