import { DirectiveOptions } from 'vue';
import eventBus from '@/utils/eventBus';

const CACHE_SCROLL_TO = '__vueScrollTo__';
const DEFAULT_DURATION = 500;
const DEFAULT_SCROLLER_ELEMENT = document.documentElement;

export function scrollToFunc(target: HTMLElement, duration: number, offset: number = 0, scrollerElement: HTMLElement, callback?: Function) {
  eventBus.emit('scrollTo', true);

  const targetTop = target.offsetTop - offset;

  let currentTop = scrollerElement.scrollTop;
  let prevAnimTime = 0;

  const scrollLength = targetTop - currentTop;
  const direction = Math.sign(scrollLength);

  /**
   * @description - recursively scrolls in small steps to fit the duration
   * @param {number} time - timestamp of requestAnimationFrame
   * * */
  const scrollStep = (time: number) => {
    const differenceInSeconds = prevAnimTime === 0 ? 0 : time - prevAnimTime;
    const percentOfTime = differenceInSeconds / duration;
    const step = scrollLength * percentOfTime;

    scrollerElement.scroll(0, currentTop + step);

    if (direction * currentTop + step < targetTop * direction) {
      prevAnimTime = time;
      currentTop += step;

      requestAnimationFrame(scrollStep);
    } else {
      scrollerElement.scroll(0, targetTop);
      if (callback) {
        callback();
      }
      eventBus.emit('scrollTo', false);
    }
  };

  requestAnimationFrame(scrollStep);
}

export default {
  inserted(el, binding) {
    if (!binding.value.target) {
      throw TypeError("The target element is not provided in 'vue-scroll-to'");
    }

    const target = document.getElementById(binding.value.target);
    const scrollerElement = binding.value.scrollerElementId ? document.getElementById(binding.value.scrollerElementId) as HTMLElement : DEFAULT_SCROLLER_ELEMENT;
    const duration = binding.value.duration || DEFAULT_DURATION;
    const { offset } = binding.value;
    const { callback } = binding.value;

    if (target) {
      const handler = () => scrollToFunc(target, duration, offset, scrollerElement, callback);

      el.addEventListener('click', handler);
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      el[CACHE_SCROLL_TO] = handler;
    }
  },
  unbind(el) {
    // @ts-ignore
    el.removeEventListener('click', el[CACHE_SCROLL_TO]);
  },
} as DirectiveOptions;
