<template>
  <base-header-button-action @click="onClick">
    <IconButtonBack class="header-button-back__icon"/>
  </base-header-button-action>
</template>

<script lang="ts">

import BaseHeaderButtonAction from '@/components/legacy/base/BaseHeaderButtonAction.vue';
import IconButtonBack from '@/icons/IconButtonBack.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderButtonBack',
  props: {
    clickProp: {
      type: Function,
      default: null,
    },
  },
  components: {
    BaseHeaderButtonAction,
    IconButtonBack,
  },
  methods: {
    onClick(): void {
      if (this.clickProp) {
        this.clickProp();
      } else {
        this.$router.back();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.header-button-back__icon {
  width: 24px;
  height: 24px;
}
</style>
