import { Actions } from 'vuex-smart-module';

import {
  GetCommissionInfoFromInsappResponse,
  InsappPoliciesList,
  Pagination,
} from '@/rpc-types/profi';

import PoliciesState from '@/store/modules/policies/state';
import PoliciesGetters from '@/store/modules/policies/getters';
import PoliciesMutations from '@/store/modules/policies/mutations';

import PoliciesApi from '@/services/httpClient/api/profi/policies.api';

export default class PoliciesActions extends Actions<
  PoliciesState,
  PoliciesGetters,
  PoliciesMutations,
  PoliciesActions
> {
  api!: PoliciesApi;

  $init(): void {
    this.api = new PoliciesApi();
  }

  async fetchPoliciesHistoryList({ pagination }: {
    pagination?: Pagination
  }): Promise<InsappPoliciesList> {
    const { data: { result, error } } = await this.api.fetchPoliciesHistoryList(pagination);

    // eslint-disable-next-line no-console
    if (error) console.warn('fetchPoliciesHistoryList error:', error);

    if (result) {
      this.mutations.setPoliciesHistory(result.policies);
      return result?.policies || [];
    }

    return [];
  }

  async fetchCommissionInfo(): Promise<GetCommissionInfoFromInsappResponse | null> {
    const { data: { result, error } } = await this.api.fetchCommissionInfo();

    // eslint-disable-next-line no-console
    if (error) console.warn('fetchPoliciesHistoryList error:', error);

    if (result) {
      this.mutations.setCommissionInfo(result);
      return result || null;
    }

    return null;
  }
}
