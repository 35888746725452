import { Module, createMapper } from 'vuex-smart-module';

import AppState from '@/store/modules/app/state';
import AppGetters from '@/store/modules/app/getters';
import AppMutations from '@/store/modules/app/mutations';

const app = new Module({
  state: AppState,
  getters: AppGetters,
  mutations: AppMutations,
});

export const appMapper = createMapper(app);

export default app;
