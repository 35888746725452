import { Mutations } from 'vuex-smart-module';

import { GetCommissionInfoFromInsappResponse, InsappPoliciesList } from '@/rpc-types/profi';

import PoliciesState from '@/store/modules/policies/state';

export default class PoliciesMutations extends Mutations<PoliciesState> {
  setPoliciesHistory(policies: InsappPoliciesList): void {
    this.state.policiesList = policies;
  }

  setCommissionInfo(commissionInfo: GetCommissionInfoFromInsappResponse): void {
    this.state.commissionInfo = commissionInfo;
  }
}
