type Value = string | number;
export type Format = 'currency' | 'distance' | 'percent' | 'mass' | 'capacity';
const EMPTY_PLACEHOLDER = '—';

const RUB = '\u20bd';
const KM = 'км';
const SPACING = '\u00A0';
const PERCENT = '%';
const MASS = 'кг';
const CAPACITY = `куб.${SPACING}см`;

export default function unit(v: Value, format: Format) {
  if (v === '') {
    return EMPTY_PLACEHOLDER;
  }

  let value = v;

  switch (format) {
    case 'currency':
      value += SPACING + RUB;
      break;
    case 'distance':
      value += SPACING + KM;
      break;
    case 'percent':
      value += PERCENT;
      break;
    case 'mass':
      value += SPACING + MASS;
      break;
    case 'capacity':
      value += SPACING + CAPACITY;
      break;
    default:
      throw new TypeError("Invalid format in 'unit' filter");
  }

  return value;
}
