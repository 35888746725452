<template>
  <div class="layout">
    <div class="layout__main">
      <slot></slot>
    </div>
    <footer
      v-if="isShowFooter"
      class="layout__footer"
    >
      <div class="layout__actions">
        <slot name="actions"></slot>
      </div>
      <div
        v-if="isShowApps"
        class="layout__apps"
      >
        <MobileAppsLinks />
      </div>
    </footer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import MobileAppsLinks from '@/components/composite/mobile-apps-links/MobileAppsLinks.vue';

export default defineComponent({
  name: 'LayoutLoginDesktop',
  components: {
    MobileAppsLinks,
  },
  props: {
    isShowApps: {
      type: Boolean,
      default: true,
    },
    isShowFooter: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: gridCalc(0, 8);
}

.layout__main {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: gridCalc(2) 0;
}

.layout__apps {
  margin-top: gridCalc(8);
}

.layout__footer {
  min-height: 128px;
  padding-top: gridCalc(2);
  padding-bottom: gridCalc(5.5);
  margin-top: auto;
  border-top: 1px solid #e6e7f4;
}

.layout__actions {
  font-size: 14px;
  text-align: center;
}
</style>
