import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';
import dayjs from 'dayjs';

import { IRPCError } from '@/services/httpClient/types';
import { Text, Comment, UUID } from '@/rpc-types/profi';

import CommentsState from '@/store/modules/comments/state';
import CommentsGetters from '@/store/modules/comments/getters';
import CommentsMutations from '@/store/modules/comments/mutations';

import reports from '@/store/modules/reports';
import CommentsApi from '@/services/httpClient/api/profi/comments.api';

export default class CommentActions extends Actions<
  CommentsState,
  CommentsGetters,
  CommentsMutations,
  CommentActions
> {
  reports!: Context<typeof reports>;
  api!: CommentsApi;

  $init(store: Store<any>): void {
    this.reports = reports.context(store);
    this.api = new CommentsApi();
  }

  async fetchCommentsByReportUuid(uuid: string): Promise<IRPCError | undefined> {
    const { data: { result, error } } = await this.api.getCommentsByReportUuid(uuid);

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('fetchComment error', error);
    }

    if (result) {
      this.reports.mutations.setReportContentField({ path: 'comments', items: result.comments, uuid });
    }

    return error;
  }

  async createComment({ uuid, content }: { uuid: string, content: Text }): Promise<void> {
    const { data: { result, error } } = await this.api.createComment(uuid, content);

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('createComment error', error);
    }

    if (result) {
      this.reports.mutations.setReportContentFieldItem({ path: 'comments', item: result, uuid });
    }
  }

  async updateComment({
    id, content, uuid,
  }: { id: number, content: string, uuid?: UUID }): Promise<void> {
    const reportAndUUID = this.reports.getters.getReportAndUUID(uuid);
    if (!reportAndUUID || !reportAndUUID.report.comments) {
      return;
    }
    const { report } = reportAndUUID;
    const reportUUID = reportAndUUID.uuid;
    const commentPrev = report.comments!.find((comment: Comment) => comment.id === id);
    if (commentPrev) {
      const commentNext = {
        ...commentPrev,
        text: content,
        updated_at: dayjs().format(),
      };

      this.reports.mutations.deleteReportContentFieldItem({ path: 'comments', uuid: reportUUID, id });
      this.reports.mutations.setReportContentFieldItem({ path: 'comments', item: commentNext, uuid: reportUUID });

      const { data: { error } } = await this.api.updateComment(commentNext.id, content);

      if (error) {
        this.reports.mutations.deleteReportContentFieldItem({ path: 'comments', uuid: reportUUID, id });
        this.reports.mutations.setReportContentFieldItem({ path: 'comments', item: commentPrev, uuid: reportUUID });
        // eslint-disable-next-line no-console
        console.warn('updateComment error', error);
      }
    }
  }

  async removeComment({ id, uuid }: { id: number, uuid?: UUID }): Promise<void> {
    const reportAndUUID = this.reports.getters.getReportAndUUID(uuid);
    if (!reportAndUUID || !reportAndUUID.report.comments) {
      return;
    }
    const { report } = reportAndUUID;
    const reportUUID = reportAndUUID.uuid;
    const commentRemoved = report.comments!.find((comment: Comment) => comment.id === id);
    if (commentRemoved) {
      this.reports.mutations.deleteReportContentFieldItem({ path: 'comments', uuid: reportUUID, id });

      const { data: { error } } = await this.api.deleteComment(id);

      if (error) {
        this.reports.mutations.setReportContentFieldItem({ path: 'comments', item: commentRemoved, uuid: reportUUID });
        // eslint-disable-next-line no-console
        console.warn('removeComment error', error);
      }
    }
  }
}
