module.exports = new Promise(resolve => {
                  const remoteUrl = window.appSettings.external.uris.mf_mntg || 'http://localhost:8087';
                  const bustCacheParam = '?t=' + new Date().getTime();
                  const remoteUrlWithVersion = remoteUrl + '/remoteEntry.js' + bustCacheParam;
                  const script = document.createElement('script')
                  script.src = remoteUrlWithVersion
                  script.onload = () => {
                    const proxy = {
                      get: (request) => window.mf_mntg.get(request),
                      init: (arg) => {
                        try {
                          return window.mf_mntg.init(arg)
                        } catch(e) {
                          console.log('remote container already initialized')
                        }
                      }
                    }
                    resolve(proxy)
                  }
                  document.head.appendChild(script);
                });