import { Actions } from 'vuex-smart-module';

import DeviceState from '@/store/modules/device/state';
import DeviceGetters from '@/store/modules/device/getters';
import DeviceMutations from '@/store/modules/device/mutations';
import DeviceApi from '@/services/httpClient/api/profi/device.api';

import { genDeviceId } from '@/utils/helpers';
import { Client, DeviceUuid } from '@/rpc-types/profi';

export default class DeviceActions extends Actions<DeviceState, DeviceGetters, DeviceMutations> {
  api!: DeviceApi;

  $init() {
    this.api = new DeviceApi();
  }

  async setDeviceId(userUUID: Client['uuid']): Promise<string | null> {
    try {
      let deviceId: DeviceUuid | null = null;

      if (this.getters.isExistsDeviceId) {
        // eslint-disable-next-line prefer-destructuring
        deviceId = this.state.deviceId;
      } else {
        deviceId = await genDeviceId(userUUID, 'pc');
        this.mutations.setDeviceId(deviceId);
      }

      const { data: { error, result } } = await this.api.setDeviceId(deviceId as string);

      if (result) {
        return result.uuid;
      }

      if (error) {
        // eslint-disable-next-line
        console.error(error.message);
        return null;
      }

      return null;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return null;
    }
  }
}
