import Vue from 'vue';
import clickOutsideDirective from './click-outside-directive';
import mask from './mask';
import scrollTo from './scroll-to';
import scrollSaveViewport from './scroll-save-viewport';
import disableScroll from './disableScroll';
import focus from './focus';

Vue.directive('click-outside', clickOutsideDirective);
Vue.directive('mask', mask);
Vue.directive('scroll-to', scrollTo);
Vue.directive('scroll-save-viewport', scrollSaveViewport);
Vue.directive('disable-scroll', disableScroll);
Vue.directive('focus', focus);
