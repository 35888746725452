import HttpClient from '@/services/httpClient/index';
import { DeviceUuid, IRPCDeviceSet } from '@/rpc-types/profi';

export default class DeviceApi extends HttpClient {
  setDeviceId = (id: DeviceUuid) => this.call<IRPCDeviceSet>(
    'device.set',
    {},
    'profi',
    { 'device-uuid': id },
  )
}
