import Vue from 'vue';

import { Mutations } from 'vuex-smart-module';
import ContentScrollPositionMutations from '@/store/modules/content-scroll-position/state';

type Pages = 'home' | 'report';

export default class contentScrollPositionMutations extends Mutations<ContentScrollPositionMutations> {
  saveScrollPosition({ page, posY }: { page: Pages, posY?: number }) {
    Vue.set(this.state, page, posY || 0);
  }

  resetScrollPositionReport(): void {
    Vue.set(this.state, 'report', 0);
  }


  resetScrollPositionHome(): void {
    Vue.set(this.state, 'home', 0);
  }
}
