import { requireAuth } from '@/router/utils';

export default [
  {
    path: '/monitoring/:entry*',
    name: 'monitoring',
    component: () => import('@/pages/common/ads/MonitoringPart.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Поиск авто',
    },
    props: {
      layoutComponent: () => import('@/layouts/general/LayoutMntgDefault.vue'),
    },
  },
];
