import {
  UUID,
  Pagination,
  Sort,
  Identifier,
  Type,
  Filters,
  IRPCReportCreate,
  IRPCReportUpgrade,
  IRPCReportGet,
  IRPCReportsList,
  IRPCReportsSearch,
  IRPCCompaniesGetByReportUuid,
  IRPCReportsPromoCount,
  IRPCCommentsGetByReportsUuids,
} from '@/rpc-types/profi';

import { ITupleMethodData, TOptionsHttpClient } from '@/services/httpClient/types';

import HttpClient from '@/services/httpClient/index';

export default class ReportApi extends HttpClient {
  createReport = (query: Identifier, type?: Type) => (
    this.call<IRPCReportCreate>('report.create', { query, type }, 'profi')
  )

  upgradeReport = (uuid: UUID) => (
    this.call<IRPCReportUpgrade>('report.upgrade', { uuid }, 'profi')
  )

  fetchReport = (uuid: UUID, options?: TOptionsHttpClient) => (
    this.call<IRPCReportGet>('report.get', { uuid }, 'profi', {}, options)
  )

  fetchReportCompanies = (uuid: UUID) => (
    this.call<IRPCCompaniesGetByReportUuid>('companies.getByReportUuid', { uuid }, 'profi')
  )

  fetchReportList = (pagination?: Pagination, sort?: Sort, filters?: Filters) => (
    this.call<IRPCReportsList>('reports.list', { pagination, sort, filters }, 'profi')
  )

  fetchReportsUserContent = (uuids: UUID[]) => {
    interface IUserContent extends ITupleMethodData {
      input: [IRPCCommentsGetByReportsUuids['input']];
      output: [IRPCCommentsGetByReportsUuids['output']];
    }

    const comments = {
      method: 'comments.getByReportsUuids',
      params: { uuids },
      route: 'profi',
    } as const;

    const batch = <IUserContent['input'][]>[[comments]];

    return this.batch<IUserContent>(batch, 'profi');
  }

  searchReports = (query: Identifier, type?: Type, pagination?: Pagination, sort?: Sort, filters?: Filters) => (
    this.call<IRPCReportsSearch>('reports.search', {
      query, type, pagination, sort, filters,
    }, 'profi')
  )

  fetchPromoReportsCount = () => (
    this.call<IRPCReportsPromoCount>('reports.promo.count', {}, 'profi')
  );
}
