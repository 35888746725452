import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class RESTTransport {
  transport: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    const { baseURL } = config;
    const headers: AxiosRequestConfig['headers'] = {
      'Content-Type': 'application/json',
    };

    if (config?.headers) {
      Object.entries(config?.headers).forEach(([header, headerVal]) => {
        headers[header] = headerVal;
      });
    }

    this.transport = axios.create({
      baseURL,
      headers,
    });
  }
}

export default RESTTransport;
