import { cloneDeep } from 'lodash';

import { Report } from '@/rpc-types/profi';

// photos
export const getReportPhotos = (report: Report) => cloneDeep(report.content.content.images.photos.items);

// passport
export const getModel = (report: Report) => report.content.content.tech_data.brand.name.original;

export const getBrandLogo = (report: Report) => report.content.content.tech_data.brand.logotype.uri;

export const getVin = (report: Report) => report.content.content.identifiers.vehicle.vin;

export const getRegNumber = (report: Report) => report.content.content.identifiers.vehicle.reg_num;

export const getBody = (report: Report) => report.content.content.identifiers.vehicle.body;

export const getChassis = (report: Report) => report.content.content.identifiers.vehicle.chassis;

export const getPtsNumber = (report: Report) => report.content.content.identifiers.vehicle.pts;

export const getStsNumber = (report: Report) => report.content.content.identifiers.vehicle.sts;

export const getQueryNumber = (report: Report) => report.content.query.body;

export const getQueryType = (report: Report) => report.content.query.type;

export const getYear = (report: Report) => report.content.content.tech_data.year;

export const getWeight = (report: Report) => report.content.content.tech_data.weight.netto;

export const getIsPtsDuplicate = (report: Report) => report.content.content.additional_info.vehicle.passport.has_dublicate;

export const getWheel = (report: Report) => report.content.content.tech_data.wheel.position;

export const getEngineNumber = (report: Report) => report.content.content.tech_data.engine.number;

export const getEngineModel = (report: Report) => report.content.content.tech_data.engine.model.name;

export const getEngineVolume = (report: Report) => report.content.content.tech_data.engine.volume;

export const getEngineFuelType = (report: Report) => report.content.content.tech_data.engine.fuel.type;

export const getPowerHp = (report: Report) => report.content.content.tech_data.engine.power.hp;

export const getPowerKw = (report: Report) => report.content.content.tech_data.engine.power.kw;

export const getColor = (report: Report) => report.content.content.tech_data.body.color.name;

export const getBodyNumber = (report: Report) => report.content.content.tech_data.body.number;

export const getTechDataDateUpdate = (report: Report) => report.content.content.tech_data.date.update;

export const getCategoryCode = (report: Report) => report.content.content.additional_info.vehicle.category.code;

// geography
export const getRegistrationActions = (report: Report) => cloneDeep(report.content.content.registration_actions.items);

export const getRegistrationActionsDateUpdate = (report: Report) => report.content.content.registration_actions.date.update;

// owning
export const getOwnershipHistory = (report: Report) => cloneDeep(report.content.content.ownership.history.items);

export const getOwnershipHistoryUpdateDate = (report: Report) => report.content.content.ownership.history.date.update;

// accidents
export const getAccidentsHistory = (report: Report) => cloneDeep(report.content.content.accidents.history.items);

export const getAccidentsInsurance = (report: Report) => cloneDeep(report.content.content.accidents.insurance.items);

export const getAccidentsInsuranceDateUpdate = (report: Report) => report.content.content.accidents.insurance.date.update;

export const getAccidentsHistoryDateUpdate = (report: Report) => report.content.content.accidents.history.date.update;

// maintenance
export const getServiceHistory = (report: Report) => cloneDeep(report.content.content.service_history.items);

export const getServiceHistoryDateUpdate = (report: Report) => report.content.content.service_history.date.update;

// service-calc
export const getRepairsHistory = (report: Report) => cloneDeep(report.content.content.repairs?.history?.items);

export const getRepairsHistoryDateUpdate = (report: Report) => report.content.content.repairs?.history?.date?.update;

// mileage
export const getMileages = (report: Report) => cloneDeep(report.content.content.mileages.items);

// vin
export const getIsVinValid = (report: Report) => report.content.content.additional_info.identifiers.vin.checksum_validated;

// limitations
export const getRestrictions = (report: Report) => cloneDeep(report.content.content.restrictions.registration_actions.items);

export const getHistoryRestrictions = (report: Report) => report.content.content.restrictions.registration_actions_archive.items;

export const getHistoryRestrictionsDateUpdate = (report: Report) => report.content.content.restrictions.registration_actions.date.update;

export const getHistoryRestrictionsArchiveDateUpdate = (report: Report) => report.content.content.restrictions.registration_actions_archive.date.update;

export const getExecutiveProcedures = (report: Report) => cloneDeep(report.content.content.executive_procedures.items);

// wanted
export const getStealings = (report: Report) => cloneDeep(report.content.content.stealings.items);

export const getIsWanted = (report: Report) => report.content.content.stealings.is_wanted;

export const getStealingsDateUpdate = (report: Report) => report.content.content.stealings.date.update;

// taxi
export const getTaxis = (report: Report) => cloneDeep(report.content.content.taxi.history.items);

export const getWasInTaxi = (report: Report) => report.content.content.taxi.used_in_taxi;

// leasing
export const getLeasings = (report: Report) => cloneDeep(report.content.content.leasings.items);

export const getWasInLeasing = (report: Report) => report.content.content.leasings.used_in_leasing;

export const getLeasingsDateUpdate = (report: Report) => report.content.content.leasings.date.update;

// advert
export const getAdsHistory = (report: Report) => cloneDeep(report.content.content.ads.history.items);

export const getAdsHistoryDateUpdate = (report: Report) => report.content.content.ads.history.date.update;

// disposal
export const getUtilizations = (report: Report) => cloneDeep(report.content.content.utilizations.items);

export const getWastUtilized = (report: Report) => report.content.content.utilizations.was_utilized;

export const getUtilizationsDateUpdate = (report: Report) => report.content.content.utilizations.date.update;

// pledge
export const getPledgesFNP = (report: Report) => cloneDeep(report.content.content.pledges.items);

export const getPledgesDateUpdate = (report: Report) => report.content.content.pledges.date.update;

// osago
export const getOsago = (report: Report) => cloneDeep(report.content.content.insurance.osago.items);

export const getOsagoDateUpdate = (report: Report) => report.content.content.insurance.osago.date.update;

// market-value
export const getMarketPrices = (report: Report) => cloneDeep(report.content.content.market_prices.ads.items);

export const getTechData = (report: Report) => cloneDeep(report.content.content.tech_data);

export const getMarketPricesUpdateDate = (report: Report) => report.content.content.market_prices.ads.date.update;

// tech-view
export const getDiagnosticCards = (report: Report) => cloneDeep(report.content.content.diagnostic_cards.items);

export const getDiagnosticCardsDateUpdate = (report: Report) => report.content.content.diagnostic_cards.date.update;

// osago-cost
export const getOsagoCostRegions = (report: Report) => report.content.content.calculate.osago.price.current.yearly.amount;

export const getOsagoCostMoscow = (report: Report) => report.content.content.calculate.osago.price.moscow.yearly.amount;

// custom
export const getCustomsHistory = (report: Report) => cloneDeep(report.content.content.customs.history.items);

export const getCustomsHistoryDateUpdate = (report: Report) => report.content.content.customs.history.date.update;

// fines
export const getFines = (report: Report) => cloneDeep(report.content.content.fines.items);

export const getFinesDateUpdate = (report: Report) => report.content.content.fines.date.update;

// comercial-use
export const getCommercial = (report: Report) => cloneDeep(report.content.content.commercial_use.items);

export const getCommercialDateUpdate = (report: Report) => report.content.content.commercial_use.date.update;

// tech-changes
export const getWasModificated = (report: Report) => report.content.content.additional_info.vehicle.modifications.was_modificated;

// recall-campaign
export const getRecallCampaigns = (report: Report) => cloneDeep(report.content.content.recall_campaigns.items);

export const getRecallCampaignsDateUpdate = (report: Report) => report.content.content.recall_campaigns.date.update;

// gots auction
export const getGotsAuctionItems = (report: Report) => cloneDeep(report.content.content.gots_auctions?.items);

export const getGotsAuctionDateUpdate = (report: Report) => report.content.content.gots_auctions?.date.update;

// fssp
export const getHasProceedings = (report: Report) => report.content.content.additional_info.vehicle.owner.enforcement_proceedings.has_proceedings;

// arbitration
export const getArbitration = (report: Report) => cloneDeep(report.content.content.arbitration.items);

export const getArbitrationDateUpdate = (report: Report) => cloneDeep(report.content.content.arbitration.date.update);
