<template>
  <base-transition-expand>
    <div
      v-if="visible"
      class="hint-wrapper"
    >
      <p
        :class="['hint', type]"
        data-jest-id="hint"
      >
        <slot />
      </p>
    </div>
  </base-transition-expand>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type TInfo = 'info' | 'success' | 'error';

export default defineComponent({
  name: 'BaseHint',
  props: {
    type: {
      type: String as PropType<TInfo>,
      default: 'info',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
$hint-info-color: $text-secondary-color;
$hint-success-color: $accessory-color;
$hint-error-color: $warning-color;
$hint-height: 19px;

.hint {
  max-height: rem($hint-height * 2);
  padding-top: rem(calc(#{$grid-gutter} / 2));
  margin: 0;
  font-size: rem(12px);
  line-height: lh(14, 12);
  text-align: left;

  &.info {
    color: $hint-info-color;
  }

  &.success {
    color: $hint-success-color;
  }

  &.error {
    color: $hint-error-color;
  }
}
</style>
