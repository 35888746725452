import Vue from 'vue';
import consoleReporter from '@/services/exception-handler/handlers/console-reporter';
import sentryReporter from '@/services/exception-handler/handlers/sentry-reporter';
import vueErrorRenderer from '@/services/exception-handler/handlers/vue-error-renderer';

import PackageVersion from '@/services/packageVersion';
import AppSettingsStorage from '@/services/appSettingsStorage';

interface IConfig {
  level: 'warning' | 'error' | ''
  report?: boolean
  render?: boolean
}

interface IContext {
  appVersion?: string
  env?: string
  isVue: boolean
  file?: string
  info?: string
  trace?: string
}

const appSettingsStorage = new AppSettingsStorage();
const pckVer = new PackageVersion();

class ExceptionHandler {
  public init() {
    this.registerWindowErrorHandler();
    this.registerVueErrorHandler();
    this.registerVueWarnHandler();
  }

  public get config(): IConfig {
    return {
      level: '',
      report: false,
      render: false,
    };
  }

  public get context(): { appVersion: string, env: string } {
    return {
      appVersion: pckVer.getVersion(),
      env: appSettingsStorage.environment,
    };
  }

  public get reporters() {
    return [
      consoleReporter,
      sentryReporter,
    ];
  }

  public get renderers() {
    return [
      vueErrorRenderer,
    ];
  }

  public handle(
    exception: Error | string | undefined,
    config = {} as IConfig,
    context = {} as IContext,
  ): boolean {
    const handleConfig: IConfig = { ...this.config, ...config };
    const handleContext: IContext = { ...this.context, ...context };
    const handlers = [];

    if (handleConfig.report) {
      handlers.push(...this.reporters);
    }

    if (handleConfig.render) {
      handlers.push(...this.renderers);
    }

    handlers.forEach((handler) => {
      handler.handle(exception, handleConfig, handleContext);
    });

    return Boolean(handlers.length);
  }

  private registerWindowErrorHandler() {
    window.onerror = (
      event: Event | string,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error,
    ): void => {
      this.handle(
        error,
        { level: 'error', report: true },
        { isVue: false, file: `${source}:${lineno}:${colno}` },
      );
    };
  }

  public registerVueErrorHandler() {
    Vue.config.errorHandler = (
      exception: Error,
      vm: Vue,
      info: string,
    ): void => {
      this.handle(
        exception,
        { level: 'error', report: true, render: true },
        { isVue: true, info },
      );
    };
  }

  public registerVueWarnHandler() {
    Vue.config.warnHandler = (
      msg: string,
      vm: Vue,
      trace: string,
    ): void => {
      this.handle(
        msg,
        { level: 'warning', report: true },
        { isVue: true, trace },
      );
    };
  }
}

const exceptionHandler = new ExceptionHandler();

export default exceptionHandler;
