import { DirectiveFunction } from 'vue';

import masker from './masker';

const evt = (name: string): Event => {
  const e = document.createEvent('Event');
  e.initEvent(name, true, true);
  return e;
};

const masked: DirectiveFunction = (el: HTMLElement | HTMLInputElement, binding): void => {
  let elInput: HTMLInputElement = el as HTMLInputElement;

  const isInput = elInput instanceof HTMLInputElement;
  if (!isInput) {
    const nodeInput = elInput?.querySelector('input');
    elInput = nodeInput as HTMLInputElement;
  }

  if (!(elInput instanceof HTMLInputElement)) {
    return;
  }

  const mask = binding.value;
  if (typeof mask !== 'string') return;

  if (binding.arg === 'enable') {
    // eslint-disable-next-line no-param-reassign
    elInput.oninput = (event) => {
      if (!event.isTrusted) return;

      let position = elInput.selectionEnd!;
      const digit = elInput.value[position - 1];
      // eslint-disable-next-line no-param-reassign
      elInput.value = masker(elInput.value, mask);
      while (position < elInput.value.length && elInput.value.charAt(position - 1) !== digit) {
        position += 1;
      }
      if (elInput === document.activeElement) {
        elInput.setSelectionRange(position, position);
        setTimeout(() => {
          elInput.setSelectionRange(position, position);
        }, 0);
      }

      elInput.dispatchEvent(evt('input'));
    };

    const newDisplay = masker(elInput.value, mask);
    if (newDisplay !== elInput.value) {
      // eslint-disable-next-line no-param-reassign
      elInput.value = newDisplay;
      elInput.dispatchEvent(evt('input'));
    }
  }
};

export default masked;
