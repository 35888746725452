import { VueConstructor } from 'vue';
import DesktopApp from '@/DesktopApp.vue';
import store from './store';
import router from './router/desktop';

export default function desktopApp(Vue: VueConstructor) {
  return new Vue({
    router,
    store,
    render: h => h(DesktopApp),
  });
}
