<template>
  <div class="layout-login-mobile">
    <header class="layout-login-mobile__header">
      <div class="layout-login-mobile__header-before">
        <slot name="headerBefore"></slot>
      </div>
      <IconProfiLogo
        width="130"
        height="38"
      />
    </header>
    <div class="layout-login-mobile__main">
      <slot></slot>
    </div>
    <footer class="layout-login-mobile__action">
      <slot name="action"></slot>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import IconProfiLogo from '@/components/icons/IconProfiLogo.vue';

export default defineComponent({
  name: 'LayoutLoginMobile',
  components: {
    IconProfiLogo,
  },
});
</script>

<style lang="scss" scoped>
.layout-login-mobile {
  --layout-login-mobile-main-justify-content: var(--custom-layout-login-mobile-main-justify-content, space-between);
  --layout-login-mobile-slot-offset-w: 60px;
  --layout-login-mobile-header-h: 64px;
  --layout-login-min-action-h: var(--custom-layout-login-min-action-h, 80px);

  height: 100%;
}

.layout-login-mobile__header {
  position: relative;
  display: flex;
  justify-content: center;
  height: var(--layout-login-mobile-header-h);
  padding: gridCalc(2) var(--layout-login-mobile-slot-offset-w, 60px);
  border-bottom: 1px solid #eff3f8;
}

.layout-login-mobile__action {
  position: fixed;
  right: 0;
  bottom: gridCalc(3);
  left: 0;
  z-index: $z-idx-over;
}

.layout-login-mobile__header-before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: var(--layout-login-mobile-slot-offset-w, 60px);
  height: 100%;
}

.layout-login-mobile__main {
  display: flex;
  flex-direction: column;
  justify-content: var(--layout-login-mobile-main-justify-content);
  min-height: calc(100vh - var(--layout-login-mobile-header-h));
  padding-bottom: calc(var(--layout-login-min-action-h) + #{gridCalc(3)});
}
</style>
