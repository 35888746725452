import VueRouter, { RawLocation, Route } from 'vue-router';

import { Store } from 'vuex';
import { webViewIOS } from '@/services/webView';

import store from '@/store';

import authStorage, { IAuthStorage } from '@/services/authTokenStorage';
import { updateDescription, updateTitle } from '@/services/metaUpdate';
import { isMobileApp } from '@/utils/helpers';
import wsClientWrapper from '@/services/wsClient/wsWrapper';
import sentryReporter from '@/services/exception-handler/handlers/sentry-reporter';

export const PARTNER_ID_EXPIRED_GAP = 2.592e+9;
export const LS_ADMITAD_UID_KEY = 'admitad_uid';
export const LS_ADMITAD_UID_EXPIRED_KEY = 'admitad_uid_expired';

const requireAuth = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
) => {
  if (!authStorage.isLoggedIn) {
    const redirectPath = to.query?.redirect || to.fullPath;
    next({
      name: 'auth',
      query: { redirect: redirectPath },
    });
  } else {
    next();
  }
};

const requireProductExist = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
) => {
  if (store.state.clients.currentAccess?.product.is_archived) {
    next({ name: 'subs-management-unavailable' });
  } else {
    next();
  }
};


const reportPageGuard = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
) => {
  const isUserLogin = authStorage.isLoggedIn;

  if (isUserLogin) {
    next();
  } else if (isMobileApp()) {
    next({
      name: 'auth',
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    next({ path: `/report/guest/${to.params.uuid}` });
  }
};

const requireUnauth = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
) => {
  if (authStorage.isLoggedIn) {
    next(from ? from.path : {
      name: 'home',
    });
  } else {
    next();
  }
};

const logout = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
) => {
  if (to.params.type === 'delete') {
    // authCtx.actions.delete();
    next({ name: 'profile-delete-reasons', params: { type: 'delete' } });
  } else {
    if (window.jivo_api) {
      window.jivo_api.close();
    }

    webViewIOS.onLogout();

    let rawLocation: RawLocation;
    authStorage.logout();
    wsClientWrapper.unsubscribeAllCentrifugeInstances();

    const isFromReport = from.name === 'report';
    const isNotMobileApp = !isMobileApp();

    if (isFromReport && isNotMobileApp) {
      rawLocation = { name: 'report-guest', params: { uuid: from.params.uuid } };
    } else {
      // Если поле redirect есть в query, то после авторизации - пользователь должен оказаться на этой странице.
      const redirectQuery = to.query?.redirect === '/' ? null : to.query?.redirect;
      // В случае, если поля redirect нет, то пользователь должен оказаться на той странице из которой его
      const redirectPath = redirectQuery || window.location.pathname;
      rawLocation = { name: 'auth', query: { redirect: redirectPath } };
    }

    store.dispatch('resetState');
    next(rawLocation);
  }
};

const profileDeleteReasons = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
): void => {
  if (to.params.type === 'delete') {
    next();
  } else {
    next({ name: 'home' });
  }
};

const setMetaTags = (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
): void => {
  const brandName = 'Автокод Профи';
  const defaultTitle = `${brandName} — приложение для проверки автомобилей`;
  updateTitle(to.meta?.title ? `${to.meta.title} - ${brandName}` : defaultTitle);
  const defaultDescription = 'Проверяйте любое количество автомобилей, создавайте списки, используйте фильтры для быстро поиска нужных вариантов — Автокод для профессионалов.';
  updateDescription(to.meta?.description || defaultDescription);
  next();
};

const getAdmitadHandler = (store: Store<any>) => (
  to: Route,
  from: Route,
  next: (to?: RawLocation) => void,
): void => {
  const removeAdmitadFromStorage = () => {
    localStorage.removeItem(LS_ADMITAD_UID_KEY);
    localStorage.removeItem(LS_ADMITAD_UID_EXPIRED_KEY);
  };
  const uid: any = to.query.admitad_uid;

  if (authStorage.isLoggedIn) {
    const uidFromLocalStorage = localStorage.getItem(LS_ADMITAD_UID_KEY);

    if (uidFromLocalStorage) {
      const expired = localStorage.getItem(LS_ADMITAD_UID_EXPIRED_KEY);
      const isIDValid = Number(expired) >= Date.now();

      if (isIDValid) {
        store.dispatch('clients/sendPartnerId', {
          programName: 'admitad',
          id: uidFromLocalStorage,
          callback: removeAdmitadFromStorage,
        });
      }
      removeAdmitadFromStorage();
    } else if (uid) {
      store.dispatch('clients/sendPartnerId', {
        programName: 'admitad',
        id: uid,
      });
    }
  } else if (uid) {
    const expiredDate = Date.now() + PARTNER_ID_EXPIRED_GAP;
    localStorage.setItem(LS_ADMITAD_UID_KEY, uid);
    localStorage.setItem(LS_ADMITAD_UID_EXPIRED_KEY, expiredDate.toString());
  }

  next();
};

const getGuestBuyHandler = (authStorage: IAuthStorage) => (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  if (authStorage.isLoggedIn) {
    next({ name: 'subs-tariffs' });
  } else {
    next();
  }
};

const routerErrorHandler = (error: Error) => {
  if (/loading chunk .* failed/i.test(error.message)) {
    window.location.reload();
  }
};

// eslint-disable-next-line func-names
const getCloseSidebarMenuHandler = function (store: Store<any>) {
  return (to: Route, from: Route, next: (to?: RawLocation) => void) => {
    store.commit('app/setIsShowNavMenuSidebar', false);
    next();
  };
};

const routerCatchErrorHandler = function routerCatchErrorHandler(error: Error) {
  const { isNavigationFailure, NavigationFailureType } = VueRouter;
  if (isNavigationFailure(error, NavigationFailureType.redirected)) {
    // eslint-disable-next-line no-console
    console.trace(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
    sentryReporter.handle(error, { level: 'error' }, {});
  }
};

const isSamePage = (from: Route, to: Route) => from.path === to.path;

export {
  requireAuth,
  requireUnauth,
  logout,
  profileDeleteReasons,
  setMetaTags,
  routerErrorHandler,
  getAdmitadHandler,
  getCloseSidebarMenuHandler,
  reportPageGuard,
  requireProductExist,
  routerCatchErrorHandler,
  getGuestBuyHandler,
  isSamePage,
};
