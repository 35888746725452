import PageReset from '@/pages/mobile/auth/PageReset.vue';
import PageRestore from '@/pages/mobile/auth/PageRestore.vue';
import { logout, requireAuth, requireUnauth } from '@/router/utils';
const Auth = () => import('@/pages/mobile/auth/PageAuth.vue');
const Reg = () => import('@/pages/mobile/auth/PageReg.vue');
const PageConfirmPhone = () => import('@/pages/mobile/auth/PageConfirmPhone.vue');
const PageConfirmUserData = () => import('@/pages/mobile/auth/PageConfirmUserData.vue');

export default [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      title: 'Авторизация',
      layout: 'div',
    },
    beforeEnter: requireUnauth,
  },
  {
    path: '/reg',
    name: 'reg',
    component: Reg,
    meta: {
      title: 'Регистрация',
      layout: 'div',
    },
    beforeEnter: requireUnauth,
  },
  {
    path: '/reset',
    name: 'reset',
    component: PageReset,
    meta: {
      title: 'Восстановление',
      layout: 'div',
    },
    beforeEnter: requireUnauth,
  },
  {
    path: '/restore',
    name: 'restore',
    component: PageRestore,
    meta: {
      title: 'Восстановление',
      layout: 'div',
    },
    beforeEnter: requireUnauth,
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: logout,
  },
  {
    path: '/reg/confirm-phone',
    name: 'reg-confirm-phone',
    component: PageConfirmPhone,
    meta: {
      title: 'Подтверди номер телефона',
      layout: 'div',
    },
  },
  {
    path: '/confirm-user-data',
    name: 'confirm-user-data',
    component: PageConfirmUserData,
    meta: {
      title: 'Подтверди данные профиля',
      layout: 'div',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/confirm-user-data/confirm-phone',
    name: 'user-data-confirm-phone',
    component: PageConfirmPhone,
    meta: {
      title: 'Подтверди номер телефона',
      layout: 'div',
    },
  },
];
