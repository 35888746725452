import { Mutations } from 'vuex-smart-module';

import ProductsState from '@/store/modules/products/state';
import {
  ProductAccess, ProductCarOwnerReport, ProductReportPack, ProductSubscription,
} from '@/rpc-types/profi';

export default class ProductsMutations extends Mutations<ProductsState> {
  setAccessList(list: ProductAccess[]) {
    this.state.accessList = [...list];
  }

  setSubscriptionsList(list: ProductSubscription[]) {
    this.state.subscriptionsList = [...list];
  }

  setReportPacksList(list: ProductReportPack[]) {
    this.state.reportPacksList = [...list];
  }

  setCarOwnerReportTypeList(list: ProductCarOwnerReport[]) {
    this.state.carOwnerReportTypeList = [...list];
  }
}
