import {
  CardCryptogram, Ip, UUID,
  IRPCBankCardsAdd, IRPCBankCardsDelete, IRPCBankCardsSetMain,
} from '@/rpc-types/profi';

import HttpClient from '@/services/httpClient/index';

export default class BankCardsApi extends HttpClient {
  addBankCard = (card_cryptogram: CardCryptogram, ip?: Ip) => (
    this.call<IRPCBankCardsAdd>('bankCards.add', { card_cryptogram, ip }, 'profi')
  )

  deleteBankCard = (uuid: UUID) => (
    this.call<IRPCBankCardsDelete>('bankCards.delete', { uuid }, 'profi')
  )

  setMainBankCard = (uuid: UUID) => (
    this.call<IRPCBankCardsSetMain>('bankCards.setMain', { uuid }, 'profi')
  )
}
