import dayjs from 'dayjs';
import Vue from 'vue';

import 'dayjs/locale/ru';
import './plugins';
import './directives';
import './filters';
import AppSettingsStorage from '@/services/appSettingsStorage';
import initCybertonica from '@/services/cybertonica';
import exceptionHandler from '@/services/exception-handler';
import initFirebaseAnalytics from '@/services/firebaseAnalytics';
import initMindBox from '@/services/mindBox';
import checkPartnerProgramIdentifier from '@/services/partnerProgram';
import { PPSpectrumClass } from '@/services/ppSpectrum';
import { isMobile } from '@/utils/helpers';

import '@avto-internal/avtocod-meta-ui/dist/main.css';

import desktopApp from './desktop-app';
import mobileApp from './mobile-app';

dayjs.locale('ru');

const appSettingsStorage = new AppSettingsStorage();
const isProductionEnv = appSettingsStorage.environment === 'production';

initMindBox();
PPSpectrumClass.init(isProductionEnv);
initFirebaseAnalytics();
initCybertonica(appSettingsStorage.cybertonicaTeam);
checkPartnerProgramIdentifier();

exceptionHandler.init();

Vue.config.productionTip = false;
Vue.config.devtools = !isProductionEnv;

const init = (stateMobile: boolean) => {
  const vm = stateMobile ? mobileApp(Vue) : desktopApp(Vue);
  vm.$mount('#app');

  return vm;
};

init(isMobile());

// export for quick switching between apps just by click
export default init;
