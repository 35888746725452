import { helpers } from '@vuelidate/validators';
import { NORMALIZED_PHONE_LENGTH } from '@/consts/report';
import dayjs from 'dayjs';

export const uniqueValue = (param: Array<any>) => helpers.withParams(
  { type: 'uniqueValue', value: param },
  function uniqueValueRule(value: string, vm: any): boolean {
    // @ts-ignore
    return !helpers.ref(param, this, vm).includes(value);
  },
);
// Отличается от стандартного валидатора `vuelidate` тем что принимает любой регистр символов
export const emailValidator = (value: string): boolean => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return emailRegex.test(value);
};

export const phoneLengthValidator = (value: string) => value.replace(/[-\s()]/g, '').length === NORMALIZED_PHONE_LENGTH;

export function isPassportValid(passport: string): boolean {
  const re = /\d{4}\s\d{6}/;
  return re.test(passport);
}

export function isDateValid(dateOfBirth: string): boolean {
  const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;

  if (!dateRegex.test(dateOfBirth)) {
    return false;
  }

  const [day, month, year] = dateOfBirth.split('.').map(Number);

  const inputDate = new Date(year, month - 1, day); // Месяцы в объекте Date начинаются с 0

  if (inputDate.getFullYear() !== year
    || inputDate.getMonth() !== month - 1
    || inputDate.getDate() !== day) {
    return false;
  }

  const dateObj = dayjs(`${year}-${month}-${day}`);
  const currentDateObj = dayjs();

  if (dateObj.isAfter(currentDateObj)) {
    return false;
  }

  return true;
}
