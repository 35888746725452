interface IPackageVersion {
  /**
   * @description Get application version value in string representation
   */
  getVersion(): string
}

export default class PackageVersion implements IPackageVersion {
  /**
   * @description Get application vertion that defined in package.json file
   * (webpack config should contains plugin with "constant" definition)
   */
  getVersion(): string {
    let version;
    try {
      // @ts-ignore
      version = String(PACKAGE_VERSION);

      if (!/[vV]?\d+\.\d+\.\d+.*/.test(version)) {
        throw new Error('Wrong version value passed');
      }
    } catch {
      version = '0.0.0';
    }
    return version.replace(/^[vV]/, '');
  }
}
