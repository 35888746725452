import { render, staticRenderFns } from "./AppNotifications.vue?vue&type=template&id=9f8cd83a&scoped=true"
import script from "./AppNotifications.vue?vue&type=script&lang=ts"
export * from "./AppNotifications.vue?vue&type=script&lang=ts"
import style0 from "./AppNotifications.vue?vue&type=style&index=0&id=9f8cd83a&prod&lang=scss&module=true"
import style1 from "./AppNotifications.vue?vue&type=style&index=1&id=9f8cd83a&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9f8cd83a",
  null
  
)

export default component.exports