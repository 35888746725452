import { Getters } from 'vuex-smart-module';
import { getVin } from '@/services/ReportStateManager/get-report-fields';

import ReportsState from '@/store/modules/reports/state';
import {
  Comment, UUID,
} from '@/rpc-types/profi';

import { IReportListEntityWithUserContent, IReportWithUserContent } from '@/types/entities/report';

export default class ReportsGetters extends Getters<ReportsState> {
  get reportFullComments(): Comment[] {
    return this.state.reportFull.comments || [];
  }

  get isJapanCar(): boolean {
    return !(getVin(this.state.reportFull));
  }

  get someReportContentIsMissing(): boolean {
    return !(this.state.reportFull.comments);
  }

  getReportAndUUID(uuid?: UUID): { report: IReportListEntityWithUserContent | IReportWithUserContent, uuid: UUID} | null {
    if (uuid) {
      const reportInList = this.state.list.find(report => report.uuid === uuid);
      if (reportInList) {
        return { report: reportInList, uuid };
      }
    } else {
      const { reportFull } = this.state;
      if (reportFull) {
        return { report: reportFull, uuid: this.state.reportFull.uuid };
      }
    }
    return null;
  }

  get hasPromoShortReports(): boolean {
    return this.state.promoShortCount > 0;
  }
}
