import { reportPageGuard } from '@/router/utils';

const Report = () => import('@/pages/mobile/report/PageReport.vue');

export default [
  {
    path: '/report/:uuid',
    name: 'report',
    component: Report,
    beforeEnter: reportPageGuard,
    props: true,
    meta: {
      title: 'Отчёт',
      theme: 'lilac',
    },
  },
];
