import { Actions } from 'vuex-smart-module';
import ProductsState from '@/store/modules/products/state';
import ProductsGetters from '@/store/modules/products/getters';
import ProductsMutations from '@/store/modules/products/mutations';
import ProductsApi from '@/services/httpClient/api/profi/products.api';

export default class ProductsActions extends Actions <ProductsState, ProductsGetters, ProductsMutations, ProductsActions> {
  productApi!: ProductsApi;

  $init() {
    this.productApi = new ProductsApi();
  }

  async updateProducts(): Promise<void> {
    const { data: { result, error } } = await this.productApi.fetchList();

    if (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    } else if (result?.products) {
      const { products } = result || {};

      this.mutations.setSubscriptionsList(products.subscriptions);
      this.mutations.setAccessList(products.access);
      this.mutations.setReportPacksList(products.report_packs);
      this.mutations.setCarOwnerReportTypeList(products.car_owners);
    }
  }
}
