import {
  ClientPassword,
  Email,
  FirstName,
  IRPCAuthLogin,
  IRPCAuthRegister,
  IRPCPasswordReset,
  IRPCPasswordResetEmail,
  IRPCProfileResendVerificationCode,
  IRPCProfileVerify,
  LastName,
  Phone,
  Token,
  UUID,
  VerificationCode,
} from '@/rpc-types/profi';
import HttpClient from '@/services/httpClient/index';

export default class AuthApi extends HttpClient {
  login = (email: Email, password: ClientPassword) =>
    this.call<IRPCAuthLogin>('auth.login', { email, password }, 'profi');

  register = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    email: Email,
    phone: Phone,
    password: ClientPassword,
    first_name?: FirstName,
    last_name?: LastName
  ) =>
    this.call<IRPCAuthRegister>(
      'auth.register',
      {
        email,
        phone,
        password,
        first_name: first_name || null,
        last_name: last_name || null,
      },
      'profi'
    );

  verifyProfile = (client_verify_uuid: UUID, verification_code: VerificationCode) =>
    this.call<IRPCProfileVerify>(
      'profile.verify',
      {
        client_verify_uuid,
        verification_code,
      },
      'profi'
    );

  resendVerificationCode = (client_verify_uuid: UUID) =>
    this.call<IRPCProfileResendVerificationCode>(
      'profile.resendVerificationCode',
      {
        client_verify_uuid,
      },
      'profi'
    );

  reset = (email: Email) =>
    this.call<IRPCPasswordResetEmail>(
      'password.resetEmail',
      {
        email,
      },
      'profi'
    );

  confirmReset = (email: Email, password: ClientPassword, token: Token) =>
    this.call<IRPCPasswordReset>(
      'password.reset',
      {
        email,
        password,
        token,
      },
      'profi'
    );
}
