import { IdentifierType } from '@/components/legacy/common/general/check-auto/tooltip-data';
import {
  getDeviceType, TdeviceType,
} from '@/utils/helpers';
import { UUID } from '@/rpc-types/profi';

import {
  TDays,
  TOptionsType,
  TProductType,
  TTariffType,
} from '@/utils/helpers/analytics/utils';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyC9sPHT6XW3Fo8VQ_LH2jkc_iuD6S73c88',
  authDomain: 'avtocod-profi.firebaseapp.com',
  databaseURL: 'https://avtocod-profi.firebaseio.com',
  projectId: 'avtocod-profi',
  storageBucket: 'avtocod-profi.appspot.com',
  messagingSenderId: '19037389122',
  appId: '1:19037389122:web:9b9105ff328b6bce8808db',
  measurementId: 'G-1FGQFKM0RM',
};

export type TFirebaseAnalyticsProduct = {
  price?: number,
  product_id?: string,
  tariff_type?: TTariffType,
  days?: TDays,
  name: string,
  product_type?: TProductType,
  options_type?: TOptionsType
  value?: number | string,
  count?: number | string,
}

let pendingOperations: Function[] = [];

let firebaseAnalyticsInstance: {
  logEvent: (name: string, params?: Object) => void
};
let firebaseAnalyticsLogEvent: (name: string, params?: Object) => void;

let deviceType: TdeviceType = 'web';

export default function initFirebaseAnalytics(): void {
  deviceType = getDeviceType();

  const scriptFirebase = document.createElement('script');
  scriptFirebase.src = 'https://www.gstatic.com/firebasejs/8.2.4/firebase-app.js';

  const scriptFireBaseAnalytics = document.createElement('script');
  scriptFireBaseAnalytics.src = 'https://www.gstatic.com/firebasejs/8.2.4/firebase-analytics.js';

  document.body.appendChild(scriptFirebase);

  scriptFirebase.addEventListener('load', () => {
    document.body.appendChild(scriptFireBaseAnalytics);
  });

  scriptFireBaseAnalytics.addEventListener('load', () => {
    (window as any).firebase.initializeApp(FIREBASE_CONFIG);

    firebaseAnalyticsInstance = (window as any).firebase.analytics();
    firebaseAnalyticsLogEvent = (event: string, params?: Object) => {
      firebaseAnalyticsInstance.logEvent(event, {
        device_type: deviceType,
        user_id: FirebaseAnalytics.userUUID,
        ...(params || {}),
      });
    };

    pendingOperations.forEach((operation) => {
      operation();
    });

    pendingOperations = [];
  });
}

class FirebaseAnalytics {
  static userUUID: UUID;

  static setUserUUID(uuid: UUID): void {
    FirebaseAnalytics.userUUID = uuid;
  }

  static getUserUUID(): UUID {
    return FirebaseAnalytics.userUUID;
  }

  static onLogin(): void {
    firebaseAnalyticsLogEvent('on_login');
  }

  static onLogout(): void {
    firebaseAnalyticsLogEvent('exit');
  }

  static onRegistration(): void {
    firebaseAnalyticsLogEvent('registration');
  }

  static onClickShowTariffsBtn(page: 'subs' | 'subs_tariffs' | null = null): void {
    const eventName = page ? `tariff_${page}` : 'tariff';
    firebaseAnalyticsLogEvent(eventName);
  }

  static onTopSTOBannerLinkClick() {
    firebaseAnalyticsLogEvent('click_banner_topsto');
  }

  static onClickGuestLinkOnReport(): void {
    firebaseAnalyticsLogEvent('copy_link_guest');
  }

  static onClickPrintLink(): void {
    firebaseAnalyticsLogEvent('print_report');
  }

  static onClickAddCommentary(): void {
    firebaseAnalyticsLogEvent('add_comment');
  }

  static onClickAddMobileCommentary(): void {
    firebaseAnalyticsLogEvent('add_comment_mob');
  }

  static onClickLinkToCheckPerson(): void {
    firebaseAnalyticsLogEvent('click_check_person');
  }

  // todo что делать с событием после изменения списка отчетов?
  static onSearchOnReports(): void {
    firebaseAnalyticsLogEvent('search_report');
  }

  // todo что делать с событием после изменения списка отчетов?
  static clickCheckAutoButton(): void {
    firebaseAnalyticsLogEvent('click_check_auto_button');
  }

  static generateShortReport(type: IdentifierType) {
    firebaseAnalyticsLogEvent('generate_short_report', { type });
  }

  static visitPage(page_id: 'tariff', from?: string) {
    firebaseAnalyticsLogEvent(`${page_id}_page`, { from });
  }

  static subscriptionAction(type: 'unsubscribe' | 'restore' | 'first',
    {
      days,
      tariff_type,
      product_id,
      product_type,
      options_type,
      value,
      count,
      price,
    }: TFirebaseAnalyticsProduct): void {
    firebaseAnalyticsLogEvent(`${type}_purchase`, {
      days,
      tariff_type,
      product_id,
      product_type,
      options_type,
      value,
      count,
      price,
    });
  }

  static unsubscribeAction(): void {
    firebaseAnalyticsLogEvent('unsubscribe');
  }

  static paymentAction({
    name,
    tariff_type,
    product_id,
    product_type,
    options_type,
    value,
    count,
    price,
  }: TFirebaseAnalyticsProduct): void {
    firebaseAnalyticsLogEvent('purchase', {
      affilation: 'SpectrumData',
      currency: 'RUB',
      value,
      items: [
        {
          id: product_id,
          price,
          name,
          category: product_type,
          brand: 'Avtocod',
          quantity: count || 1,
          variant: tariff_type,
          list_name: options_type,
        },
      ],
    });
  }
}

export const FirebaseAnalyticsManager = new Proxy(FirebaseAnalytics, {
  get(target, prop, receiver) {
    return (...args: any[]) => {
      if (firebaseAnalyticsInstance) {
        return Reflect.get(target, prop, receiver)(...args);
      }
      const MAX_OPERATION_STACK = 10;
      if (pendingOperations.length < MAX_OPERATION_STACK) {
        pendingOperations.push(Reflect.get(target, prop, receiver).bind(null, ...args));
      }
      return null;
    };
  },
});
