import Vue from 'vue';
import Router from 'vue-router';
import type { Route } from 'vue-router';

import {
  requireAuth, setMetaTags, routerErrorHandler, getAdmitadHandler, getCloseSidebarMenuHandler, isSamePage,
} from '@/router/utils';

import store from '@/store';
import ads from './ads';
import auth from './auth';
import subs from './subs';
import search from './search';
import checkAuto from './check-auto';
import report from './report';
import about from './about';
import profile from './profile';
import guestBuy from './guest-buy';
import referral from './referral';
import apps from './apps';

const ReportsPage = () => import('@/pages/mobile/ReportsPage.vue');
const Support = () => import('@/pages/mobile/PageSupport.vue');
const NotFound = () => import('@/pages/mobile/errors/PageNotFound.vue');
const PageReportGuest = () => import('@/pages/mobile/report/PageReportGuest.vue');
const PageHome = () => import('@/pages/mobile/PageHome.vue');


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: __dirname,
  scrollBehavior(to: Route, from: Route, savedPosition) {
    if (isSamePage(from, to)) {
      return null;
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home/:entry*',
      name: 'home',
      component: PageHome,
      beforeEnter: requireAuth,
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsPage,
      beforeEnter: requireAuth,
      meta: {
        title: 'Проверенные автомобили',
        description: 'Проверяйте юридическую чистоту, историю объявлений, определяйте рыночную стоимость автомобилей. Попробуйте наши инструменты для профессионалов авторынка уже сегодня!',
      },
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      beforeEnter: requireAuth,
      meta: {
        title: 'Техническая поддержка',
        description: 'Появились вопросы? Позвоните нам! Техническая поддержка Автокод Профи',
      },
    },
    {
      path: '/report/guest/:uuid',
      name: 'report-guest',
      component: PageReportGuest,
      props: true,
      meta: {
        title: 'Отчёт',
        theme: 'lilac',
      },
    },
    ...ads,
    ...auth,
    ...subs,
    ...search,
    ...checkAuto,
    ...report,
    ...about,
    ...profile,
    ...guestBuy,
    ...referral,
    ...apps,
    {
      path: '*',
      component: NotFound,
    },
  ],
});

router.beforeEach(setMetaTags);
router.beforeEach(getAdmitadHandler(store));
router.beforeEach(getCloseSidebarMenuHandler(store));
router.onError(routerErrorHandler);

export default router;
