import { Module, createMapper } from 'vuex-smart-module';

import BankCardsState from '@/store/modules/bank-cards/state';
import BankCardsGetters from '@/store/modules/bank-cards/getters';
import BankCardsMutations from '@/store/modules/bank-cards/mutations';
import BankCardsActions from '@/store/modules/bank-cards/actions';

const bankCards = new Module({
  state: BankCardsState,
  getters: BankCardsGetters,
  mutations: BankCardsMutations,
  actions: BankCardsActions,
});

export const bankCardsMapper = createMapper(bankCards);

export default bankCards;
