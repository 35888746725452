/**
 * @param {string | number} v - input value
 * @return {string}
 */
export default function digit(v: string | number) {
  const number = Number(v);

  if (Number.isNaN(number)) {
    return '';
  }

  return number.toLocaleString('ru');
}
