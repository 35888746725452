import { INotification } from '@/types/components/common-app-notification';
import { IMileage } from '@/types/components/report-full-content-mileage';
import { TContentPaths } from '@/types/entities/report';
import { Arguments } from '@/types/util-types';
import { TExternalBreadCrumb } from '@/types/components/bread-crumbs';

interface IEventBus<Events> {
  on<E extends keyof Events>(event: E, fn: Events[E]): this
  off<E extends keyof Events>(event: E, fn: Events[E]): this
  emit<E extends keyof Events>(event: E, ...args: Arguments<Events[E]>): boolean
}

interface IEvents {
  scrollTo: (state: boolean) => void
  isPledgesStatus: (state: boolean) => void
  pushNotification: (message: INotification) => void
  closeNotificationAfterAction: (id: string) => void
  calculateMileages: (mileages: IMileage[]) => void
  detectMileagesDisparities: (disparities: number) => void
  setBarBottom: (state: boolean) => void
  setButtonFloat: (state: boolean) => void
  goToAuth: () => void
  selectAsideActiveElement: (index: number) => void
  toggleReportFullAside: (state: boolean) => void
  highlightReportFullAsideBlock: (blockName: TContentPaths) => void
  highlightElement: (elem: HTMLElement, timeInSeconds?: number, timeoutBeforeAnimate?: number, padding?: number) => void
  scrollToBlock: (instant: boolean, elementID: string) => void
  updatePageTitle: (title: string) => void
  updateExternalBreadCrumbs: (externalBreadCrumbs: TExternalBreadCrumb[]) => void
  updateRouteMntg: (route: { path: string, mata: { [key: string]: any } }) => void
  showPresetCreateTooltip: () => void
  filterPresetCreated: () => void
  filterPresetUpdated: () => void
  editFilterPreset: () => void
  setActiveFilterPreset: () => void
  emitPathForMF: (path: string) => void
  toggleNavSide: (state: boolean) => void
  setStatusShowCustomHead: (isShowCustomHead: boolean) => void
}

class EventBus implements IEventBus<IEvents> {
  private readonly events = {} as { [key: string]: ((...args: any[]) => any)[] };

  public on<E extends keyof IEvents>(event: E, fn: IEvents[E]): this {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(fn);

    return this;
  }

  public off<E extends keyof IEvents>(event: E, fn: IEvents[E]): this {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter(listener => listener !== fn);
    }

    return this;
  }

  public emit<E extends keyof IEvents>(event: E, ...args: Arguments<IEvents[E]>): boolean {
    if (!this.events[event]) return false;

    this.events[event].forEach(fn => fn(...args));

    return true;
  }
}

const eventBus = new EventBus();

export default eventBus;
