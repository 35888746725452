import { Module, createMapper } from 'vuex-smart-module';

import ProfileState from '@/store/modules/profile/state';
import ProfileGetters from '@/store/modules/profile/getters';
import ProfileMutations from '@/store/modules/profile/mutations';
import ProfileActions from '@/store/modules/profile/actions';

const profile = new Module({
  state: ProfileState,
  getters: ProfileGetters,
  mutations: ProfileMutations,
  actions: ProfileActions,
});

export const profileMapper = createMapper(profile);

export default profile;
