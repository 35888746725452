import {
  Info, InfoAccess, InfoSubscription, Product, ProductAccess,
} from '@/rpc-types/profi';


// Хрупкая система вязатся на содержимое, необходимо вязаться по типу
// eslint-disable-next-line import/prefer-default-export
export function instanceOfInfoSubscription(info: Info): info is InfoSubscription {
  return 'is_recurrent' in info;
}

export function instanceOfInfoAccess(info: Info): info is InfoAccess {
  return 'end_date' in info;
}

export function instanceOfProductAccess(product: Product): product is ProductAccess {
  return product.type === 'access';
}
