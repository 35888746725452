import WsClient, { IWsClientOptions, TWebSocketInstanceName } from '@/services/wsClient/index';
import { refreshCentrifugeTokenHandler } from '@/services/centrifuge/helpers';
import AppSettingsStorage from '@/services/appSettingsStorage';

export const appSettingsStorage = new AppSettingsStorage();

export class WsClientWrapper {
    instances: { [key in TWebSocketInstanceName]?: WsClient } = {};

    unsubscribeAllCentrifugeInstances(): void {
      Object.values(this.instances).forEach((instance) => {
        instance?.disconnect();
      });
    }

    getInstance(instanceName: TWebSocketInstanceName, jwt: string): WsClient {
      let instance = this.instances[instanceName];
      if (!instance) {
        const config: IWsClientOptions = {
          uri: `${appSettingsStorage.centrifugeUri}`,
          onRefresh: refreshCentrifugeTokenHandler,
          accessToken: jwt,
        };
        instance = new WsClient(config);
        this.instances[instanceName] = instance;
      }
      return instance;
    }
}

const wsClientWrapper = new WsClientWrapper();

export default wsClientWrapper;
