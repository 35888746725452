<template>
  <svg id="icon-link-app-store"
       viewBox="0 0 215 64"
       fill="none"
  >
    <rect x="0.75"
          y="0.75"
          width="213.146"
          height="62.5"
          rx="31.25"
          fill="none"
          fill-opacity="0.15"
          stroke="currentColor"
          stroke-width="1.5"
    />
    <path d="M65.109 39.3784C64.0877 41.605 63.6135 42.6271 62.3369 44.5982C60.5131 47.3724 57.9598 50.8036 54.8228 50.8036C52.0142 50.8401 51.2847 48.9785 47.4912 48.9785C43.6977 49.015 42.8952 50.8036 40.0866 50.8036C36.9132 50.7671 34.5058 47.6644 32.682 44.9267C27.6118 37.2248 27.1011 28.1723 30.2016 23.354C32.4266 19.9228 35.9283 17.9517 39.2111 17.9517C42.5669 17.9517 44.6825 19.7768 47.4547 19.7768C50.1539 19.7768 51.7953 17.9517 55.6618 17.9517C58.5798 17.9517 61.6803 19.5577 63.9053 22.2954C56.6466 26.2376 57.8138 36.6042 65.109 39.3784Z"
          fill="currentColor"
    />
    <path d="M52.7075 14.8489C54.13 13.0238 55.1878 10.5051 54.7866 7.87695C52.4886 8.02296 49.7894 9.48305 48.2209 11.4177C46.7984 13.1333 45.6312 15.7249 46.0689 18.2071C48.5857 18.2801 51.1755 16.7835 52.7075 14.8489Z"
          fill="currentColor"
    />
    <path d="M76.089 27.7934L69.9077 45.9777H72.7344L74.567 40.3825H80.5309L82.4257 45.9777H85.3455L79.1331 27.7934H76.089ZM77.5489 30.8086C77.7664 31.6168 77.9838 32.456 78.2634 33.3886L79.9407 38.2066H75.2193L76.8656 33.3575C77.1141 32.5493 77.3315 31.71 77.5489 30.8086Z"
          fill="currentColor"
    />
    <path d="M93.3594 32.5183C91.4956 32.5183 89.9736 33.2332 88.9796 34.632L88.8554 32.8292H86.3394V33.0467C86.4015 34.1658 86.4325 35.4092 86.4325 37.181V51.2H89.1971V44.6723C90.0357 45.698 91.4025 46.2887 92.9555 46.2887C94.5708 46.2887 96.0307 45.667 97.1178 44.5168C98.3293 43.2424 98.9505 41.4084 98.9505 39.2325C98.9505 37.3053 98.3914 35.6267 97.3663 34.4144C96.3724 33.1711 94.9435 32.5183 93.3594 32.5183ZM92.676 34.7875C95.1299 34.7875 96.2171 37.0566 96.2171 39.3258C96.2171 42.2166 94.8193 44.0195 92.5517 44.0195C90.9986 44.0195 89.694 42.9626 89.3213 41.4706C89.2592 41.2219 89.2281 40.9422 89.2281 40.5692V38.3622C89.2281 38.1756 89.2592 37.927 89.3213 37.6783C89.3213 37.6161 89.3524 37.5229 89.3524 37.4607C89.7562 35.9065 91.1229 34.7875 92.676 34.7875Z"
          fill="currentColor"
    />
    <path d="M107.524 32.5183C105.66 32.5183 104.138 33.2332 103.144 34.632L103.02 32.8292H100.535V33.0467C100.597 34.1658 100.628 35.4092 100.628 37.181V51.2H103.392V44.6723C104.231 45.698 105.598 46.2887 107.151 46.2887C108.766 46.2887 110.226 45.667 111.313 44.5168C112.525 43.2424 113.146 41.4084 113.146 39.2325C113.146 37.3053 112.587 35.6267 111.562 34.4144C110.537 33.1711 109.108 32.5183 107.524 32.5183ZM106.84 34.7875C109.294 34.7875 110.381 37.0566 110.381 39.3258C110.381 42.2166 108.984 44.0195 106.716 44.0195C105.194 44.0195 103.858 42.9626 103.486 41.4706C103.423 41.2219 103.392 40.9422 103.392 40.5692V38.3622C103.392 38.1756 103.423 37.927 103.486 37.6783C103.486 37.6161 103.517 37.5229 103.517 37.4607C103.92 35.9065 105.287 34.7875 106.84 34.7875Z"
          fill="currentColor"
    />
    <path d="M125.26 35.5023C122.682 34.5076 121.874 33.7305 121.874 32.2695C121.874 31.0572 122.837 29.8139 125.011 29.8139C126.782 29.8139 127.931 30.4356 128.242 30.6221L128.49 30.7775L129.267 28.4772L129.112 28.384C128.646 28.1042 127.341 27.4825 125.105 27.4825C121.595 27.4825 119.141 29.5652 119.141 32.5182C119.141 34.9117 120.663 36.5902 123.955 37.7715C126.285 38.6418 127.155 39.5432 127.155 41.0664C127.155 42.8071 125.819 43.864 123.645 43.864C122.247 43.864 120.787 43.4599 119.669 42.7449L119.42 42.5895L118.675 44.9519L118.799 45.0452C119.824 45.729 121.781 46.2264 123.458 46.2264C128.18 46.2264 129.857 43.3355 129.857 40.8799C129.888 38.3931 128.553 36.8078 125.26 35.5023Z"
          fill="currentColor"
    />
    <path d="M134.827 29.6895L132.125 30.5287V32.829H130.137V35.0359H132.125V41.7813C132.125 43.3976 132.435 44.5478 133.088 45.2627C133.709 45.9466 134.641 46.3196 135.759 46.3196C136.784 46.3196 137.529 46.102 137.871 45.9776L138.026 45.9155L137.902 43.7085L137.654 43.8017C137.312 43.895 136.939 43.9572 136.38 43.9572C135.324 43.9572 134.858 43.2733 134.858 41.688V35.0359H138.182V32.829H134.858V29.6895H134.827Z"
          fill="currentColor"
    />
    <path d="M144.984 32.5183C141.133 32.5183 138.461 35.3781 138.461 39.5123C138.461 43.4911 141.071 46.2886 144.798 46.2886H144.829C147.966 46.2886 151.352 44.0817 151.352 39.2636C151.352 37.3053 150.762 35.6267 149.613 34.3834C148.401 33.1711 146.817 32.5183 144.984 32.5183ZM144.891 34.6942C147.376 34.6942 148.494 37.1188 148.494 39.3569C148.494 42.1234 146.972 44.1127 144.829 44.1127H144.798C142.717 44.1127 141.195 42.1544 141.195 39.419C141.226 37.0877 142.344 34.6942 144.891 34.6942Z"
          fill="currentColor"
    />
    <path d="M159.739 32.6427L159.584 32.6116C159.335 32.5494 159.149 32.5183 158.9 32.5183C157.471 32.5183 156.167 33.3576 155.452 34.7253L155.359 32.7981H152.905L152.936 33.0157C152.998 34.1036 153.03 35.3159 153.03 36.9634L152.998 45.9467H155.763V38.9217C155.763 38.5487 155.794 38.1757 155.825 37.8648C156.136 36.1863 157.285 35.0983 158.714 35.0983C158.993 35.0983 159.211 35.1294 159.459 35.1294L159.739 35.1605V32.6427Z"
          fill="currentColor"
    />
    <path d="M165.92 32.4872C162.255 32.4872 159.708 35.4091 159.708 39.6055C159.708 43.6464 162.255 46.2575 166.199 46.2575C168.281 46.2575 169.709 45.7913 170.548 45.4182L170.703 45.3561L170.175 43.3045L169.958 43.3978C169.212 43.7086 168.218 44.0194 166.51 44.0194C165.33 44.0194 162.565 43.6154 162.379 40.0407L171.263 40.0717L171.294 39.8852C171.356 39.6055 171.387 39.2325 171.387 38.7351C171.387 35.8443 169.958 32.4872 165.92 32.4872ZM165.734 34.6009C166.572 34.6009 167.256 34.8496 167.753 35.378C168.529 36.1862 168.684 37.3674 168.684 37.9269H162.41C162.689 36.3416 163.683 34.6009 165.734 34.6009Z"
          fill="currentColor"
    />
    <path d="M69.9077 15.391C70.6843 15.2977 71.554 15.2045 72.548 15.2045C74.3185 15.2045 75.592 15.5775 76.4307 16.2613C77.3005 16.9763 77.7974 17.971 77.7974 19.3698C77.7974 20.7686 77.3005 21.9187 76.4307 22.7269C75.5299 23.5351 74.039 23.9702 72.2063 23.9702C71.3055 23.9702 70.5911 23.9392 69.9388 23.877V15.391H69.9077ZM71.1813 23.0066C71.4919 23.0688 71.9578 23.0688 72.4237 23.0688C75.064 23.0688 76.4928 21.7322 76.4928 19.4319C76.4928 17.3804 75.2193 16.1059 72.6101 16.1059C71.9578 16.1059 71.4919 16.1681 71.1502 16.2302V23.0066H71.1813Z"
          fill="currentColor"
    />
    <path d="M82.2081 24.0323C80.3754 24.0323 78.9155 22.82 78.9155 20.8617C78.9155 18.7791 80.4376 17.5668 82.3323 17.5668C84.2892 17.5668 85.6249 18.8412 85.6249 20.7374C85.6249 23.0065 83.8544 24.0323 82.2081 24.0323ZM82.2392 23.1619C83.4195 23.1619 84.3203 22.1672 84.3203 20.7374C84.3203 19.6805 83.7301 18.375 82.2702 18.375C80.8103 18.375 80.158 19.5872 80.158 20.7684C80.1891 22.1672 81.0277 23.1619 82.2392 23.1619Z"
          fill="currentColor"
    />
    <path d="M87.613 17.6912L88.5449 20.8308C88.7312 21.5146 88.9176 22.1674 89.0419 22.8201H89.0729C89.2282 22.1985 89.4457 21.5457 89.6942 20.8618L90.8124 17.7223H91.8685L92.9246 20.7997C93.1731 21.5146 93.3905 22.1985 93.5458 22.8201H93.5769C93.7011 22.1985 93.8875 21.5457 94.1049 20.7997L95.0679 17.6912H96.3103L94.1049 23.877H92.9557L91.8995 20.924C91.6511 20.2091 91.4647 19.6185 91.2783 18.8724H91.2472C91.0919 19.6185 90.8745 20.2712 90.626 20.924L89.5078 23.8459H88.3585L86.2773 17.6602H87.613V17.6912Z"
          fill="currentColor"
    />
    <path d="M97.5528 19.3698C97.5528 18.717 97.5528 18.2197 97.4907 17.6912H98.609L98.6711 18.6859H98.7021C99.0438 18.1264 99.8514 17.5358 100.97 17.5358C101.933 17.5358 103.392 18.0332 103.392 20.178V23.877H102.15V20.3023C102.15 19.3076 101.746 18.4684 100.566 18.4684C99.7582 18.4684 99.1059 18.9968 98.8885 19.6185C98.8264 19.7739 98.7953 19.9604 98.7953 20.1469V23.877H97.5528V19.3698Z"
          fill="currentColor"
    />
    <path d="M105.412 14.8004H106.654V23.877H105.412V14.8004Z"
          fill="currentColor"
    />
    <path d="M111.531 24.0323C109.698 24.0323 108.238 22.82 108.238 20.8617C108.238 18.7791 109.76 17.5668 111.655 17.5668C113.612 17.5668 114.948 18.8412 114.948 20.7374C114.948 23.0065 113.177 24.0323 111.531 24.0323ZM111.562 23.1619C112.742 23.1619 113.643 22.1672 113.643 20.7374C113.643 19.6805 113.053 18.375 111.593 18.375C110.133 18.375 109.481 19.5872 109.481 20.7684C109.512 22.1672 110.35 23.1619 111.562 23.1619Z"
          fill="currentColor"
    />
    <path d="M121.346 22.3849C121.346 22.9133 121.377 23.4417 121.439 23.8769H120.321L120.197 23.0998H120.166C119.793 23.5972 119.048 24.0323 118.054 24.0323C116.656 24.0323 115.941 23.162 115.941 22.2605C115.941 20.7685 117.401 19.9603 120.073 19.9603V19.836C120.073 19.3386 119.917 18.4061 118.52 18.4061C117.867 18.4061 117.215 18.5615 116.718 18.8723L116.438 18.1263C116.998 17.8155 117.836 17.5979 118.706 17.5979C120.818 17.5979 121.315 18.8723 121.315 20.1157V22.3849H121.346ZM120.135 20.7374C118.768 20.7063 117.215 20.9239 117.215 22.1362C117.215 22.8822 117.743 23.2241 118.364 23.2241C119.265 23.2241 119.855 22.7268 120.042 22.1984C120.073 22.074 120.104 21.9497 120.104 21.8254V20.7374H120.135Z"
          fill="currentColor"
    />
    <path d="M129.267 14.8003V22.2605C129.267 22.8201 129.267 23.4417 129.329 23.8458H128.211L128.148 22.7579H128.117C127.745 23.4417 126.906 23.9702 125.788 23.9702C124.142 23.9702 122.837 22.6957 122.837 20.8307C122.837 18.748 124.266 17.5046 125.912 17.5046C126.968 17.5046 127.683 17.9398 127.993 18.4372H128.024V14.7692H129.267V14.8003ZM128.024 20.209C128.024 20.0536 128.024 19.867 127.962 19.7116C127.776 18.9967 127.092 18.4372 126.161 18.4372C124.856 18.4372 124.11 19.4629 124.11 20.7996C124.11 22.074 124.794 23.0998 126.129 23.0998C126.968 23.0998 127.745 22.6025 127.962 21.7632C127.993 21.6078 128.024 21.4523 128.024 21.2658V20.209Z"
          fill="currentColor"
    />
    <path d="M137.157 24.0323C135.324 24.0323 133.864 22.82 133.864 20.8617C133.864 18.7791 135.386 17.5668 137.281 17.5668C139.238 17.5668 140.574 18.8412 140.574 20.7374C140.574 23.0065 138.803 24.0323 137.157 24.0323ZM137.188 23.1619C138.368 23.1619 139.269 22.1672 139.269 20.7374C139.269 19.6805 138.679 18.375 137.219 18.375C135.759 18.375 135.107 19.5872 135.107 20.7684C135.107 22.1672 135.976 23.1619 137.188 23.1619Z"
          fill="currentColor"
    />
    <path d="M142.127 19.3698C142.127 18.717 142.127 18.2197 142.064 17.6912H143.183L143.245 18.6859H143.276C143.618 18.1264 144.425 17.5358 145.543 17.5358C146.506 17.5358 147.966 18.0332 147.966 20.178V23.877H146.724V20.3023C146.724 19.3076 146.32 18.4684 145.14 18.4684C144.332 18.4684 143.68 18.9968 143.462 19.6185C143.4 19.7739 143.369 19.9604 143.369 20.1469V23.877H142.127V19.3698Z"
          fill="currentColor"
    />
    <path d="M154.52 16.2302V17.7223H156.322V18.5926H154.52V21.9187C154.52 22.6958 154.769 23.1309 155.452 23.1309C155.794 23.1309 155.98 23.0999 156.167 23.0688L156.229 23.9391C155.98 24.0013 155.607 24.0946 155.11 24.0946C154.52 24.0946 154.054 23.9081 153.775 23.6283C153.433 23.2864 153.278 22.7269 153.278 22.0119V18.6548H152.222V17.7844H153.278V16.6343L154.52 16.2302Z"
          fill="currentColor"
    />
    <path d="M157.72 14.8004H158.962V18.6548H158.993C159.18 18.344 159.49 18.0331 159.894 17.8777C160.267 17.6601 160.702 17.5669 161.168 17.5669C162.099 17.5669 163.559 18.0642 163.559 20.209V23.877H162.317V20.3334C162.317 19.3387 161.913 18.4994 160.733 18.4994C159.925 18.4994 159.304 18.9967 159.055 19.6184C158.993 19.7738 158.962 19.9293 158.962 20.1469V23.877H157.72V14.8004Z"
          fill="currentColor"
    />
    <path d="M166.293 20.9862C166.324 22.5093 167.38 23.131 168.622 23.131C169.523 23.131 170.082 22.9756 170.517 22.8202L170.735 23.5973C170.3 23.7838 169.523 24.0014 168.436 24.0014C166.324 24.0014 165.05 22.7269 165.05 20.8929C165.05 19.0279 166.293 17.5358 168.281 17.5358C170.517 17.5358 171.107 19.3076 171.107 20.4578C171.107 20.6753 171.107 20.8619 171.076 20.9862H166.293ZM169.927 20.178C169.927 19.4631 169.585 18.344 168.219 18.344C166.945 18.344 166.417 19.4009 166.293 20.178H169.927Z"
          fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseIconSocialAppStore',
});
</script>
