<template>
  <svg width="268"
       height="80"
       viewBox="0 0 268 80"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1"
          y="1"
          width="266"
          height="78"
          rx="39"
          stroke="currentColor"
          stroke-width="2"
    />
    <g clip-path="url(#clip0_24773_30276)">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M77.3728 61.7947C67.37 61.7947 62.3686 61.7947 59.2612 58.7308C56.1538 55.6669 56.1538 50.7354 56.1538 40.8726V39.1291C56.1538 29.2662 56.1538 24.3349 59.2612 21.2709C62.3686 18.207 67.37 18.207 77.3728 18.207H79.141C89.1438 18.207 94.1452 18.207 97.2527 21.2709C100.36 24.3349 100.36 29.2662 100.36 39.1291V40.8726C100.36 50.7354 100.36 55.6669 97.2527 58.7308C94.1452 61.7947 89.1438 61.7947 79.141 61.7947H77.3728ZM87.2951 46.4427L90.282 47.1784C91.6348 47.5117 92.9462 46.508 92.9462 45.1395V30.9447C92.9462 29.74 92.1112 28.6894 90.9201 28.3961L82.3531 26.2859C81.0002 25.9527 79.6887 26.9564 79.6887 28.325V30.8837L74.2909 29.5542C72.9382 29.221 71.6267 30.2247 71.6267 31.5933V34.152L66.2285 32.8225C64.8757 32.4892 63.5643 33.4929 63.5643 34.8614V49.056C63.5643 50.2609 64.3993 51.3114 65.5904 51.6048L74.1574 53.7149C75.5102 54.0482 76.8218 53.0445 76.8218 51.6759V49.3159L76.8223 37.5021C76.8223 36.3046 76.0552 35.6103 75.7764 35.3976C75.7232 35.3569 75.7085 35.2813 75.7466 35.2267C75.7841 35.173 75.8518 35.1475 75.9152 35.1664C77.0439 35.5024 78.124 36.6435 78.2318 38.0782L78.6045 48.9187C78.6168 49.2748 78.8505 49.5836 79.1867 49.6996L82.2198 50.4467C83.5726 50.78 84.8841 49.7763 84.8841 48.4078L84.8863 34.2353C84.8863 33.0377 84.1192 32.3434 83.8404 32.1307C83.7873 32.0901 83.7726 32.0145 83.8106 31.9599C83.8482 31.9062 83.9159 31.8807 83.9792 31.8995C85.1079 32.2356 86.188 33.3766 86.2959 34.8114L86.6687 45.651C86.6814 46.0219 86.9354 46.341 87.2951 46.4427Z"
            fill="currentColor"
      />
      <path d="M126.251 58.3539H122.872L117.512 50.9762H114.692V58.3539H111.816V38.9459H118.74C120.08 38.9459 121.281 39.2029 122.342 39.7167C123.403 40.2306 124.222 40.9463 124.799 41.864C125.395 42.7633 125.692 43.8002 125.692 44.9748C125.692 46.4431 125.236 47.7002 124.324 48.7463C123.412 49.774 122.193 50.453 120.667 50.7835L126.251 58.3539ZM114.692 41.5613V48.3884H118.74C119.931 48.3884 120.89 48.0764 121.616 47.4524C122.361 46.8101 122.733 45.9842 122.733 44.9748C122.733 43.9654 122.361 43.1487 121.616 42.5248C120.89 41.8824 119.931 41.5613 118.74 41.5613H114.692Z" fill="currentColor"/>
      <path d="M133.882 58.6292C132.858 58.6292 131.937 58.3814 131.118 57.886C130.299 57.3721 129.656 56.6746 129.191 55.7938C128.726 54.8945 128.493 53.885 128.493 52.7655V44.1214H131.229V52.5454C131.229 53.6281 131.536 54.5091 132.15 55.1881C132.765 55.8671 133.556 56.2067 134.524 56.2067C135.529 56.2067 136.366 55.858 137.037 55.1606C137.707 54.4631 138.042 53.5914 138.042 52.5454V44.1214H140.778V58.3539H138.823L138.181 56.2067C137.716 56.9775 137.102 57.5739 136.338 57.996C135.594 58.4181 134.775 58.6292 133.882 58.6292Z" fill="currentColor"/>
      <path d="M150.978 58.6291C149.172 58.6291 147.599 58.1703 146.259 57.2526C144.938 56.3167 144.035 55.0504 143.551 53.4537L146.315 53.0132C146.855 54.0409 147.497 54.8026 148.242 55.2981C148.986 55.7752 149.898 56.0139 150.978 56.0139C152.076 56.0139 152.969 55.7752 153.658 55.2981C154.347 54.8026 154.691 54.1327 154.691 53.2885C154.691 52.6644 154.496 52.1414 154.105 51.7193C153.732 51.2972 153.258 50.9577 152.681 50.7007C152.104 50.4438 151.35 50.1593 150.419 49.8474C149.172 49.4436 148.149 49.0398 147.348 48.6361C146.548 48.2323 145.868 47.6634 145.31 46.9293C144.77 46.1768 144.5 45.2225 144.5 44.0663C144.5 42.9834 144.77 42.0382 145.31 41.2307C145.868 40.4049 146.632 39.7717 147.599 39.3312C148.567 38.8907 149.647 38.6706 150.838 38.6706C152.495 38.6706 153.919 39.1018 155.11 39.9644C156.301 40.8086 157.148 41.974 157.651 43.4606L154.942 43.901C154.57 43.0568 154.04 42.4145 153.351 41.974C152.662 41.5152 151.853 41.2858 150.922 41.2858C149.917 41.2858 149.089 41.5243 148.437 42.0015C147.786 42.4604 147.46 43.0935 147.46 43.901C147.46 44.5067 147.646 45.0205 148.018 45.4426C148.409 45.8648 148.884 46.2043 149.442 46.4612C150.019 46.7182 150.782 47.0118 151.732 47.3422C152.941 47.7459 153.947 48.1589 154.747 48.581C155.566 48.9848 156.255 49.5629 156.813 50.3153C157.371 51.0678 157.651 52.013 157.651 53.1508C157.651 54.252 157.353 55.2155 156.757 56.0413C156.18 56.8672 155.38 57.5096 154.356 57.9684C153.351 58.4089 152.225 58.6291 150.978 58.6291Z" fill="currentColor"/>
      <path d="M169.208 55.9864L168.957 58.3538H166.416C164.908 58.3538 163.764 57.9868 162.982 57.2526C162.2 56.5185 161.809 55.4632 161.809 54.0869V46.5163H159.324V44.1213H161.809L162.563 39.992H164.545V44.1213H168.957V46.5163H164.545V54.0869C164.545 55.3532 165.169 55.9864 166.416 55.9864H169.208Z" fill="currentColor"/>
      <path d="M178.333 58.6291C176.862 58.6291 175.55 58.3171 174.396 57.6931C173.243 57.0691 172.34 56.1973 171.687 55.0778C171.036 53.9584 170.71 52.6828 170.71 51.2513C170.71 49.8198 171.036 48.5443 171.687 47.4248C172.34 46.3052 173.243 45.4335 174.396 44.8095C175.55 44.1672 176.862 43.846 178.333 43.846C179.803 43.846 181.115 44.1672 182.269 44.8095C183.424 45.4335 184.326 46.3052 184.978 47.4248C185.629 48.5443 185.955 49.8198 185.955 51.2513C185.955 52.6828 185.629 53.9584 184.978 55.0778C184.326 56.1973 183.424 57.0691 182.269 57.6931C181.115 58.3171 179.803 58.6291 178.333 58.6291ZM178.333 56.1515C179.748 56.1515 180.892 55.7019 181.767 54.8026C182.66 53.9033 183.108 52.7195 183.108 51.2513C183.108 49.7831 182.66 48.5994 181.767 47.7001C180.892 46.7824 179.748 46.3236 178.333 46.3236C176.918 46.3236 175.774 46.7824 174.899 47.7001C174.023 48.5994 173.586 49.7831 173.586 51.2513C173.586 52.7011 174.023 53.8849 174.899 54.8026C175.774 55.7019 176.918 56.1515 178.333 56.1515Z" fill="currentColor"/>
      <path d="M188.889 58.3539V44.1214H190.871L191.458 46.1586C191.885 45.4427 192.416 44.9289 193.049 44.617C193.681 44.2865 194.436 44.1214 195.311 44.1214H195.98L196.204 46.7092H194.64C193.636 46.7092 192.881 46.9936 192.379 47.5625C191.877 48.1315 191.625 48.9023 191.625 49.8749V58.3539H188.889Z" fill="currentColor"/>
      <path d="M211.846 51.1412C211.846 51.5082 211.837 51.7927 211.819 51.9945H199.896C200.046 53.261 200.548 54.2703 201.404 55.0228C202.26 55.7752 203.35 56.1515 204.67 56.1515C205.583 56.1515 206.392 55.9771 207.1 55.6285C207.825 55.2614 208.375 54.7842 208.747 54.1969L211.316 54.6099C210.758 55.8946 209.855 56.8856 208.607 57.583C207.379 58.2804 206.011 58.6291 204.504 58.6291C203.071 58.6291 201.786 58.3171 200.651 57.6931C199.516 57.0508 198.631 56.1698 197.999 55.0504C197.365 53.9309 197.049 52.6644 197.049 51.2513C197.049 49.8198 197.365 48.5443 197.999 47.4248C198.631 46.3052 199.506 45.4335 200.622 44.8095C201.739 44.1672 203.014 43.846 204.448 43.846C205.862 43.846 207.127 44.1672 208.244 44.8095C209.381 45.4335 210.264 46.3052 210.897 47.4248C211.53 48.5259 211.846 49.7647 211.846 51.1412ZM204.448 46.241C203.35 46.241 202.4 46.5622 201.599 47.2045C200.818 47.8469 200.296 48.7094 200.036 49.7923H208.859C208.617 48.7278 208.096 47.8744 207.295 47.232C206.513 46.5714 205.565 46.241 204.448 46.241Z" fill="currentColor"/>
      <path d="M115.885 31.5023C114.906 31.5023 114.027 31.275 113.246 30.8205C112.465 30.356 111.853 29.7136 111.408 28.8934C110.973 28.0732 110.756 27.1492 110.756 26.1215C110.756 25.0937 110.973 24.1747 111.408 23.3643C111.853 22.5441 112.461 21.9067 113.231 21.4521C114.012 20.9876 114.892 20.7554 115.87 20.7554C116.927 20.7554 117.866 21.0222 118.686 21.5559C119.516 22.0796 120.109 22.7911 120.465 23.6904L118.953 23.9276C118.676 23.3742 118.266 22.9443 117.723 22.638C117.189 22.3217 116.586 22.1636 115.914 22.1636C115.223 22.1636 114.605 22.3316 114.061 22.6676C113.528 23.0036 113.108 23.473 112.801 24.0758C112.505 24.6688 112.357 25.3506 112.357 26.1215C112.357 26.9022 112.505 27.5939 112.801 28.1967C113.108 28.7897 113.533 29.2541 114.076 29.5901C114.62 29.9261 115.242 30.0941 115.944 30.0941C116.636 30.0941 117.248 29.936 117.782 29.6198C118.326 29.2937 118.741 28.8391 119.027 28.256L120.539 28.4932C120.164 29.4122 119.556 30.1435 118.716 30.687C117.876 31.2306 116.932 31.5023 115.885 31.5023Z" fill="currentColor"/>
      <path d="M128.285 31.3541H126.373L123.541 28.1819H123.082V31.3541H121.629V23.6904H123.082V26.7885H123.526L126.239 23.6904H127.988L124.698 27.3962L128.285 31.3541Z" fill="currentColor"/>
      <path d="M131.739 23.5422C132.688 23.5422 133.434 23.8535 133.978 24.476C134.531 25.0887 134.808 25.9485 134.808 27.0553V31.3541H133.77L133.429 30.1534C133.182 30.5388 132.851 30.86 132.436 31.1169C132.021 31.3738 131.522 31.5023 130.939 31.5023C130.425 31.5023 129.965 31.3985 129.56 31.191C129.155 30.9835 128.839 30.6969 128.611 30.3313C128.384 29.9557 128.271 29.5357 128.271 29.0713C128.271 28.3499 128.557 27.7718 129.13 27.3369C129.703 26.9021 130.534 26.6847 131.621 26.6847H133.355C133.315 26.0819 133.147 25.6174 132.851 25.2913C132.554 24.9553 132.159 24.7873 131.665 24.7873C131.29 24.7873 130.958 24.8763 130.672 25.0541C130.385 25.232 130.168 25.4643 130.02 25.7508L128.671 25.5285C128.868 24.9158 129.249 24.4316 129.812 24.0758C130.375 23.72 131.018 23.5422 131.739 23.5422ZM131.25 30.272C131.873 30.272 132.377 30.0694 132.762 29.6642C133.157 29.2492 133.355 28.6809 133.355 27.9595V27.8706H131.665C130.38 27.8706 129.738 28.256 129.738 29.0268C129.738 29.4023 129.876 29.7037 130.153 29.931C130.43 30.1583 130.795 30.272 131.25 30.272Z" fill="currentColor"/>
      <path d="M140.501 28.5673C140.263 28.676 139.982 28.76 139.656 28.8193C139.329 28.8786 139.008 28.9082 138.692 28.9082C137.773 28.9082 137.066 28.6513 136.572 28.1374C136.078 27.6235 135.831 26.8972 135.831 25.9584V23.6904H137.284V25.9584C137.284 27.0553 137.813 27.6038 138.87 27.6038C139.512 27.6038 140.056 27.4901 140.501 27.2628V23.6904H141.953V31.3541H140.501V28.5673Z" fill="currentColor"/>
      <path d="M146.679 23.5422C147.628 23.5422 148.374 23.8535 148.917 24.476C149.471 25.0887 149.747 25.9485 149.747 27.0553V31.3541H148.71L148.369 30.1534C148.122 30.5388 147.791 30.86 147.376 31.1169C146.961 31.3738 146.462 31.5023 145.878 31.5023C145.365 31.5023 144.905 31.3985 144.5 31.191C144.095 30.9835 143.779 30.6969 143.551 30.3313C143.324 29.9557 143.21 29.5357 143.21 29.0713C143.21 28.3499 143.497 27.7718 144.07 27.3369C144.643 26.9021 145.473 26.6847 146.56 26.6847H148.295C148.255 26.0819 148.087 25.6174 147.791 25.2913C147.494 24.9553 147.099 24.7873 146.605 24.7873C146.229 24.7873 145.898 24.8763 145.612 25.0541C145.325 25.232 145.108 25.4643 144.959 25.7508L143.611 25.5285C143.808 24.9158 144.189 24.4316 144.752 24.0758C145.315 23.72 145.958 23.5422 146.679 23.5422ZM146.19 30.272C146.812 30.272 147.316 30.0694 147.702 29.6642C148.097 29.2492 148.295 28.6809 148.295 27.9595V27.8706H146.605C145.32 27.8706 144.678 28.256 144.678 29.0268C144.678 29.4023 144.816 29.7037 145.093 29.931C145.37 30.1583 145.735 30.272 146.19 30.272Z" fill="currentColor"/>
      <path d="M153.513 31.3541H151.364V23.6904H152.772V29.7235H152.994L156.093 23.6904H158.242V31.3541H156.834V25.3358H156.611L153.513 31.3541ZM154.818 22.6973C154.175 22.6973 153.637 22.5293 153.202 22.1933C152.777 21.8474 152.525 21.3335 152.446 20.6517H153.632C153.681 20.9679 153.805 21.21 154.002 21.378C154.21 21.5361 154.482 21.6152 154.818 21.6152C155.154 21.6152 155.421 21.5361 155.618 21.378C155.826 21.21 155.949 20.9679 155.989 20.6517H157.189C157.101 21.3335 156.839 21.8474 156.404 22.1933C155.979 22.5293 155.45 22.6973 154.818 22.6973Z" fill="currentColor"/>
      <path d="M163.291 31.3541H161.838V25.0245H159.185V23.6904H165.944V25.0245H163.291V31.3541Z" fill="currentColor"/>
      <path d="M174.033 27.4704C174.033 27.668 174.028 27.8212 174.019 27.9299H167.689C167.768 28.6118 168.035 29.1553 168.489 29.5604C168.944 29.9656 169.522 30.1682 170.224 30.1682C170.708 30.1682 171.138 30.0743 171.513 29.8866C171.899 29.6889 172.19 29.432 172.388 29.1158L173.752 29.3381C173.455 30.0299 172.976 30.5635 172.314 30.939C171.662 31.3145 170.935 31.5023 170.135 31.5023C169.374 31.5023 168.692 31.3343 168.089 30.9983C167.486 30.6524 167.017 30.1781 166.681 29.5753C166.345 28.9725 166.177 28.2906 166.177 27.5297C166.177 26.7588 166.345 26.072 166.681 25.4692C167.017 24.8664 167.481 24.397 168.074 24.061C168.667 23.7151 169.344 23.5422 170.105 23.5422C170.856 23.5422 171.528 23.7151 172.121 24.061C172.724 24.397 173.193 24.8664 173.529 25.4692C173.865 26.0621 174.033 26.7292 174.033 27.4704ZM170.105 24.8318C169.522 24.8318 169.018 25.0047 168.593 25.3506C168.178 25.6965 167.901 26.161 167.763 26.744H172.447C172.319 26.1708 172.042 25.7113 171.617 25.3654C171.202 25.0097 170.698 24.8318 170.105 24.8318Z" fill="currentColor"/>
      <path d="M180.556 31.3541H178.406V23.6904H179.814V29.7235H180.037L183.135 23.6904H185.284V31.3541H183.876V25.3358H183.654L180.556 31.3541Z" fill="currentColor"/>
      <path d="M191.164 27.4407C191.569 27.5593 191.905 27.7767 192.172 28.0929C192.439 28.3993 192.572 28.7798 192.572 29.2343C192.572 29.6593 192.449 30.0447 192.202 30.3906C191.955 30.7266 191.599 30.9983 191.135 31.2058C190.68 31.4035 190.151 31.5023 189.548 31.5023C188.738 31.5023 188.041 31.3145 187.458 30.939C186.875 30.5536 186.49 30.0397 186.302 29.3974L187.651 29.175C187.977 29.8866 188.605 30.2423 189.534 30.2423C190.048 30.2423 190.438 30.1484 190.705 29.9607C190.981 29.763 191.12 29.4913 191.12 29.1454C191.12 28.839 190.991 28.592 190.734 28.4042C190.487 28.2165 190.087 28.1226 189.534 28.1226H188.526V26.8478H189.534C190.008 26.8478 190.369 26.7588 190.616 26.581C190.873 26.4031 191.001 26.1659 191.001 25.8694C191.001 25.5532 190.863 25.2963 190.586 25.0986C190.319 24.901 189.988 24.8022 189.593 24.8022C189.208 24.8022 188.862 24.896 188.555 25.0838C188.249 25.2716 188.017 25.5285 187.859 25.8546L186.51 25.6174C186.737 24.9553 187.127 24.4464 187.681 24.0906C188.244 23.725 188.896 23.5422 189.637 23.5422C190.171 23.5422 190.65 23.636 191.075 23.8238C191.51 24.0116 191.846 24.2735 192.083 24.6094C192.33 24.9454 192.454 25.321 192.454 25.736C192.454 26.161 192.335 26.5217 192.098 26.8181C191.861 27.1146 191.55 27.3221 191.164 27.4407Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_24773_30276">
        <rect width="155.692"
              height="46"
              fill="white"
              transform="translate(56.1538 17)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseIconRustore',
});
</script>
