<template>
  <base-button
    :class="$style.button"
    :to="to"
    data-jest-id="base-button"
    @click="$emit('click', $event)"
  >
    <slot />
  </base-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';

import BaseButton from '@/components/legacy/base/BaseButton.vue';

export default defineComponent({
  name: 'BaseHeaderButtonAction',
  components: {
    BaseButton,
  },
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: '',
    },
  },
});
</script>

<style lang="scss" module>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  color: $neutral-color;
  background: transparent;
  border: 0;
  outline: none;
}
</style>
