import { Mutations } from 'vuex-smart-module';

import AppState from '@/store/modules/app/state';

export default class AppMutations extends Mutations<AppState> {
  setIsShowNavMenuSidebar(state: boolean) {
    this.state.isShowNavMenuSidebar = state;
  }
  toggleIsShowNavMenuSidebar() {
    this.state.isShowNavMenuSidebar = !this.state.isShowNavMenuSidebar;
  }
}
