<template>
  <app resolution="mobile">
    <component :is="layout">
      <router-view />
    </component>

    <SuccessfullyRegisteredAlert
      :is-show-error-alert="isShowRegisteredAlert"
      is-mobile
      @close="closeAlert"
    />
    <SuccessfullyProfileVerifiedAlert
      :is-show-error-alert="isShowProfileVerifiedAlert"
      is-mobile
      @close="closeAlert"
    />

    <portal-target
      name="modal"
      multiple
    />
    <portal-target name="header-alert" />
    <portal-target
      name="select-dialog"
      multiple
    />
    <portal-target
      name="sidebar"
      multiple
    />
    <portal-target
      name="tooltip-chart--market-value"
      multiple
    />
    <portal-target
      name="tooltip-chart--mileage"
      multiple
    />
    <portal-target
      name="photoswiper"
      multiple
    />
    <portal-target
      name="popup"
      multiple
    />
    <app-notifications />
  </app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SuccessfullyProfileVerifiedAlert from '@/components/particles/successfully-profile-verified-alert/SuccessfullyProfileVerifiedAlert.vue';
import SuccessfullyRegisteredAlert from '@/components/particles/successfully-registered-alert/SuccessfullyRegisteredAlert.vue';

import AppNotifications from '@/components/legacy/common/desktop/notifications/AppNotifications.vue';
import App from '@/components/legacy/common/general/App.vue';
import { SEARCH_PARAMS } from '@/consts/search';
import { routerCatchErrorHandler } from '@/router/utils';
import { isAuthenticationException } from '@/services/appError/exceptionChecker';
import authStorage from '@/services/authTokenStorage';
import { FirebaseAnalyticsManager } from '@/services/firebaseAnalytics';
import { TRPCResponseData } from '@/services/httpClient/types';
import wsClientWrapper from '@/services/wsClient/wsWrapper';
import { batchMapper } from '@/store/modules/batch';
import { reportsMapper } from '@/store/modules/reports';
import { reportsFacadeMapper } from '@/store/modules/reports-facade';
import eventBus from '@/utils/eventBus';

const LayoutDefault = () => import('@/layouts/mobile/LayoutDefault.vue');
const LayoutPrint = () => import('@/layouts/general/LayoutPrint.vue');
const LayoutWebview = () => import('@/layouts/mobile/LayoutWebview.vue');

export default defineComponent({
  name: 'MobileApp',
  components: {
    SuccessfullyProfileVerifiedAlert,
    SuccessfullyRegisteredAlert,
    AppNotifications,
    App,
    LayoutDefault,
    LayoutPrint,
    LayoutWebview,
  },
  data() {
    return {
      isShowRegisteredAlert: false,
      isShowProfileVerifiedAlert: false,
    };
  },
  computed: {
    layout(): string {
      return this.$route.meta?.layout || 'LayoutDefault';
    },
  },
  watch: {
    $route(to) {
      if (to.hash) {
        this.onHashchange(to.hash);
      } else {
        if (this.isShowRegisteredAlert) this.isShowRegisteredAlert = false;
        if (this.isShowProfileVerifiedAlert) this.isShowProfileVerifiedAlert = false;
      }
    },
  },
  async created(): Promise<void> {
    eventBus.on('goToAuth', this.goToAuthHandler);

    this.fetchDataForNotAuthUser();
    if (authStorage.isLoggedIn) {
      const dataForAuthUser = await this.fetchDataForAuthUser();
      // FIXME-VUE3 !92 исправить типы после обновления на vue3
      // @ts-ignore
      const responseData = Object.values(dataForAuthUser) as TRPCResponseData<unknown>[];
      const isHasAuthenticationException = responseData.some((answer) => isAuthenticationException(answer.error));
      if (!isHasAuthenticationException) {
        // FIXME-VUE3 !92 исправить типы после обновления на vue3
        // @ts-ignore
        const { profileResponse } = dataForAuthUser;
        const userUUID = profileResponse?.result?.uuid || '';
        FirebaseAnalyticsManager.setUserUUID(userUUID);

        await this.fetchReportList({
          pagination: SEARCH_PARAMS.DEFAULT_PAGINATION,
          sort: SEARCH_PARAMS.DEFAULT_SORT,
        });
        await this.fetchPromoReportsCount();
      }
    }
  },
  beforeDestroy(): void {
    eventBus.off('goToAuth', this.goToAuthHandler);
  },
  methods: {
    ...reportsMapper.mapActions(['fetchReportList']),
    ...reportsFacadeMapper.mapActions(['fetchPromoReportsCount']),
    ...batchMapper.mapActions(['fetchDataForNotAuthUser', 'fetchDataForAuthUser']),

    closeAlert() {
      window.location.hash = '';
    },

    onHashchange(hash: string): void {
      if (hash === '#show-registered-alert') {
        this.isShowRegisteredAlert = true;
        return;
      }

      if (hash === '#show-profile-verified-alert') {
        this.isShowProfileVerifiedAlert = true;
        return;
      }
    },

    goToAuthHandler(): void {
      // TODO Fix multiple router.push (main path + logout path) before main finish create error
      wsClientWrapper.unsubscribeAllCentrifugeInstances();
      const redirectUrl = this.$route.query.redirect || this.$route.path;
      this.$router.push({ name: 'logout', query: { redirect: redirectUrl } }).catch(routerCatchErrorHandler);
    },
  },
});
</script>
