import { ClientToken } from '@/rpc-types/profi';

export interface IAuthStorage {
  token: ClientToken
  isLoggedIn: boolean

  logout: () => void
}

const TOKEN_KEY = 'jwt';

class AuthStorage implements IAuthStorage {
  private readonly tokenKey = TOKEN_KEY;
  private memoryStorage: { [key in typeof TOKEN_KEY]?: ClientToken } = {};

  public get token(): ClientToken {
    if (localStorage) {
      return localStorage.getItem(this.tokenKey) || '';
    }
    return this.memoryStorage[this.tokenKey] || '';
  }

  public set token(newToken: ClientToken) {
    if (localStorage) {
      localStorage.setItem(this.tokenKey, newToken);
    } else {
      this.memoryStorage[this.tokenKey] = newToken;
    }
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.token);
  }

  public logout(): void {
    localStorage.removeItem(this.tokenKey);
  }
}

const authStorage = new AuthStorage();

export default authStorage;
