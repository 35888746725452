import Vue, { VueConstructor } from 'vue';

import Breakpoint from '@/plugins/breakpoint/service';

export const thresholds = {
  xs: 600,
  sm: 960,
  md: 1600,
  lg: 1900,
};

export const scrollBarWidth = 16;

const breakpoint = {
  install(V: VueConstructor) {
    // eslint-disable-next-line no-param-reassign
    V.prototype.$breakpoint = Vue.observable(new Breakpoint({ scrollBarWidth, thresholds }));
  },
};

Vue.use(breakpoint);
