import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';
import { cloneDeep } from 'lodash';
import { empty_report_matrix } from '@/report-samples/empty_report_matrix';
import { empty_short_report_matrix } from '@/report-samples/empty_short_report_matrix';
import { empty_report_matrix_with_items } from '@/report-samples/empty_report_matrix_with_items';
import { empty_short_report_matrix_with_items } from '@/report-samples/empty_short_report_matrix_with_items';
import {
  Report, ReportListEntity, UUID, Companies, Comment,
} from '@/rpc-types/profi';

import { ShortReport } from '@/rpc-types/short-report-types';

import {
  IReportListEntityWithUserContent, TContentPaths, TContentTypes, TReportWithCompanies,
} from '@/types/entities/report';

import ReportsState from '@/store/modules/reports/state';
import { filterItemsByReportUuid } from '@/utils/helpers';
import { fillObjectFromMatrix } from '../../../../helper-services/global-utils';

export default class ReportsMutations extends Mutations<ReportsState> {
  setReportShort(report: ShortReport): void {
    const result = report;
    if (result.content) {
      if (!result.content.content) {
        result.content.content = empty_short_report_matrix;
      } else {
        result.content.content = fillObjectFromMatrix(result.content.content, empty_short_report_matrix_with_items);
      }
    }

    Vue.set(this.state, 'reportShort', result);
  }

  clearReportShort(): void {
    Vue.set(this.state, 'reportShort', {});
  }

  clearReportFull(): void {
    Vue.set(this.state, 'reportFull', {});
  }

  setReportFull(report: Report): void {
    const result = report;
    if (result.content) {
      if (!result.content.content) {
        result.content.content = empty_report_matrix;
      } else {
        result.content.content = fillObjectFromMatrix(result.content.content, empty_report_matrix_with_items);
      }
    }

    const cloneStateReportFull = cloneDeep(this.state.reportFull);
    const newReportFull = Object.assign(cloneStateReportFull, report);
    Vue.set(this.state, 'reportFull', newReportFull);
  }

  setReportFullCompanies({ uuid, companies }: { uuid: UUID, companies: Companies }): void {
    if (this.state.reportFull.uuid === uuid) {
      const companiesForReport = companies.reduce<TReportWithCompanies['companies']>((acc, el) => {
        acc![el.inn] = el;
        return acc;
      }, {});
      Vue.set(this.state.reportFull, 'companies', companiesForReport);
    }
  }

  setReportListItem({ report, index }: {
    report: IReportListEntityWithUserContent,
    index: number
  }): void {
    Vue.set(this.state.list, index, report);
  }

  updateReportInList(report: ReportListEntity) {
    const reportIndex = this.state.list.findIndex(r => r.uuid === report.uuid);
    const reportOld = this.state.list[reportIndex];
    if (reportOld) {
      const reportWithUserContent: IReportListEntityWithUserContent = {
        ...report,
      };
      reportWithUserContent.comments = reportOld.comments;
      Vue.set(this.state.list, reportIndex, reportWithUserContent);
    }
  }

  setReportList(list: IReportListEntityWithUserContent[]): void {
    Vue.set(this.state, 'list', list);
  }

  clearReportsList(): void {
    this.state.list = [];
  }

  setReportListItemKey({ uuid, key, value }: { uuid: UUID, key: string, value: any }): void {
    const report = this.state.list.find(r => r.uuid === uuid);
    if (report) Vue.set(report, key, value);
  }

  addCommentsToReportList({ uuids, comments }: { uuids: UUID[], comments: Comment[] }) {
    uuids.forEach((uuid) => {
      const report = this.state.list.find(r => r.uuid === uuid);

      if (!report) return;

      if (!report.comments) {
        Vue.set(report, 'comments', []);
      }

      report.comments?.push(...filterItemsByReportUuid(uuid, comments));
    });
  }

  setReportContentField({ path, items, uuid }: { path: TContentPaths, items: TContentTypes[], uuid: UUID }): void {
    if (this.state.reportFull.uuid === uuid) {
      Vue.set(this.state.reportFull, path, cloneDeep(items));
    }
    const report = this.state.list.find(r => r.uuid === uuid);
    if (report) {
      Vue.set(report, path, cloneDeep(items));
    }
  }

  // Для ограничения количества добавляемых сущностей используйте константы из @/consts/report.ts
  setReportContentFieldItem({ path, item, uuid }: { path: TContentPaths, item: TContentTypes, uuid: UUID }): void {
    if (this.state.reportFull.uuid === uuid) {
      Vue.set(this.state.reportFull[path], this.state.reportFull[path].length, item);
    }
    const report = this.state.list.find(r => r.uuid === uuid);
    if (report && report[path]) {
      Vue.set(report[path]!, report[path]!.length, item);
    }
  }

  deleteReportContentFieldItem({ path, id, uuid }: { path: TContentPaths, id: number, uuid: UUID }): void {
    if (this.state.reportFull.uuid === uuid) {
      const itemIndex = (this.state.reportFull[path]! as TContentTypes[]).findIndex((item: TContentTypes) => item.id === id);
      if (itemIndex >= 0) {
        Vue.delete(this.state.reportFull[path], itemIndex);
      }
    }

    const report = this.state.list.find(r => r.uuid === uuid);
    if (report && report[path]) {
      const itemIndex = (report[path]! as TContentTypes[]).findIndex((item: TContentTypes) => item.id === id);
      if (itemIndex >= 0) {
        Vue.delete(report[path]!, itemIndex);
      }
    }
  }

  updateRefreshListStatus(newValue: boolean): void {
    this.state.listNeedRefetch = newValue;
  }

  moveReportInList({ uuid, newPos }: { uuid: UUID, newPos: number }): void {
    const reportIndex = this.state.list.findIndex(r => r.uuid === uuid);
    const report = this.state.list[reportIndex];
    if (report && newPos < this.state.list.length) {
      this.state.list.splice(reportIndex, 1);
      this.state.list.splice(newPos, 0, report);
    }
  }

  reset(): void {
    const state = new ReportsState();
    (Object.keys(state) as (keyof typeof state)[]).forEach((key) => {
      if (key !== 'reportFull') {
        Vue.set(this.state, key, state[key]);
      }
    });
  }

  setPromoShortReportCount(count: number) {
    this.state.promoShortCount = count;
  }

  setReportDataLoaded(value: boolean) {
    this.state.isReportDataLoaded = value;
  }

  setLoading(value: boolean): void {
    this.state.loading = value;
  }
}
