/**
 * Reporter of exceptions in console
 */
class ConsoleReporter {
  public handle(exception: any, config: any, context: any) {
    // eslint-disable-next-line no-console
    console.error(exception, context);
  }
}

const consoleReporter = new ConsoleReporter();

export default consoleReporter;
