import { Module, createMapper } from 'vuex-smart-module';

import BatchState from '@/store/modules/batch/state';
import BatchGetters from '@/store/modules/batch/getters';
import BatchMutations from '@/store/modules/batch/mutations';
import BatchActions from '@/store/modules/batch/actions';

const batch = new Module({
  state: BatchState,
  getters: BatchGetters,
  mutations: BatchMutations,
  actions: BatchActions,
});

export const batchMapper = createMapper(batch);

export default batch;
