import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';


import PPSpectrumdataState from '@/store/modules/ppSpectrumdata/state';
import PPSpectrumdataGetters from '@/store/modules/ppSpectrumdata/getters';
import PPSpectrumdataMutations from '@/store/modules/ppSpectrumdata/mutations';

import apiPPSpectrum from '@/services/api/PPSpectrum/ppSpectrum.api';
import FormData from 'form-data';
import { ClientPP, IApiPPSpectrum, DataPP } from '@/store/modules/ppSpectrumdata/types';
import profile from '@/store/modules/profile';


export default class PPSpectrumdataActions extends Actions<
  PPSpectrumdataState,
  PPSpectrumdataGetters,
  PPSpectrumdataMutations,
  PPSpectrumdataActions
> {
  api!: IApiPPSpectrum
  profile!: Context<typeof profile>;

  $init(store: Store<any>): void {
    this.api = apiPPSpectrum;
    this.profile = profile.context(store);
  }

  async checkRegistrationInPPSpectrum() {
    this.mutations.setStatusLoading('loading');
    const form = new FormData();
    form.append('email', this.profile.state.profile.email);
    try {
      const { data: { affiliate, status } }: {data: DataPP} = await this.api.checkStatus(form);

      if (status === 'error') {
        this.mutations.setStatusInPP(status);
      } else {
        this.mutations.setStatusInPP(status);
        this.mutations.setClientPP(affiliate);
      }

      this.mutations.setStatusLoading('success');
    } catch (err) {
      this.mutations.setStatusLoading('error');
    }
  }

  async registerInPartnerProgram(userData: ClientPP) {
    const form = new FormData();
    form.append('email', userData.email);
    form.append('first_name', userData.first_name);
    form.append('last_name', userData.last_name);
    form.append('personal_data', userData.personal_data);
    form.append('terms_conditions', userData.terms_conditions);
    try {
      const { data: { status, affiliate } }: {data: DataPP} = await this.api.registerInPartnerProgram(form);
      if (status === 'ok') {
        this.mutations.setStatusInPP(status);
        this.mutations.setClientPP(affiliate);
      } else {
        this.mutations.setStatusInPP(status);
      }
      return status;
    } catch (err) {
      this.mutations.setStatusInPP('error');
      return 'error';
    }
  }
}
