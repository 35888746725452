import * as Sentry from '@sentry/browser';

import AppSettingsStorage from '@/services/appSettingsStorage';
import PackageVersion from '@/services/packageVersion';

const appSettingsStorage = new AppSettingsStorage();
const pckVer = new PackageVersion();

// Reporter of exceptions in Sentry
class SentryReporter {
  constructor() {
    Sentry.init({
      dsn: appSettingsStorage.sentryDsn,
      environment: appSettingsStorage.environment,
      release: pckVer.getVersion(),
      ignoreErrors: [
        'DeviceUUID not set', // Ошибка на стороне mindbox
        'null',
        'Avoided redundant navigation to current location:',
        'TypeError: undefined is not an object (evaluating \'promoBlock.remove\')',
        'TypeError: undefined is not an object (evaluating \'elem.remove\')',
        'Network Error',
        /Error: Navigation cancelled from .* with a new navigation\./,
      ],
    });
  }

  // eslint-disable-next-line no-unused-vars
  public handle(exception: any, config: any, context: any) {
    if (appSettingsStorage.environment !== 'development') {
      Sentry.configureScope((scope) => {
        scope.setLevel(config.level || 'error');
        Object.keys(context).forEach((key) => {
          scope.setExtra(key, context[key]);
        });
      });

      if (typeof exception === 'string') {
        Sentry.captureMessage(exception, config.level);
      } else {
        Sentry.captureException(exception);
      }
    }
  }
}

// TODO: make SentryReporter not SINGLETON
const sentryReporter = new SentryReporter();

export default sentryReporter;
