import {
  TMindbox,
  TMindBoxPageName,
  TMindboxOrderCustomFields,
} from '@/services/mindBox/types';
import {
  Email, InsappCommissionWithdrawalAmount, Phone, UUID,
} from '@/rpc-types/profi';

declare const mindbox: TMindbox;

let pendingOperations: Function[] = [];

export default function initMindBox(): void {
  // Init MindBox
  const script = document.createElement('script');
  script.src = 'https://api.mindbox.ru/scripts/v1/tracker.js';

  script.addEventListener('load', () => {
    // eslint-disable-next-line no-shadow
    const mindbox = window.mindbox || function createMindBox(...args: any[]) { mindbox.queue.push(args); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create', {
      endpointId: 'Profiavtocod',
    });

    pendingOperations.forEach((operation) => {
      operation();
    });
    pendingOperations = [];
  });

  document.body.appendChild(script);
}

// Должен обладать только методами, при наличии статического проперти
// и его последующем `get` будет выведена ошибка
class MindBoxClass {
  static auth(email: Email, userUUID: UUID): void {
    mindbox('async', {
      operation: 'AuthorizationProfi',
      data: {
        customer: {
          email,
          ids: {
            userWebSiteID: userUUID,
          },
        },
      },
    });
  }

  static createOrder(userUUID: UUID, email: Email, phone: Phone, customFields: TMindboxOrderCustomFields): void {
    mindbox('async', {
      operation: 'GetOrderProfi',
      data: {
        customer: {
          email,
          mobilePhone: phone,
          ids: {
            userWebSiteID: userUUID,
          },
        },
        customerAction: {
          customFields,
        },
      },
    });
  }

  // todo что делать с событием после изменения списка отчетов?
  static clickCheckAutoButton(userUUID: UUID): void {
    mindbox('async', {
      operation: 'Checkcarprofi',
      data: {
        customer: {
          ids: {
            userWebSiteID: userUUID,
          },
        },
      },
    });
  }

  static openFullReport(userUUID: UUID): void {
    mindbox('async', {
      operation: 'fullreport',
      data: {
        customer: {
          ids: {
            userWebSiteID: userUUID,
          },
        },
        viewProduct: {
          product: {
            ids: {
              profiAvtocod: 'profi',
            },
          },
        },
      },
    });
  }

  static generateShortReport(userUUID: UUID): void {
    mindbox('async', {
      operation: 'summaryreport',
      data: {
        customer: {
          ids: {
            userWebSiteID: userUUID,
          },
        },
        viewProduct: {
          product: {
            ids: {
              profiAvtocod: 'summaryprofi',
            },
          },
        },
      },
    });
  }

  static generateGuestLink(userUUID: UUID): void {
    mindbox('async', {
      operation: 'reportlink',
      data: {
        customer: {
          ids: {
            userWebSiteID: userUUID,
          },
        },
      },
    });
  }

  static openPage(pageType: TMindBoxPageName, userUUID: UUID) {
    mindbox('async', {
      operation: pageType,
      data: {
        customer: {
          ids: {
            userWebSiteID: userUUID,
          },
        },
        viewProduct: {
          product: {
            ids: {
              profiAvtocod: pageType,
            },
          },
        },
      },
    });
  }

  static submitApplicationWithdrawalMoney(userUUID: UUID, email: Email, phone: Phone, withdrawalAmount: InsappCommissionWithdrawalAmount, date: string): void {
    mindbox('async', {
      operation: 'ApplicationWithdrawalMoney',
      data: {
        customerAction: {
          customFields: {
            mobilePhone: phone,
            date,
            withdrawalAmount,
          },
        },
        customer: {
          email,
          ids: {
            userWebSiteID: userUUID,
          },
        },
      },
    });
  }
}

export const MindBox = new Proxy(MindBoxClass, {
  get(target, prop, receiver) {
    return (...args: any[]) => {
      if ((window as any).mindbox) {
        return Reflect.get(target, prop, receiver)(...args);
      }
      const MAX_OPERATION_STACK = 10;
      if (pendingOperations.length < MAX_OPERATION_STACK) {
        pendingOperations.push(Reflect.get(target, prop, receiver).bind(null, ...args));
      }
      return null;
    };
  },
});
