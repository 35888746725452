import { Actions } from 'vuex-smart-module';
import ClientsState from '@/store/modules/clients/state';
import ClientsGetters from '@/store/modules/clients/getters';
import ClientsMutations from '@/store/modules/clients/mutations';

import ContactsApi from '@/services/httpClient/api/profi/contacts.api';
import { ProgramName } from '@/rpc-types/profi';
import ClientsApi from '@/services/httpClient/api/profi/clients.api';

export default class ClientsActions extends Actions<ClientsState, ClientsGetters, ClientsMutations, ClientsActions> {
  contactsApi!: ContactsApi;
  clientsApi!: ClientsApi;

  $init(): void {
    this.contactsApi = new ContactsApi();
    this.clientsApi = new ClientsApi();
  }

  async sendPartnerId({ programName, id, callback }: { programName: ProgramName, id: string, callback: Function }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: { result, error } } = await this.contactsApi.sendPartnerId(programName, id);
    if (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    } else if (callback) {
      callback();
    }
  }

  async fetchCurrentAccess() {
    try {
      const { data: { result, error } } = await this.clientsApi.fetchCurrentAccess();

      if (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }

      if (result) {
        this.mutations.setCurrentAccess(result);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
}
