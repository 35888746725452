<template>
  <LayoutLoginDesktop :isShowFooter="false">
    <ContainerLoginMain class="view-reset-desktop__container-form">
      <div class="view-reset-desktop__logo-wrap">
        <IconProfiLogo class="view-reset-desktop__logo"/>
      </div>
      <h2 class="text-display-20 view-reset-desktop__title">Восстановление пароля</h2>
      <p class="text-body-10 view-reset-desktop__msg">от учетной записи сервиса Автокод Профи</p>
      <ResetPasswordConfirm
        class="view-reset-desktop__form"
      />
    </ContainerLoginMain>
  </LayoutLoginDesktop>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconProfiLogo from '@/components/icons/IconProfiLogo.vue';
import LayoutLoginDesktop from '@/layouts/layout-login-desktop/LayoutLoginDesktop.vue';
import ContainerLoginMain from '@/components/composite/container-login-main/ContainerLoginMain.vue';
import ResetPasswordConfirm from '@/components/micro-app/reset-password-confirm/ResetPasswordConfirm.vue';

export default defineComponent({
  name: 'ResetPasswordConfirmPage',
  components: {
    ContainerLoginMain,
    LayoutLoginDesktop,
    IconProfiLogo,
    ResetPasswordConfirm,
  },
});

</script>

<style lang="scss" scoped>
.view-reset-desktop__container-form {
  margin: 0 auto;
}

.view-reset-desktop__title {
  text-align: center;
  letter-spacing: 0.64px;
  margin: gridCalc(0, 0, 1);
}

.view-reset-desktop__logo-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: gridCalc(4);
}

.view-reset-desktop__form {
  margin-top: gridCalc(4);
}

.view-reset-desktop__msg {
  text-align: center;
  font-size: 18px;
  margin: 0;
}
</style>
