type Value = string | number;

export default function dateOwners(v: Value) {
  if (v === 'несколько секунд') {
    return '1 день';
  }

  if (v === 'месяц') {
    return '1 месяц';
  }

  if (v === 'год') {
    return '1 год';
  }

  return v;
}
