import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import { Id, Reasons, UnsubscribeComment } from '@/rpc-types/profi';
import SubscriptionsApi from '@/services/httpClient/api/profi/subscriptions.api';
import clients from '@/store/modules/clients';
import SubscriptionsGetters from '@/store/modules/subscriptions/getters';
import SubscriptionsMutations from '@/store/modules/subscriptions/mutations';
import SubscriptionsState from '@/store/modules/subscriptions/state';

export default class SubscriptionsActions extends Actions<
  SubscriptionsState,
  SubscriptionsGetters,
  SubscriptionsMutations,
  SubscriptionsActions
> {
  clients!: Context<typeof clients>;
  api!: SubscriptionsApi;

  $init(store: Store<unknown>): void {
    this.clients = clients.context(store);
    this.api = new SubscriptionsApi();
  }

  async unsubscribe({
    subscription_id,
    comment,
    reasons,
  }: {
    subscription_id: Id;
    comment?: UnsubscribeComment;
    reasons?: Reasons;
  }): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      data: { error },
    } = await this.api.unsubscribe({ subscription_id, comment, reasons });

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('unsubscribe error: ', error);
    } else {
      this.clients.mutations.setCurrentAccessRecurrent(false);
    }
  }
}
