<script lang="ts">
import Vue from 'vue';
import createGrid from '@/grid/createGrid';

export default Vue.extend({ ...createGrid('g-col') });
</script>

<style lang="scss" scoped>
.g-col {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;

  // example: .g-col.xs-4.order-xs-12
  // stylelint-disable max-nesting-depth
  @each $size, $width in $breakpoints {
    @include media-min($size) {
      @for $i from 1 through $columns {
        &.#{$size}-#{$i} {
          flex-basis: calc((#{$i} / #{$columns}) * 100%);
          flex-grow: 0;
          max-width: calc((#{$i} / #{$columns}) * 100%);
        }

        &.order-#{$size}-#{$i} {
          order: $i;
        }
      }
    }
  }
  // stylelint-enable
}
</style>
