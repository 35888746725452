import { Module, createMapper } from 'vuex-smart-module';

import DeviceState from '@/store/modules/device/state';
import DeviceGetters from '@/store/modules/device/getters';
import DeviceMutations from '@/store/modules/device/mutations';
import DeviceActions from '@/store/modules/device/actions';

const device = new Module({
  state: DeviceState,
  getters: DeviceGetters,
  mutations: DeviceMutations,
  actions: DeviceActions,
});

export const deviceMapper = createMapper(device);

export default device;
