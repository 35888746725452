// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--grid-gutter:0.8rem}.App_application_Z_6QB{position:relative;display:flex;flex-direction:column;max-width:100%}.App_application_Z_6QB.App_desktop_SWuIQ{min-height:100vh}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"application": `App_application_Z_6QB`,
	"desktop": `App_desktop_SWuIQ`
};
export default ___CSS_LOADER_EXPORT___;
