<template>
  <portal :to="portalPlaceTo">
    <component
      :is="isMobileComputed ? 'SlideUpPanel' : 'BasePopup'"
      :isOpen="isOpenAlert"
      :maxWidth="maxWidth"
      @close="$emit('close')"
      @open="$emit('open')"
    >
      <div
        class="alert-wrap"
        :class="{ 'alert-wrap--desktop': !isMobileComputed }"
      >
        <slot v-if="$slots.default" />
        <div
          v-else
          class="alert-wrap__layout"
        >
          <h2 class="alert-wrap__layout-title">
            <slot name="title" />
          </h2>
          <p class="text-body-10 alert-wrap__layout-content">
            <slot name="content" />
          </p>
          <div class="alert-wrap__layout-controls">
            <template v-if="$slots.customAcceptButton">
              <slot name="customAcceptButton" />
            </template>
            <template v-else>
              <u-i-button
                w100
                type-style="danger"
                @click.stop="$emit('close')"
              >
                <slot name="accept">Подтвердить</slot>
              </u-i-button>
            </template>
          </div>
        </div>
      </div>
    </component>
  </portal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { UIButton } from '@avto-internal/avtocod-meta-ui';

type TData = {
  isMobileInternal: boolean;
};

export default Vue.extend({
  name: 'BaseUniversalAlert',
  components: {
    BasePopup: () => import('@/components/legacy/base/BasePopup.vue'),
    // @ts-ignore
    SlideUpPanel: () => import('@avto-internal/slider-up-panel').then((module) => module.SlideUpPanel),
    UIButton,
  },
  model: {
    prop: 'isOpen',
    event: 'close',
  },
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isMobile: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    maxWidth: {
      type: Number as PropType<number>,
      default: 480,
    },
  },
  data(): TData {
    return {
      isMobileInternal: true,
    };
  },
  mounted() {
    const matchMedia: MediaQueryList = window.matchMedia('(min-width: 768px)');
    matchMedia.onchange = this.matchMediaHandler;
    this.matchMediaHandler(matchMedia);
  },
  computed: {
    isOpenAlert(): boolean {
      return this.isOpen;
    },
    isMobileComputed(): boolean {
      return this.isMobile || this.isMobileInternal;
    },
    portalPlaceTo(): 'select-dialog' | 'modal' {
      return this.isMobileComputed ? 'select-dialog' : 'modal';
    },
  },
  methods: {
    matchMediaHandler(matchMedia: MediaQueryList | MediaQueryListEvent): void {
      this.isMobileInternal = !matchMedia.matches;
    },
  },
});
</script>

<style scoped lang="scss">
.alert-wrap {
  padding: gridCalc(2, 2, 3);

  &--desktop {
    padding: 0;
    text-align: center;
  }
}

.alert-wrap__layout-title {
  @include media-min(md) {
    margin-top: 0;
  }
}

.alert-wrap__layout-content {
  margin-top: gridCalc(2);
}

.alert-wrap__layout-controls {
  margin-top: gridCalc(4);
}
</style>
