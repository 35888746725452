import { Getters } from 'vuex-smart-module';

import BankCardsState from '@/store/modules/bank-cards/state';

export default class BankCardsGetters extends Getters<BankCardsState> {
  get mainCardUUID(): string | null {
    const mainCard = this.state.list.find(card => card.is_main);
    if (mainCard) {
      return mainCard.uuid;
    }
    return null;
  }
}
