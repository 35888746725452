/**
 * This file was automatically generated
 */

 export const empty_report_matrix_with_items = {
  identifiers: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    },
    manufacture: {
      vin: null
    }
  },
  identifiers_masked: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    }
  },
  tech_data: {
    manufacturer: {
      name: null
    },
    brand: {
      id: null,
      name: {
        original: null,
        rus: null,
        normalized: null
      },
      logotype: {
        uri: null
      }
    },
    model: {
      id: null,
      name: {
        original: null,
        normalized: null
      }
    },
    type: {
      name: null,
      type_id: null,
      code: null
    },
    body: {
      number: null,
      color: {
        name: null,
        type: null
      }
    },
    year: null,
    chassis: {
      number: null
    },
    engine: {
      fuel: {
        type: null,
        type_id: null
      },
      number: null,
      model: {
        name: null
      },
      volume: null,
      power: {
        hp: null,
        kw: null
      },
      standarts: {
        emission: {
          euro: null
        }
      }
    },
    weight: {
      netto: null,
      max: null
    },
    transmission: {
      type: null,
      type_id: null
    },
    drive: {
      type: null,
      type_id: null
    },
    wheel: {
      position: null,
      position_id: null,
      position_code: null
    },
    generations: [
      {
        id: null,
        name: null,
        is_restyling: null,
        years: {
          start: null,
          end: null
        },
        bodies: [
          {
            id: null,
            type: null,
            modifications: [
              {
                id: null,
                name: null,
                engine: {
                  fuel: {
                    type: null
                  },
                  power: {
                    hp: null
                  },
                  volume: null
                },
                transmission: {
                  type: null
                },
                drive: {
                  type: null
                }
              }
            ]
          }
        ]
      }
    ],
    date: {
      update: null
    }
  },
  additional_info: {
    vehicle: {
      category: {
        code: null,
        description: null
      },
      owner: {
        type: null,
        phone_number: null,
        enforcement_proceedings: {
          has_proceedings: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null,
          postal_code: null
        },
        taxi_history: {
          probable_taxi_driver: null,
          taxi_licenses: {
            items: [
              {
                vehicle: {
                  reg_num: null,
                  brand_name_original: null,
                  model_name_original: null
                },
                full_number: null,
                number: null,
                issued: null,
                region: {
                  code: null
                }
              }
            ],
            actuality: {
              date: null
            }
          },
          probable_use: {
            items: [
              {
                reg_num: null,
                date: null
              }
            ]
          }
        }
      },
      passport: {
        date: {
          receive: null
        },
        has_dublicate: null,
        number: null,
        org: {
          name: null
        }
      },
      sts: {
        date: {
          receive: null
        }
      },
      modifications: {
        was_modificated: null
      },
      notes: null,
      segment: {
        euro: {
          code: null,
          description: null
        }
      },
      exported: null,
      techreglament_category: {
        code: null,
        description: null
      }
    },
    catalog: {
      transdekra: {
        items: [
          {
            rsa_code: null,
            modification_id: null,
            relevance: null
          }
        ]
      },
      rsa: {
        items: [
          {
            code: {
              rsa: null,
              transdekra: null,
              vehicle: null
            },
            relevance: null,
            vehicle: {
              type: null,
              brand: {
                name: null
              },
              model: {
                name: null
              },
              modifications: {
                name: null
              },
              transmission: {
                type: null
              },
              drive: {
                type: null
              },
              engine: {
                type: null,
                power: {
                  hp: null
                },
                volume: null
              },
              doors: {
                count: null
              },
              seats: {
                count: null
              },
              weight: {
                max: null
              },
              category: {
                code: null
              },
              body: {
                type: null
              },
              manufactured: {
                year: {
                  start: null,
                  end: null
                }
              }
            }
          }
        ]
      },
      tecdoc: {
        items: [
          {
            manufacturer_id: null,
            model_id: null,
            id: null,
            name: null,
            description: null
          }
        ]
      },
      oats: {
        items: [
          {
            code: null,
            vehicle: {
              brand: {
                name: null
              },
              model: {
                name: null
              },
              modification: {
                name: null
              },
              engine: {
                model: {
                  name: null
                },
                type: null,
                power: {
                  hp: null,
                  kw: null
                },
                volume: null
              },
              category: {
                name: null
              },
              manufactured: {
                year: {
                  start: null,
                  end: null
                }
              }
            }
          }
        ]
      }
    },
    identifiers: {
      vin: {
        checksum_validated: null
      }
    },
    driver: {
      mileages: {
        average_annual: {
          value: null,
          calculation_options: {
            period: {
              start: null,
              end: null
            },
            vehicle: {
              count: null
            },
            mileages: {
              count: null
            }
          },
          is_calculated: false
        }
      }
    }
  },
  registration_actions: {
    items: [
      {
        date: {
          start: null,
          end: null
        },
        reg_num: null,
        identifiers: {
          pts: null,
          sts: null
        },
        owner: {
          type: null,
          org: {
            name: null,
            tin: null,
            kpp: null,
            ogrn: null
          },
          phone_number: null
        },
        code: null,
        type: null,
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null
        },
        attributes: null,
        usage_allowed: null,
        actuality: {
          date: null
        }
      }
    ],
    date: {
      update: null
    },
    count: 1,
    has_annulment: null
  },
  repairs: {
    history: {
      items: [
        {
          number: {
            calculation: null,
            claim: null
          },
          date: {
            calculation: null
          },
          mileage: null,
          cost: {
            total: null,
            labour: null,
            parts: null,
            paint: null
          },
          car: {
            manufacturer: null,
            model: null,
            sub_model: null
          },
          insurer: {
            name: null
          },
          assessor: {
            name: null
          },
          task_process: null,
          repairer: {
            type: null,
            description: null
          },
          details: {
            items: [
              {
                name: null,
                repair: {
                  method: null,
                  description: null
                }
              }
            ],
            count: 1
          }
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  gots_auctions: {
    items: [
      {
        vehicle: {
          mileage: null,
          damage: {
            value: null,
            descriptions: [
              {
                code: null,
                value: null
              }
            ]
          }
        },
        auction: {
          date: null,
          number: null,
          client: {
            name: null,
            postal_code: null,
            city: null,
            address_line: null,
            phone_number: null
          }
        }
      }
    ],
    date: {
      update: null
    }
  },
  carfax: {
    check: {
      exists: null,
      records: {
        count: null
      },
      vehicle: {
        model: {
          name: null
        }
      }
    }
  },
  calculate: {
    tax: {
      moscow: {
        yearly: {
          amount: null
        }
      },
      regions: {
        yearly: {
          amount: null
        }
      }
    },
    osago: {
      coefficients: {
        regional: {
          value: null
        }
      },
      price: {
        current: {
          region: {
            name: null
          },
          city: {
            name: null
          },
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        },
        moscow: {
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        },
        moscow_region: {
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        }
      }
    }
  },
  car_price: {
    items: [
      {
        date: {
          release: null
        },
        amount: null,
        model: {
          name: null
        },
        modification: {
          name: null
        },
        engine: {
          volume: null
        },
        year: null
      }
    ],
    count: 1
  },
  fines: {
    items: [
      {
        date: {
          event: null,
          accident: null
        },
        article: {
          code: null,
          description: null
        },
        uin: null,
        description: null,
        vendor: {
          name: null,
          type: null,
          code: null
        },
        amount: {
          value: null,
          total: null
        },
        discount: {
          percent: null,
          date: {
            end: null
          }
        },
        need_payment: null,
        is_paid: null,
        wire: {
          user: {
            name: null,
            tin: null,
            kpp: null
          },
          bank: {
            account: {
              number: null
            },
            name: null,
            bik: null
          },
          payment: {
            purpose: null
          },
          kbk: null,
          okato: null
        },
        location: {
          raw: null,
          coordinates: null
        },
        fssp: {
          date: null,
          is_proceed: null,
          enforcement_proceeding: {
            number: null,
            contact: null
          }
        },
        payer: {
          name: null,
          identifier: {
            type: null,
            number: null
          }
        },
        photos: [
          {
            type: null,
            uri: null
          }
        ]
      }
    ],
    has_fines: null,
    date: {
      update: null
    },
    count: 1
  },
  leasings: {
    items: [
      {
        date: {
          event: null
        },
        company: {
          name: null
        },
        lessor: {
          company: {
            name: null
          }
        },
        tin: null,
        contract_info: {
          number: null,
          date: {
            event: null,
            start: null,
            end: null
          }
        },
        lessors: [
          {
            company: {
              name: null,
              type: null,
              tin: null,
              ogrn: null
            }
          }
        ],
        lessees: [
          {
            company: {
              name: null,
              type: null,
              tin: null,
              ogrn: null
            }
          }
        ],
        messages: [
          {
            date: null,
            number: null,
            type: null,
            content: {
              description: null,
              is_subleasing: null,
              subjects: [
                {
                  identifier: null,
                  type: null,
                  description: null
                }
              ]
            }
          }
        ],
        in_leasing: null
      }
    ],
    used_in_leasing: null,
    date: {
      update: null
    },
    count: 1
  },
  customs: {
    history: {
      items: [
        {
          date: {
            event: null
          },
          document: {
            name: null,
            number: null
          },
          org: {
            name: null
          },
          color: {
            name: null
          },
          tax: {
            amount: null
          },
          price: {
            amount: null
          },
          mileage: null,
          ecology: {
            type: null
          },
          specification: {
            type: null,
            raw: null
          },
          owner: {
            type: null,
            value: null,
            tin: null
          },
          country: {
            from: {
              name: null,
              code: null
            },
            to: {
              name: null,
              code: null
            },
            original: {
              name: null,
              code: null
            }
          },
          utilization_tax: {
            status: null
          },
          sender: {
            name: null,
            tin: null
          },
          operation: {
            type: null
          },
          manufacturer: {
            name: null
          }
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  taxi: {
    history: {
      items: [
        {
          date: {
            start: null,
            end: null,
            actual: null,
            cancel: null
          },
          license: {
            number: null,
            issued: null,
            status: null
          },
          company: {
            name: null,
            address: null
          },
          ogrn: null,
          tin: null,
          number_plate: {
            is_yellow: null
          },
          vehicle: {
            brand: {
              name: null,
              normalized: null
            },
            model: {
              name: null,
              normalized: null
            },
            color: null,
            reg_num: null,
            year: null,
            sts: {
              number: null,
              date: {
                receive: null
              }
            }
          },
          region: {
            code: null
          },
          cancel: {
            reason: null,
            note: null
          },
          permit: {
            number: null
          },
          city: {
            name: null
          }
        }
      ],
      count: 1
    },
    used_in_taxi: null
  },
  taxi_analytics: {
    probable_use: {
      is_used: null
    }
  },
  pledges: {
    items: [
      {
        date: {
          event: null
        },
        number: null,
        pledgors: [
          {
            name: null,
            type: null,
            dob: null
          }
        ],
        pledgees: [
          {
            name: null,
            type: null,
            dob: null
          }
        ],
        pledge_managers: [
          {
            name: null,
            type: null,
            dob: null
          }
        ],
        type: null,
        in_pledge: null,
        data: null
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  pledges_nbki: {
    items: [
      {
        date: {
          end: null
        },
        note: null,
        vehicle: {
          color: {
            name: null
          },
          model: {
            name: null
          },
          year: null,
          identifiers: {
            vin: null,
            pts: null,
            body: null,
            chassis: null,
            engine: null
          }
        },
        in_pledge: null
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  insurance: {
    osago: {
      items: [
        {
          number: null,
          insurer: {
            name: null
          },
          date: {
            start: null,
            end: null,
            created: null,
            periods: [
              {
                start: null,
                end: null
              }
            ],
            cancel: null
          },
          actuality_date: null,
          restrictions: {
            type: null,
            drivers: null
          },
          policy: {
            series: null,
            number: null,
            is_active: null,
            expired: null,
            status: null
          },
          policy_replaced: [
            {
              series: null,
              number: null
            }
          ],
          policy_replacement: {
            series: null,
            number: null
          },
          contract: {
            is_active: null,
            using_type: {
              status: null,
              description: null
            },
            amount: {
              value: null,
              currency: null
            },
            kbm: null,
            is_follow_to_registration: null,
            has_trailer: null
          },
          vehicle: {
            model: {
              name: null,
              category: null
            },
            identifiers: {
              reg_num: null,
              vin: null,
              body: null
            },
            engine: {
              power: {
                hp: null
              }
            },
            weight: {
              max: null
            },
            seats: {
              count: null
            }
          },
          insurant: {
            type: null,
            dob: null,
            name: null,
            tin: null
          },
          owner: {
            type: null,
            dob: null,
            name: null,
            tin: null
          },
          geo: {
            country: null,
            region: null,
            city: null,
            street: null,
            house: null
          }
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  accidents: {
    statistics: {
      date: {
        update: null
      },
      items: [
        {
          number: null,
          type: null,
          car_in_accident: null,
          people_in_accident: null,
          causers: [
            {
              identifier: null
            }
          ],
          geo: {
            country: null,
            region: null,
            city: null,
            street: null,
            house: null
          },
          participants: {
            vehicles: [
              {
                vin: null,
                body: null,
                chassis: null,
                brand: {
                  name: null
                },
                model: {
                  name: null
                },
                year: null,
                category: null,
                color: null,
                driver: {
                  gender: null,
                  drive_experience: null,
                  damage: null,
                  used_seat_belt: null,
                  offense: {
                    main: null,
                    addition: []
                  }
                },
                passengers: [
                  {
                    gender: null,
                    damage: null,
                    used_seat_belt: null
                  }
                ]
              }
            ],
            pedestrians: [
              {
                gender: null,
                damage: null,
                offense: {
                  main: null,
                  addition: []
                }
              }
            ]
          },
          date: null
        }
      ],
      count: 1
    },
    history: {
      date: {
        update: null
      },
      items: [
        {
          number: null,
          accident: {
            date: null
          },
          participants: {
            total: null
          },
          actuality: {
            date: null
          },
          type: null,
          state: null,
          org: {
            name: null
          },
          damage: {
            raw: null,
            points: null,
            codes: null
          },
          vehicle: {
            brand: {
              name: null
            },
            model: {
              name: null
            },
            year: null
          },
          geo: {
            country: null,
            region: null,
            city: null,
            street: null,
            house: null
          },
          other_participants: [
            {
              number: null,
              accident: {
                date: null
              },
              type: null,
              state: null,
              damage: {
                points: null,
                codes: null
              },
              vehicle: {
                brand: {
                  name: null
                },
                model: {
                  name: null
                },
                year: null,
                identifiers: {
                  vin: null,
                  body: null,
                  chassis: null
                }
              },
              geo: {
                country: null,
                region: null,
                city: null,
                street: null,
                house: null
              }
            }
          ]
        }
      ],
      count: 1
    },
    insurance: {
      date: {
        update: null
      },
      items: [
        {
          date: {
            event: null
          },
          insurer: {
            name: null
          },
          policy: {
            series: null,
            number: null
          },
          actuality: {
            date: null
          }
        }
      ],
      count: 1
    },
    has_accidents: null
  },
  restrictions: {
    registration_actions: {
      date: {
        update: null
      },
      items: [
        {
          date: {
            added: null,
            start: null,
            end: null
          },
          vehicle: {
            year: null,
            model: {
              name: null
            }
          },
          initiator: {
            name: null,
            region: {
              name: null
            }
          },
          restrict: {
            type: null,
            reason: null,
            number: null,
            executive_procedure: null,
            contact: null
          },
          actuality: {
            date: null
          }
        }
      ],
      count: 1,
      has_restrictions: null
    },
    registration_actions_archive: {
      date: {
        update: null
      },
      items: [
        {
          date: {
            added: null,
            start: null,
            end: null
          },
          vehicle: {
            year: null,
            model: {
              name: null
            }
          },
          initiator: {
            name: null,
            region: {
              name: null
            }
          },
          restrict: {
            type: null,
            reason: null,
            number: null
          },
          actuality: {
            date: null
          }
        }
      ],
      count: 1,
      has_restrictions: null
    }
  },
  stealings: {
    is_wanted: null,
    items: [
      {
        actuality: {
          date: null
        },
        entry: null,
        vehicle: {
          brand: {
            name: null
          },
          vin: null,
          body: null,
          year: null
        },
        date: {
          stealing: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null
        }
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  ownership: {
    history: {
      items: [
        {
          date: {
            start: null,
            end: null
          },
          owner: {
            type: null,
            company: {
              name: null,
              tin: null,
              kpp: null,
              ogrn: null
            }
          },
          last_operation: {
            code: null,
            description: null
          }
        }
      ],
      usage: {
        duration_iso: null,
        days: null
      },
      date: {
        update: null
      },
      count: 1
    }
  },
  mileages: {
    items: [
      {
        date: {
          event: null
        },
        mileage: null,
        filled_by: {
          source: null
        },
        actuality: {
          date: null
        }
      }
    ],
    count: 1
  },
  diagnostic_cards: {
    items: [
      {
        date: {
          from: null,
          to: null
        },
        doc: {
          serial: null,
          number: null,
          type: null
        },
        inspection: {
          place: null
        },
        reg_num: null,
        actuality: {
          date: null
        }
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  utilizations: {
    items: [
      {
        date: {
          utilization: null
        },
        org: {
          name: null
        },
        country: {
          name: null
        }
      }
    ],
    was_utilized: null,
    date: {
      update: null
    },
    count: 1
  },
  images: {
    photos: {
      items: [
        {
          vehicle: {
            brand: {
              name: null
            },
            model: {
              name: null
            }
          },
          date: {
            issued: null
          },
          uri: null
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  ads: {
    history: {
      items: [
        {
          title: null,
          text: null,
          date: {
            publish: null
          },
          uri: null,
          seller: {
            name: null,
            phones: [
              null
            ]
          },
          geo: {
            country: null,
            region: null,
            city: null,
            street: null,
            house: null
          },
          price: {
            value: null
          },
          photos: {
            local: null,
            board: null,
            grz: null
          },
          is_sold: null,
          is_closed: null,
          vehicle: {
            brand: {
              name: null
            },
            model: {
              name: null
            },
            identifiers: {
              vin: null,
              chassis: null
            },
            year: null,
            condition: null,
            wheel: {
              position: null
            },
            transmission: {
              type: null
            },
            drive: {
              type: null
            },
            passport: {
              type: null
            },
            doors: {
              count: null
            },
            owners: {
              count: null
            },
            engine: {
              power: {
                hp: null
              },
              volume: null,
              fuel: {
                type: null
              }
            },
            body: {
              type: null,
              color: {
                name: null
              }
            },
            mileage: null,
            is_cleared: null
          }
        }
      ],
      date: {
        update: null
      },
      count: 1
    }
  },
  service_history: {
    items: [
      {
        dealer: {
          name: null,
          branch: {
            name: null
          }
        },
        date: {
          start: null,
          end: null
        },
        amount: {
          value: null,
          currency: null
        },
        type: null,
        service: {
          type: null,
          content: [],
          spare_parts: [],
          recommendations: [],
          is_planned: null
        },
        sale: {
          is_credit: null,
          is_new: null
        },
        equipment: {
          content: []
        },
        insurance: {
          type: null,
          date: {
            start: null,
            end: null
          }
        },
        client: {
          type: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null
        },
        vehicle: {
          brand: {
            name: null
          },
          model: {
            name: null
          },
          year: null,
          mileage: null,
          body: {
            doors: null,
            type: null,
            color: null
          },
          engine: {
            number: null,
            type: null,
            volume: null,
            power: {
              hp: null
            }
          },
          transmission: {
            type: null
          },
          wheel: {
            position: null
          },
          drive: {
            type: null
          },
          notes: [],
          equipment: {
            type: null
          },
          identifiers: {
            body: null,
            vin: null,
            chassis: null
          }
        }
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  market_prices: {
    ads: {
      items: [
        {
          amount: {
            max: null,
            min: null,
            optimal: null
          },
          currency: {
            type: null
          },
          mileage: null,
          related_ads: [
            {
              date: {
                publish: null
              },
              geo: {
                country: null,
                region: null,
                city: null,
                street: null,
                house: null
              },
              uri: null,
              price: {
                amount: null,
                currency: null
              },
              vehicle: {
                brand: {
                  name: null
                },
                model: {
                  name: null
                },
                year: null,
                condition: null,
                wheel: {
                  position: null,
                  position_id: null
                },
                transmission: {
                  type: null,
                  type_id: null
                },
                drive: {
                  type: null,
                  type_id: null
                },
                owners: {
                  count: null
                },
                engine: {
                  power: {
                    hp: null
                  },
                  volume: null,
                  fuel: {
                    type: null,
                    type_id: null
                  }
                },
                body: {
                  type: null,
                  type_id: null
                },
                mileage: null
              }
            }
          ],
          metadata: {
            functions: [
              {
                name: '',
                description: '',
                parts: [
                  {
                    type: '',
                    description: '',
                    coefficients: [],
                    bounds: {
                      left: null,
                      right: null
                    }
                  }
                ]
              }
            ]
          }
        }
      ],
      date: {
        update: null
      }
    },
    appraisal: {
      amount: {
        min: null,
        max: null,
        optimal: null
      },
      reasons: {
        used_in_taxi: null,
        accidents: {
          count: null
        },
        ownerships: {
          count: null
        }
      },
      date: {
        update: null
      }
    }
  },
  commercial_use: {
    items: [
      {
        service: {
          name: null,
          url: null
        },
        company: {
          name: null,
          tin: null,
          type: null
        }
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  recall_campaigns: {
    items: [
      {
        date: null,
        company: {
          name: null
        },
        description: {
          reason: null,
          recommendation: null
        },
        vehicle: {
          brand: null,
          model: null
        },
        news: {
          url: null
        }
      }
    ],
    date: {
      update: null
    },
    count: 1
  },
  arbitration: {
    count: 1,
    items: [
      {
        date: {
          registration: null
        },
        court: {
          name: null
        },
        number: null,
        type: null,
        description: null,
        url: null
      }
    ],
    date: {
      update: null
    }
  },
  driver_licenses: {
    items: [
      {
        actuality: {
          date: null
        },
        number: null,
        status: null,
        date: {
          start: null,
          end: null
        },
        categories: [],
        experience: {
          year_start: null
        },
        operation: {
          description: null,
          code: null
        },
        deprivations: [
          {
            date: {
              start: null,
              end: null
            },
            state: {
              code: null,
              description: null
            },
            offense: {
              place: null,
              decision: null
            },
            registration_place: null
          }
        ]
      }
    ],
    has_deprivations: null,
    date: {
      update: null
    },
    count: 1
  },
  executive_procedures: {
    items: [
      {
        number: null,
        description: null,
        debt: {
          amount: {
            value: null
          }
        },
        bailiff: {
          name: null,
          phone: null,
          department: {
            name: null,
            address: null
          }
        },
        date: {
          create: null
        }
      }
    ],
    count: 1
  }
}