import { requireAuth } from '@/router/utils';

const Search = () => import('@/pages/mobile/search/PageSearch.vue');

export default [
  {
    path: '/search',
    name: 'search',
    component: Search,
    beforeEnter: requireAuth,
    meta: {
      title: 'Поиск по проверкам',
    },
  },
];
