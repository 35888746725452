<template>
  <FormReset :status="status"
             :isMobile="isMobile"
             @reset-form:submit="onSubmitForm"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AuthApi from '@/services/httpClient/api/profi/auth.api';
import { Email } from '@/rpc-types/profi';
import { Status } from '@/types/statuses';
// @ts-ignore
import eventBus from '@/utils/eventBus';
import { generateID } from '@/utils/helpers';
import FormReset from '@/components/particles/form-reset/FormReset.vue';
import { routerCatchErrorHandler } from '@/router/utils';

const authApi = new AuthApi();

type TResetSubmit = {
  email: Email;
}

export default defineComponent({
  name: 'ResetPassword',
  props: {
    isMobile: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      status: '' as Status,
      alert: {
        alertTitle: '',
        alertContent: '',
      },
    };
  },
  components: {
    FormReset,
  },
  computed: {
    isSuccess() {
      return this.status === 'success';
    },
  },
  methods: {
    async onSubmitForm(submitEvent: TResetSubmit): Promise<void> {
      this.status = 'loading';
      try {
        const { data: { error } } = await authApi.reset(submitEvent.email);
        if (error) {
          this.status = 'error';
          const isTimeLimitError = error.code === 29001;
          if (isTimeLimitError) {
            this.showAlert('Вы уже запросили восстановление пароля. Пожалуйста, проверьте почту или попробуйте снова позже.');
          } else {
            this.showAlert('Произошла ошибка. Попробуйте снова или обратитесь в техподдержку.');
          }
        } else {
          this.status = 'success';
          this.$router.push({ name: 'auth', query: { fromUrl: this.$route.name } }).catch(routerCatchErrorHandler);
        }
      } catch (error) {
        this.status = 'error';
        this.showAlert('Произошла ошибка. Попробуйте снова или обратитесь в техподдержку.');
      }
    },
    showAlert(message: string): void {
      eventBus.emit('pushNotification', { id: generateID(), msg: message });
    },
  },
});

</script>
