export type TToken = '#' | 'X' | 'S' | 'A' | 'a' | '!';

export interface IToken {
  pattern?: RegExp,
  transform?: (v: string) => string,
  escape?: boolean
}

const tokens: { [key in TToken | string]: IToken } = {
  '#': { pattern: /\d/ },
  X: { pattern: /[0-9a-zA-Z]/ },
  S: { pattern: /[a-zA-Z]/ },
  A: {
    pattern: /[a-zA-Z]/,
    transform: (v: string) => v.toLocaleUpperCase(),
  },
  a: {
    pattern: /[a-zA-Z]/,
    transform: (v: string) => v.toLocaleLowerCase(),
  },
  '!': { escape: true },
};

export default tokens;
