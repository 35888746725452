import {
  Id,
  IRPCPaymentsHistory,
  IRPCPaymentsHistoryCount,
  IRPCPaymentsPayAccess,
  IRPCPaymentsPayCarOwnerReport,
  IRPCPaymentsPayProduct,
  IRPCPaymentsPaySubscription,
  IRPCSubscriptionsRenew,
  Pagination,
  Promocode,
  ReferralLinkId,
  Sort,
  UUID,
} from '@/rpc-types/profi';
import HttpClient from '@/services/httpClient/index';
import { IPaymentsPayCarOwnerReportProps } from '@/store/modules/payments/payments.types';

export default class PaymentsApi extends HttpClient {
  fetchPaymentsHistoryList = (pagination?: Pagination, sort?: Sort) =>
    this.call<IRPCPaymentsHistory>('payments.history', { pagination, sort }, 'profi');

  fetchPaymentsHistoryListCount = () => this.call<IRPCPaymentsHistoryCount>('payments.history.count', {}, 'profi');

  payAccess = ({
    product_uuid,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    product_uuid: UUID;
    bank_card_uuid: UUID;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) =>
    this.call<IRPCPaymentsPayAccess>(
      'payments.pay.access',
      { product_uuid, bank_card_uuid, promocode, referral_link_id },
      'profi'
    );

  payProduct = ({
    price_uuid,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    price_uuid: UUID;
    bank_card_uuid: UUID;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) =>
    this.call<IRPCPaymentsPayProduct>(
      'payments.pay.product',
      { price_uuid, bank_card_uuid, promocode, referral_link_id },
      'profi'
    );

  paySubscription = ({
    product_uuid,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    product_uuid: UUID;
    bank_card_uuid: UUID;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) =>
    this.call<IRPCPaymentsPaySubscription>(
      'payments.pay.subscription',
      { product_uuid, bank_card_uuid, promocode, referral_link_id },
      'profi'
    );

  payOwnerReport = ({
    product_uuid,
    bank_card_uuid,
    delivery_email,
    first_name,
    patronymic,
    last_name,
    birth_date,
    passport,
    referral_link_id,
  }: IPaymentsPayCarOwnerReportProps) =>
    this.call<IRPCPaymentsPayCarOwnerReport>(
      'payments.pay.carOwnerReport',
      {
        product_uuid,
        bank_card_uuid,
        delivery_email,
        first_name,
        patronymic,
        last_name,
        birth_date,
        passport,
        referral_link_id,
      },
      'profi'
    );

  renewSubscription = ({ subscription_id, bank_card_uuid }: { subscription_id: Id; bank_card_uuid: UUID }) =>
    this.call<IRPCSubscriptionsRenew>('subscriptions.renew', { subscription_id, bank_card_uuid }, 'profi');
}
