import { Id, IRPCSubscriptionsUnsubscribe, Reasons, UnsubscribeComment } from '@/rpc-types/profi';
import HttpClient from '@/services/httpClient/index';

export default class SubscriptionsApi extends HttpClient {
  unsubscribe = ({
    subscription_id,
    comment,
    reasons,
  }: {
    subscription_id: Id;
    comment?: UnsubscribeComment;
    reasons?: Reasons;
  }) =>
    // TODO теперь ждет product_uuid
    this.call<IRPCSubscriptionsUnsubscribe>(
      'subscriptions.unsubscribe',
      { subscription_id, comment, reasons },
      'profi'
    );
}
