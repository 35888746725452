import { Mutations } from 'vuex-smart-module';

import BatchState from '@/store/modules/batch/state';

export default class BatchMutations extends Mutations<BatchState> {
  reset(): void {
    this.state.authDataFetched = false;
  }

  onAuthDataFetched(): void {
    this.state.authDataFetched = true;
  }

  onNotAuthDataFetched(): void {
    this.state.notAuthDataFetched = true;
  }
}
