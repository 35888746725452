import { Getters } from 'vuex-smart-module';

import ProfileState from '@/store/modules/profile/state';

export default class ProfileGetters extends Getters<ProfileState> {
  get isProfileLoaded(): boolean {
    return Boolean(this.state.profile.uuid);
  }

  get userPhone(): string {
    return this.state.profile.phone || this.state.notVerifiedPhone;
  }

  get userNotVerifiedPhone(): string {
    return this.state.notVerifiedPhone;
  }
}
