<script lang="ts">
import Vue, { VNode } from 'vue';

/* eslint-disable no-param-reassign */
export default Vue.extend({
  name: 'BaseTransitionExpand',
  functional: true,
  render(h, context): VNode {
    const data = {
      props: {
        name: 'expand',
      },
      on: {
        enter(element: HTMLElement) {
          const { width } = getComputedStyle(element);

          element.style.width = width;
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';

          const { height } = getComputedStyle(element);

          element.style.width = '';
          element.style.position = '';
          element.style.visibility = '';
          element.style.height = '0px';

          // Force repaint to make sure the animation is triggered correctly
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height;

          requestAnimationFrame(() => {
            element.style.height = height;
          });
        },
        afterEnter(element: HTMLElement) {
          element.style.height = 'auto';
        },
        leave(element: HTMLElement) {
          const { height } = getComputedStyle(element);

          element.style.height = height;

          // Force repaint to make sure the animation is triggered correctly
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height;

          requestAnimationFrame(() => {
            element.style.height = '0px';
          });
        },
      },
    };

    return h('transition', data, context.children);
  },
});
</script>

<style lang="scss" scoped>
// stylelint-disable-next-line selector-max-universal
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
