import { getGuestBuyHandler, requireUnauth } from '@/router/utils';
import authStorage from '@/services/authTokenStorage';
const PageConfirmPhone = () => import('@/pages/mobile/auth/PageConfirmPhone.vue');

export default [
  {
    path: '/guest-buy',
    name: 'guest-buy',
    component: () => import('@/pages/common/guest-buy/PageGuestBuy/PageGuestBuy.vue'),
    beforeEnter: getGuestBuyHandler(authStorage),
  },
  {
    path: '/guest-buy/reg',
    name: 'guest-buy-reg',
    component: () => import('@/pages/common/guest-buy/PageGuestBuyReg/PageGuestBuyReg.vue'),
    beforeEnter: requireUnauth,
  },
  {
    path: '/guest-buy/auth',
    name: 'guest-buy-auth',
    component: () => import('@/pages/common/guest-buy/PageGuestBuyAuth/PageGuestBuyAuth.vue'),
    beforeEnter: requireUnauth,
  },
  {
    path: '/guest-buy/payment',
    name: 'guest-buy-payment',
    component: () => import('@/pages/common/subs/PageSubscriptionPayments/PageSubscriptionPayments.vue'),
  },
  {
    path: '/guest-buy/confirm-phone',
    name: 'guest-buy-reg-confirm-phone',
    component: PageConfirmPhone,
    meta: {
      title: 'Подтверждение номера телефона',
      layout: 'div',
    },
  },
];
