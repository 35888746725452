import unit, { Format } from '@/filters/unit';

import digit from '@/filters/digit';

export default function makeRange(v: number | string, format: Format, range = 3): string {
  const value = Number(v);
  const [from, to] = [value * ((100 - range) / 100), value * ((100 + range) / 100)]
    .map(el => unit(digit(Math.floor(el)), format));
  return `от ${from} до ${to}`;
}
