const SCRIPT_URL = 'https://pp.spectrumdata.ru/scripts/ju5xdhg1';
const SCRIPT_ID = 'pap_x2s6df8d';
const LOGGER_HEADER = '[PP_SPECTRUM_SCRIPT_DEV_LOG]:';

// eslint-disable-next-line import/prefer-default-export
export class PPSpectrumClass {
  private static isProduction: boolean;

  static init(isProduction: boolean = true): void {
    this.isProduction = isProduction;

    if (this.isProduction) {
      const script = document.createElement('script');
      script.src = SCRIPT_URL;
      script.id = SCRIPT_ID;

      script.addEventListener('load', () => {
        // @ts-ignore
        PostAffTracker.setAccountId('default1');
        try {
          // @ts-ignore
          PostAffTracker.track();
        } catch (e) {} // eslint-disable-line no-empty
      });

      document.body.appendChild(script);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${LOGGER_HEADER} script was not loaded because it works only \`production\` environment`);
    }
  }

  static createOrder(orderID: string, cost: number, productID: string): void {
    try {
      // @ts-ignore
      const sale = PostAffTracker.createSale();
      sale.setTotalCost(cost);
      sale.setOrderID(orderID);
      sale.setProductID(productID);

      // @ts-ignore
      PostAffTracker.register();
    } catch (e) {} // eslint-disable-line no-empty

    if (!this.isProduction) {
      // eslint-disable-next-line no-console
      console.info(`${LOGGER_HEADER} createOrder`);
    }
  }
}
