/**
 * This file was automatically generated
 */

 export const empty_short_report_matrix = {
  additional_info: {
    vehicle: {
      category: {
        code: null,
        description: null
      },
      owner: {
        type: null,
        phone_number: null,
        enforcement_proceedings: {
          has_proceedings: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null,
          postal_code: null
        },
        taxi_history: {
          probable_taxi_driver: null,
          taxi_licenses: {
            items: [],
            actuality: {
              date: null
            }
          },
          probable_use: {
            items: []
          }
        }
      },
      passport: {
        date: {
          receive: null
        },
        has_dublicate: null,
        number: null,
        org: {
          name: null
        }
      },
      sts: {
        date: {
          receive: null
        }
      },
      modifications: {
        was_modificated: null
      },
      notes: null,
      segment: {
        euro: {
          code: null,
          description: null
        }
      },
      exported: null,
      techreglament_category: {
        code: null,
        description: null
      }
    },
    catalog: {
      transdekra: {
        items: []
      },
      rsa: {
        items: []
      },
      tecdoc: {
        items: []
      },
      oats: {
        items: []
      }
    },
    identifiers: {
      vin: {
        checksum_validated: null
      }
    },
    driver: {
      mileages: {
        average_annual: {
          value: null,
          calculation_options: {
            period: {
              start: null,
              end: null
            },
            vehicle: {
              count: null
            },
            mileages: {
              count: null
            }
          },
          is_calculated: false
        }
      }
    }
  },
  identifiers: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    },
    manufacture: {
      vin: null
    }
  },
  images: {
    photos: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  tech_data: {
    manufacturer: {
      name: null
    },
    brand: {
      id: null,
      name: {
        original: null,
        rus: null,
        normalized: null
      },
      logotype: {
        uri: null
      }
    },
    model: {
      id: null,
      name: {
        original: null,
        normalized: null
      }
    },
    type: {
      name: null,
      type_id: null,
      code: null
    },
    body: {
      number: null,
      color: {
        name: null,
        type: null
      }
    },
    year: null,
    chassis: {
      number: null
    },
    engine: {
      fuel: {
        type: null,
        type_id: null
      },
      number: null,
      model: {
        name: null
      },
      volume: null,
      power: {
        hp: null,
        kw: null
      },
      standarts: {
        emission: {
          euro: null
        }
      }
    },
    weight: {
      netto: null,
      max: null
    },
    transmission: {
      type: null,
      type_id: null
    },
    drive: {
      type: null,
      type_id: null
    },
    wheel: {
      position: null,
      position_id: null,
      position_code: null
    },
    generations: [],
    date: {
      update: null
    }
  }
}