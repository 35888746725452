import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import BatchState from '@/store/modules/batch/state';
import BatchGetters from '@/store/modules/batch/getters';
import BatchMutations from '@/store/modules/batch/mutations';

import reports from '@/store/modules/reports';
import products from '@/store/modules/products';
import profile from '@/store/modules/profile';
import clients from '@/store/modules/clients';
import bank_cards from '@/store/modules/bank-cards';
import payments from '@/store/modules/payments';

import BatchApi from '@/services/httpClient/api/profi/batch.api';
import ReportApi from '@/services/httpClient/api/profi/report.api';

import authStorage from '@/services/authTokenStorage';
import { setUserIdCybertonica } from '@/services/cybertonica';

export default class BatchActions extends Actions<
  BatchState,
  BatchGetters,
  BatchMutations,
  BatchActions
> {
  reports!: Context<typeof reports>;
  products!: Context<typeof products>;
  profile!: Context<typeof profile>;
  clients!: Context<typeof clients>;
  bank_cards!: Context<typeof bank_cards>;
  payments!: Context<typeof payments>
  api!: BatchApi;
  reportApi!: ReportApi;

  $init(store: Store<any>): void {
    this.reports = reports.context(store);
    this.profile = profile.context(store);
    this.products = products.context(store);
    this.clients = clients.context(store);
    this.bank_cards = bank_cards.context(store);
    this.payments = payments.context(store);
    this.api = new BatchApi();
    this.reportApi = new ReportApi();
  }

  async fetchFullReport(uuid: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const { data: reportData } = (await this.reportApi.fetchReport(uuid));
      if (reportData.error) {
        reject(reportData.error);
        // eslint-disable-next-line no-console
        console.warn(reportData.error);
      } else {
        this.reports.mutations.setReportFull(reportData.result);
      }
      resolve();


      if (authStorage.isLoggedIn) {
        // Should be outside of batch due to the fact that it can take a long time
        const { data: companiesData } = await this.reportApi.fetchReportCompanies(uuid);

        if (companiesData.error) {
          // eslint-disable-next-line no-console
          console.warn(companiesData.error);
        } else {
          this.reports.mutations.setReportFullCompanies({ uuid, companies: companiesData.result.companies });
        }
      }
    });
  }

  async fetchFullReportWithUserContent(uuid: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const { data: [report, comments] } = await this.api.fetchReportWithUserContent(uuid);
      if (report.error) {
        // eslint-disable-next-line no-console
        console.warn(report.error);
      } else {
        this.reports.mutations.setReportFull(report.result);
      }

      if (comments.error) {
        // eslint-disable-next-line no-console
        console.warn(comments.error);
      } else {
        this.reports.mutations.setReportContentField(
          {
            path: 'comments',
            items: comments.result.comments,
            uuid,
          },
        );
      }

      if ([report, comments].some(item => item.error)) {
        reject([report, comments].map(item => item.error));
      } else {
        resolve();
      }

      // Should be outside of batch due to the fact that it can take a long time
      const { data: companiesData } = await this.reportApi.fetchReportCompanies(uuid);

      if (companiesData.error) {
        // eslint-disable-next-line no-console
        console.warn(companiesData.error);
      } else {
        this.reports.mutations.setReportFullCompanies({ uuid, companies: companiesData.result.companies });
      }
    });
  }

  async fetchDataForAuthUser(): Promise<any> {
    const resultAuthUser = await this.api.fetchDataForAuthUser();
    const { data: [profileResponse, currentAccess, lastAccess, bankCardsList] } = resultAuthUser;

    if (profileResponse.error) {
      // eslint-disable-next-line no-console
      console.warn(profileResponse.error);
    } else {
      this.profile.mutations.setProfile(profileResponse.result);
      setUserIdCybertonica(profileResponse.result.uuid);
    }

    if (currentAccess.error) {
      // eslint-disable-next-line no-console
      console.warn(currentAccess.error);
    } else {
      this.clients.mutations.setCurrentAccess(currentAccess.result);
    }

    if (lastAccess.error) {
      // eslint-disable-next-line no-console
      console.warn(lastAccess.error);
    } else {
      this.clients.mutations.setLastAccess(lastAccess.result);
    }

    if (bankCardsList.error) {
      // eslint-disable-next-line no-console
      console.warn(bankCardsList.error);
    } else {
      bankCardsList.result.bank_cards.forEach((bank_card) => {
        this.bank_cards.mutations.setBankCardsListItem({ bank_card });
      });
    }

    this.mutations.onAuthDataFetched();

    return {
      profileResponse, bankCardsList,
    };
  }

  async fetchDataForNotAuthUser(): Promise<void> {
    const { data: [productList] } = await this.api.fetchDataForNotAuthUser();

    if (productList.error) {
      // eslint-disable-next-line no-console
      console.warn(productList.error);
    } else {
      const { products } = productList.result || {};

      this.products.mutations.setSubscriptionsList(products.subscriptions);
      this.products.mutations.setAccessList(products.access);
      this.products.mutations.setReportPacksList(products.report_packs);
      this.products.mutations.setCarOwnerReportTypeList(products.car_owners);
    }

    this.mutations.onNotAuthDataFetched();
  }

  async updateMainCard(cardUUID: string): Promise<void> {
    const { data: [update, subscription] } = await this.api.updateMainCard(cardUUID);

    if (update.error) {
      // eslint-disable-next-line no-console
      console.warn(update.error);
    } else {
      const prevMainCard = this.bank_cards.state.list.find(card => card.is_main);

      if (prevMainCard) {
        this.bank_cards.mutations.setBankCardMain({ uuid: prevMainCard.uuid, value: false });
      }
      this.bank_cards.mutations.setBankCardMain({ uuid: cardUUID, value: true });
    }

    if (subscription.error) {
      // eslint-disable-next-line no-console
      console.warn(subscription.error);
    } else {
      this.clients.mutations.setCurrentAccess(subscription.result);
    }
  }
}
