// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--grid-gutter:0.8rem}.AppNotifications_container_n9Ups{position:fixed;bottom:14.4rem;left:3.2rem;z-index:3;margin:-1rem 0}.AppNotifications_container_n9Ups .AppNotifications_contentWrapper_G2zwz{display:flex;padding:1rem 0}.AppNotifications_containerMobile_adf5y{top:8.8rem;bottom:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AppNotifications_container_n9Ups`,
	"contentWrapper": `AppNotifications_contentWrapper_G2zwz`,
	"containerMobile": `AppNotifications_containerMobile_adf5y`
};
export default ___CSS_LOADER_EXPORT___;
