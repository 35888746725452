// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--grid-gutter:0.8rem}.AppNotificationItem_content_eO3X7{display:flex;flex-wrap:nowrap;align-items:center;max-width:700px;padding:.8rem 0 .8rem 1.6rem;color:#43485b;background-color:#fff;box-shadow:0 0 1rem rgba(12,69,111,.15)}.AppNotificationItem_contentMobile_gzH0t{width:calc(100% - 32px);color:#fff;white-space:normal;background-color:#43485b;border-radius:.5rem;box-shadow:0 0 15px rgba(26,50,135,.2)}.AppNotificationItem_buttonAction_wTp8t,.AppNotificationItem_msg_wTFvv{font-size:1.6rem}.AppNotificationItem_buttonAction_wTp8t{margin-left:1.6rem;color:#067eff;background:transparent;border:0;outline:0}.AppNotificationItem_buttonAction_wTp8t:hover{color:#0065d2;cursor:pointer}.AppNotificationItem_buttonClose_ded1H{margin:2.4rem}.AppNotificationItem_buttonClose_ded1H,.AppNotificationItem_icon_KUz7Q{width:1.6rem;height:1.6rem}.AppNotificationItem_icon_KUz7Q{fill:#d8d8d8}.AppNotificationItem_icon_KUz7Q:hover{cursor:pointer;fill:#067eff}.AppNotificationItem_timer_Xc4l6{font-size:1.4rem;color:#067eff}.AppNotificationItem_loader_VgHmV{display:flex;align-items:center;justify-content:center;width:2.7rem;height:2.7rem;margin-left:2.4rem}@keyframes AppNotificationItem_progress-circular-rotate_Fsanh{to{transform:rotate(1turn)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockedColor": `#067eff`,
	"content": `AppNotificationItem_content_eO3X7`,
	"contentMobile": `AppNotificationItem_contentMobile_gzH0t`,
	"buttonAction": `AppNotificationItem_buttonAction_wTp8t`,
	"msg": `AppNotificationItem_msg_wTFvv`,
	"buttonClose": `AppNotificationItem_buttonClose_ded1H`,
	"icon": `AppNotificationItem_icon_KUz7Q`,
	"timer": `AppNotificationItem_timer_Xc4l6`,
	"loader": `AppNotificationItem_loader_VgHmV`,
	"progress-circular-rotate": `AppNotificationItem_progress-circular-rotate_Fsanh`
};
export default ___CSS_LOADER_EXPORT___;
