import { Getters } from 'vuex-smart-module';

import PPSpectrumdataState from '@/store/modules/ppSpectrumdata/state';

export default class PPSpectrumdataGetters extends Getters<PPSpectrumdataState> {
  get isPPSpectrumdataInfoLoaded(): boolean {
    return this.state.statusLoading === 'error' || this.state.statusLoading === 'success';
  }

  get isRegistered() {
    return this.state.statusInPP === 'ok';
  }

  get refId() {
    return this.state.clientPP.ref_id;
  }

  get didNotReceiveData(): boolean {
    return this.state.statusInPP === null;
  }
}
