import { requireAuth } from '@/router/utils';

const PageReferralProfi = () => import('@/pages/mobile/referral/PageReferralProfi.vue');
const PageReferralOsago = () => import('@/pages/mobile/referral/PageReferralOsago.vue');
const PageOsagoRemuneration = () => import('@/pages/mobile/referral/osago/PageOsagoRemuneration.vue');
const PageOsagoCalculate = () => import('@/pages/mobile/referral/osago/PageOsagoCalculate.vue');
const PageOsagoHistory = () => import('@/pages/mobile/referral/osago/PageOsagoHistory.vue');
const PageReferalAutocredit = () => import('@/pages/mobile/referral/PageReferralAutocredit.vue');

export default [
  {
    path: '/referral/profi',
    name: 'page-referral-profi',
    component: PageReferralProfi,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'Заработай на Профи',
    },
  },
  {
    path: '/referral/osago',
    name: 'page-referral-osago',
    component: PageReferralOsago,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'Заработай на ОСАГО',
    },
  },
  {
    path: '/referral/osago/remuneration',
    name: 'referral-osago-remuneration',
    component: PageOsagoRemuneration,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'Вознаграждение',
    },
  },
  {
    path: '/referral/osago/calculate',
    name: 'referral-osago-calculate',
    component: PageOsagoCalculate,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'Рассчитать осаго',
    },
  },
  {
    path: '/referral/osago/history',
    name: 'referral-osago-history',
    component: PageOsagoHistory,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'История продаж',
    },
  },
  {
    path: '/referral/autocredit',
    name: 'page-referral-autocredit',
    component: PageReferalAutocredit,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      title: 'Кредит и кешбэк',
    },
  },
];
