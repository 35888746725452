import { Getters } from 'vuex-smart-module';

import ClientsState from '@/store/modules/clients/state';
import { instanceOfInfoSubscription } from '@/types/guards';

export default class ClientsGetters extends Getters<ClientsState> {
  get haveCurrentAccess(): boolean {
    return this.state.currentAccess !== null;
  }

  get haveLastAccess(): boolean {
    return this.state.lastAccess !== null;
  }

  get isCurrentAccessRecurrent(): boolean {
    if (this.state.currentAccess && instanceOfInfoSubscription(this.state.currentAccess.info)) {
      return Boolean(this.state.currentAccess?.info.is_recurrent);
    }
    return false;
  }

  get isAccessSometimeNotExisted(): boolean {
    return !this.getters.haveCurrentAccess && !this.getters.haveLastAccess;
  }

  get isAccessExistButWasEnded(): boolean {
    return !this.getters.haveCurrentAccess && this.getters.haveLastAccess;
  }

  get currentAccessIsPromo(): boolean {
    return Boolean(this.state.currentAccess?.product.options?.is_one_time_purchase);
  }
}
