import _throttle from 'lodash/throttle';
import { DefineComponent } from 'vue';

import { Client, UUID } from '@/rpc-types/profi';
import { TExternalBreadCrumb } from '@/types/components/bread-crumbs';
import eventBus from '@/utils/eventBus';

export const IOS_USER_AGENT = 'iOSProfiApp';
export const ANDROID_USER_AGENT = 'AndroidProfiApp';

/**
 * @description Случайное число в интервале
 */
export const randomNumber = (max = 100, min = 0): number => Math.round(Math.random() * (max - min) + min);

/**
 * @description Случайный элемент из массива
 */
export const randomElFromArray = <T>(array: readonly T[]): T => array[randomNumber(array.length - 1, 0)];

/**
 * @description Генерация случайного id
 */
export const generateID = (): string => `_${Math.random().toString(36).substr(2, 12)}`;

/**
 * @description Проверка, является ли значение объектом
 */
export const isObject = (val: unknown): boolean =>
  typeof val === 'object' && val instanceof Object && !Array.isArray(val);

/**
 * @description
 * throttle без декоратора
 */
export const throttle = (fn: () => void, delay = 500, leading?: boolean) => _throttle(fn, delay, { leading });

/**
 * @description Проверка на то, является ли клиент мобильным устройством
 */
export const isMobile = (): boolean => {
  const phones: string[] = ['Android', 'webOS', 'iPhone', 'iPod', 'BlackBerry', 'Windows Phone'];

  const isPhone = (phone: string): boolean => Boolean(navigator.userAgent.match(new RegExp(phone, 'i')));

  return phones.some(isPhone);
};

/**
 * @description Обработка прокрутки элемнта при достижении нижней границы
 */
export const handleContentScroll = (cb: () => void, el?: HTMLElement, offset = 0): void => {
  let condition;
  if (el) {
    condition = el.scrollTop ? el.offsetHeight + el.scrollTop + offset >= el.scrollHeight : false;
  } else {
    // Some mobiles dont have enough space for scroll 0,5 px
    const bugFix = 1;
    condition = window.scrollY
      ? window.innerHeight + window.scrollY + bugFix + offset >= document.body.scrollHeight
      : false;
  }

  if (condition) {
    cb();
  }
};

/**
 * @description Округление числа
 */
export const roundNumber = (direction: 'floor' | 'ceil' | 'round', number: number, rounding: number): number =>
  Math[direction](number / rounding) * rounding;

/**
 * @description Проверка, является ли клиент iOS/Android/Мобильным
 */
export const isIOSApp = (): boolean => navigator.userAgent.includes(IOS_USER_AGENT);

export const isAndroidApp = (): boolean => navigator.userAgent.includes(ANDROID_USER_AGENT);

export const isMobileApp = (): boolean => isIOSApp() || isAndroidApp();

export type TdeviceType = 'IOS' | 'Android' | 'web';

export const getDeviceType = (): TdeviceType => {
  if (isIOSApp()) {
    return 'IOS';
  }

  if (isAndroidApp()) {
    return 'Android';
  }

  return 'web';
};

/**
 * @description Возвращает новый массив, отфильтрованный по полю report_uuid
 */
export const filterItemsByReportUuid = <T extends { report_uuid: UUID }>(uuid: UUID, items: T[]): T[] =>
  items.filter((item) => item.report_uuid === uuid);

export async function genDeviceId(userUUID: Client['uuid'], deviceKey: string): Promise<string> {
  const deviceIdEntropy = `${userUUID}${Date.now().toString()}${Math.random()}`;
  const msgUint8 = new TextEncoder().encode(deviceIdEntropy);
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
    .substring(0, 32);
  return `${hashHex}_${deviceKey}`;
}

export interface ILoaderComponentFactory {
  // Компонент, который будет загружен
  component: Promise<DefineComponent>;
  // Компонент-заглушка, показывается во время загрузки основного компонента
  loading?: DefineComponent;
  // Компонент-ошибка, который будет отображаться, при ошибке загрузки основного компонента
  error?: DefineComponent;
  // Пуаза перед показыванием компонента-лоадер. По умолчанию: 0ms.
  delay?: number;
  // Через какое время будет показан компонент-ошибка, если основной компонент загружается слишком долго
  timeout?: number;
}
export interface ILoaderComponentFactoryParam extends ILoaderComponentFactory {
  // Минимальное время показа компонента-лоадер
  timeShowLoading?: number;
}
export function asyncLoaderComponentFactory(setObjFun: () => ILoaderComponentFactoryParam): ILoaderComponentFactory {
  const setObj = setObjFun();
  return {
    ...setObj,
    component: new Promise((resolve) => {
      const { component, timeShowLoading } = setObj;
      const showLoading = new Promise((resolveShowLoading) => {
        setTimeout(() => {
          resolveShowLoading(null);
        }, timeShowLoading || 200);
      });

      Promise.all([component, showLoading]).then(([component]) => {
        resolve(component);
      });
    }),
    delay: setObj.delay || 0,
  };
}

/**
 * @description Сброс хлебных крошек
 */
export const refreshHeaderBreadcrumbs = (): void => {
  const breadCrumbs: TExternalBreadCrumb[] = [
    {
      path: '',
      meta: {
        title: '',
      },
    },
  ];
  eventBus.emit('updateExternalBreadCrumbs', breadCrumbs);
};
