import {
  UUID, Text, Id,
  Pagination, Sort,
  IRPCCommentsGetByReportUuid, IRPCCommentsCreate,
  IRPCCommentsUpdate, IRPCCommentsDelete,
} from '@/rpc-types/profi';

import HttpClient from '@/services/httpClient/index';

export default class CommentsApi extends HttpClient {
  createComment = (report_uuid: UUID, text: Text) => (
    this.call<IRPCCommentsCreate>('comments.create', { report_uuid, text }, 'profi')
  )

  updateComment = (id: Id, text: Text) => (
    this.call<IRPCCommentsUpdate>('comments.update', { id, text }, 'profi')
  )

  deleteComment = (id: Id) => (
    this.call<IRPCCommentsDelete>('comments.delete', { id }, 'profi')
  )

  getCommentsByReportUuid = (uuid: UUID, pagination?: Pagination, sort?: Sort) => (
    this.call<IRPCCommentsGetByReportUuid>('comments.getByReportUuid', { uuid, pagination, sort }, 'profi')
  )
}
