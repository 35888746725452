import { Module, createMapper } from 'vuex-smart-module';

import ReportsState from '@/store/modules/reports/state';
import ReportsGetters from '@/store/modules/reports/getters';
import ReportsMutations from '@/store/modules/reports/mutations';
import ReportsActions from '@/store/modules/reports/actions';

const reports = new Module({
  state: ReportsState,
  getters: ReportsGetters,
  mutations: ReportsMutations,
  actions: ReportsActions,
});

export const reportsMapper = createMapper(reports);

export default reports;
