// Example forms ['1', '3', '5'] -> ['таможенная декларация', 'таможенные декларации', 'таможенных деклараций']

export type TPluralForms = [string, string, string];

export default function plural(numb: number, forms: TPluralForms, pure = false): string {
  const n = Math.abs(numb) % 100;
  const n1 = n % 10;
  let formsIndex = 2;

  if (n > 10 && n < 20) formsIndex = 2;
  else if (n1 > 1 && n1 < 5) formsIndex = 1;
  else if (n1 === 1) formsIndex = 0;

  if (pure) {
    return forms[formsIndex];
  }

  return `${numb}\u00A0${forms[formsIndex]}`;
}
