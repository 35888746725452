import * as Centrifuge from 'centrifuge';
import {
  WebsocketAdapterChannelInterface,
  WebsocketAdapterInterface,
} from './WebsocketAdapterInterface';

export interface IWsClientOptions {
  uri: string,
  onRefresh: any,
  accessToken: string,
}

export type TWebSocketInstanceName = 'report-create' | 'report-list-update' | 'report-update';
export type TWebSocketEvent = 'report-ready' | 'report-completed' | 'report-updated';

class WsChannel implements WebsocketAdapterChannelInterface {
  protected channel: Centrifuge.Subscription;
  protected observer: { [event_name: string]: Function } = {};
  protected tryes: number = 0;
  protected failClosure: Function = () => {};

  constructor(channel: Centrifuge.Subscription) {
    this.channel = channel;
  }

  /**
   *
   * @param {string} eventName Название события на которое идет подписка.
   * @param {function} closure функция выполняемая по событию.
   * @param {function} failClosure функция при ошибке подписки на канал
   * @returns {WebsocketAdapterChannelInterface}
   */
  listen(
    eventName: string,
    closure: Function,
    failClosure: Function,
  ): WebsocketAdapterChannelInterface {
    if (failClosure !== undefined) {
      this.failClosure = failClosure;
    }

    this.observer[eventName] = closure;
    this.channel.on('publication', (msg) => {
      if (msg.data.event === eventName) {
        closure(msg.data);
      }
    });
    return this;
  }

  leave(): void {
    this.channel.unsubscribe();
    this.channel.removeAllListeners();
  }

  checkHistory(): void {
    const channel = this;
    this.channel.history({ limit: 10 }).then((messages: any) => {
      messages.publications.forEach((element: any) => {
        const closure = channel.observer[element.data.event];
        if (typeof closure === 'function') {
          closure(element.data.payload);
        }
      });
    });
  }

  resubscribe(): void {
    this.channel.subscribe();
  }

  fail(): void {
    this.failClosure();
  }
}

export default class WsClient implements WebsocketAdapterInterface {
  protected connection!: Centrifuge.Centrifuge;
  protected channelMap: { [key: string]: WsChannel } = {};
  protected instance: WsClient;

  constructor({ uri, onRefresh, accessToken }: IWsClientOptions) {
    // @ts-ignore
    if (this.instance !== undefined) {
      return this.instance;
    }
    this.instance = this;

    const transports: Centrifuge.TransportEndpoint[] = [{
      transport: 'websocket',
      endpoint: uri,
    }];

    this.connection = new Centrifuge.Centrifuge(transports, {
      // TODO: set to true during development, like: process.env.centrifuge_debug || false
      debug: false,
      token: accessToken,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getToken(ctx: Centrifuge.ConnectionTokenContext) {
        return onRefresh();
      },
    });

    this.connection.connect();

    return this.instance;
  }

  public getChannels() {
    return this.channelMap;
  }

  // @ts-ignore
  public subscribe(channelName: string): WebsocketAdapterChannelInterface {
    if (this.channelMap[channelName]) {
      return this.channelMap[channelName];
    }

    const subscribed = this.connection.newSubscription(channelName);
    const channel = new WsChannel(subscribed);
    this.channelMap[channelName] = channel;
    subscribed.subscribe();

    return channel;
  }

  public unsubscribe(channelName: string): this {
    if (this.channelMap[channelName]) {
      this.channelMap[channelName].leave();
      delete this.channelMap[channelName];
    }

    return this;
  }

  public unsubscribeAll(): this {
    Object.keys(this.channelMap).forEach((channelName) => {
      this.unsubscribe(channelName);
    });
    this.channelMap = {};

    return this;
  }

  public disconnect(): this {
    this.unsubscribeAll();
    this.connection.disconnect();

    return this;
  }
}
