import { Module, createMapper } from 'vuex-smart-module';

import PPSpectrumdataState from '@/store/modules/ppSpectrumdata/state';
import PPSpectrumdataGetters from '@/store/modules/ppSpectrumdata/getters';
import PPSpectrumdataMutations from '@/store/modules/ppSpectrumdata/mutations';
import PPSpectrumdataActions from '@/store/modules/ppSpectrumdata/actions';

const ppSpectrumdata = new Module({
  state: PPSpectrumdataState,
  getters: PPSpectrumdataGetters,
  mutations: PPSpectrumdataMutations,
  actions: PPSpectrumdataActions,
});

export const ppSpectrumdataMapper = createMapper(ppSpectrumdata);

export default ppSpectrumdata;
