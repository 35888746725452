import axios, {
  AxiosInstance, AxiosAdapter, AxiosResponse, AxiosRequestConfig, AxiosError, AxiosRequestHeaders,
} from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

import {
  Headers, IDMethod, IApiOptions,
  Method, Params,
  IRPCRequestData, TRPCResponseData,
  IMethodData, ITupleMethodData,
  TArrayOfResponse, TRoutes, TOptionsHttpClient,
} from '@/services/httpClient/types';

import authStorage from '@/services/authTokenStorage';
import { generateID } from '@/utils/helpers';
import eventBus from '@/utils/eventBus';

import AppSettingsStorage from '@/services/appSettingsStorage';

import { isAuthenticationException } from '@/services/appError/exceptionChecker';

const appSettingsStorage = new AppSettingsStorage();

export default class HttpClient {
    private readonly defaultHeaders: Headers;
    private readonly clients: {
      [key in TRoutes]: AxiosInstance;
    }

    private lastRequestId: IDMethod = 0;

    private cacheConfig = {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    };

    constructor(options = {} as IApiOptions) {
      this.defaultHeaders = options.headers
        || {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        };

      const routes: TRoutes[] = ['profi'];

      this.clients = routes.reduce<{[key in TRoutes]: AxiosInstance}>((acc, item) => {
        acc[item] = options.client || axios.create({
          baseURL: appSettingsStorage.backendBaseUri(item) + appSettingsStorage.backendRpcRoute,
          headers: this.defaultHeaders,
          adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, this.cacheConfig),
        });

        acc[item].interceptors.request.use(
          this.configRequestInterceptor,
          this.errorRequestInterceptor,
        );

        acc[item].interceptors.response.use(
          this.responseInterceptor,
          this.errorResponseInterceptor,
        );
        return acc;
      }, {} as {[key in TRoutes]: AxiosInstance});
    }

    private genRPCRequestData(method: Method, params: Params): IRPCRequestData {
      const id = this.lastRequestId += 1;

      return {
        jsonrpc: '2.0', id, method, params,
      };
    }

    private async makeBatchRequest<T extends ITupleMethodData>(requests: IMethodData['input'][], route: TRoutes): Promise<AxiosResponse<TArrayOfResponse<T['output']>>> {
      if (requests.length === 0) {
        throw new Error('Empty batch Request');
      }

      const data = requests.map(({ method, params }) => this.genRPCRequestData(method, params));

      const result: AxiosResponse<TArrayOfResponse<T['output']>> = await this.clients[route]({ method: 'post', data });

      result.data.sort((a, b) => a.id - b.id);

      return result;
    }

    public async call<T extends IMethodData>(
      method: T['input']['method'],
      params: T['input']['params'],
      route: T['input']['route'],
      headers: AxiosRequestHeaders = {},
      options: TOptionsHttpClient = {} as TOptionsHttpClient,
    ): Promise<AxiosResponse<TRPCResponseData<T['output']>>> {
      let signal;
      if (options.signal) {
        // eslint-disable-next-line prefer-destructuring
        signal = options.signal;
      }
      return this.clients[route]({
        signal,
        method: 'post',
        data: this.genRPCRequestData(method, params),
        headers,
      });
    }

    public async batch<T extends ITupleMethodData>(args: T['input'], route: T['input'][number]['route']): Promise<AxiosResponse<TArrayOfResponse<T['output']>>>
    public async batch<T extends ITupleMethodData>(args: T['input'][], route: T['input'][number]['route']): Promise<AxiosResponse<TArrayOfResponse<T['output']>[]>>
    public async batch<T extends ITupleMethodData>(args: T['input'] | T['input'][], route: T['input'][number]['route']): Promise<AxiosResponse<TArrayOfResponse<T['output']> | TArrayOfResponse<T['output']>[]>> {
      function isRepeatableBatch(input: T['input'] | T['input'][]): input is T['input'][] {
        return Array.isArray(input[0]);
      }

      if (isRepeatableBatch(args)) {
        const argsFlatted = args.reduce<IMethodData['input'][]>((acc, item) => {
          acc.push(...item);
          return acc;
        }, []);

        const sizeOfSubarray = args[0].length;

        const result = await this.makeBatchRequest<T>(argsFlatted, route);

        const resultMapped: AxiosResponse<TArrayOfResponse<T['output']>[]> = {
          ...result,
          data: result.data.reduce<TArrayOfResponse<T['output']>[]>((acc, item, index, array) => {
            if (index % sizeOfSubarray === 0) {
              acc[index / sizeOfSubarray] = [] as Array<TRPCResponseData<T['output'][keyof T['output']]>> as TArrayOfResponse<T['output']>;
              const curEl = acc[index / sizeOfSubarray];
              for (let i = 0; i < sizeOfSubarray; i += 1) {
                curEl.push(array[index + i]);
              }
            }
            return acc;
          }, []),
        };

        return resultMapped;
      }

      const result = await this.makeBatchRequest<T>(args, route);

      return result;
    }

    private configRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
      const { token } = authStorage;
      if (authStorage.isLoggedIn) {
        // Здесь изменяется значение в ссылке, что добавляет побочный эффект функции
        // так сделано т.к. слишком дорого копировать при каждом запросе объект config

        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }

    private errorRequestInterceptor(error: AxiosError): Promise<AxiosError> {
      return Promise.reject(error);
    }

    private responseInterceptor(response: AxiosResponse<TRPCResponseData<any> | TRPCResponseData<any>[]>): AxiosResponse<TRPCResponseData<any> | TRPCResponseData<any>[]> {
      const isBatch = Array.isArray(response.data);

      if (isBatch) {
        const batchAnswer = response.data as TRPCResponseData<any>[];
        const isBatchIncludesNotAuthError = batchAnswer.some(answer => isAuthenticationException(answer.error));
        if (isBatchIncludesNotAuthError) {
          eventBus.emit('goToAuth');
        }
      } else {
        const { error } = response.data as TRPCResponseData<any>;

        if (isAuthenticationException(error)) {
          eventBus.emit('goToAuth');
        }
      }

      return response;
    }

    private errorResponseInterceptor(error: AxiosError): Promise<AxiosError> {
      if (error.message === 'Network Error') {
        eventBus.emit('pushNotification', { id: generateID(), msg: 'Не доступно интернет соединение' });
      }
      return Promise.reject(error);
    }
}
