<template>
  <form
    id="reset-form-confirm"
    name="reset-form-confirm"
    class="form-update-pass"
    :class="{'form-update-pass--mobile': isMobile}"
    @submit.prevent="onSubmitResetConfirmForm"
  >
    <div class="form-update-pass__field">
      <UIInputPassword
        id="password"
        v-model="password"
        type="password"
        size="x12"
        name="password"
        autocomplete="new-password"
        placeholder="Новый пароль*"
        :category="inputCategory"
        :state="(v$.password.$error || Boolean(errors.password)) ? 'error' : ''"
        @focus="onPasswordFocus"
        @blur="v$.password.$touch()"
        @enter="onEnterPasswordField"
      />
      <BaseHint :visible="v$.password.required.$invalid && v$.password.$dirty" type="error">
        Пароль обязателен
      </BaseHint>
      <BaseHint :visible="v$.password.minLength.$invalid && v$.password.$dirty" type="error">
        Минимальная длина пароля {{ maxSymbolsText }}
      </BaseHint>
      <BaseHint :visible="Boolean(errors.password)" type="error">
        {{ errors.password }}
      </BaseHint>
    </div>
    <div class="form-update-pass__field">
      <UIInputPassword
        v-model="confirmPassword"
        type="password"
        size="x12"
        name="password"
        autocomplete="new-password"
        placeholder="Повторите пароль*"
        :category="inputCategory"
        :state="v$.confirmPassword.$error ? 'error' : ''"
        @focus="v$.confirmPassword.$reset"
        @blur="v$.confirmPassword.$touch"
        @change="confirmPassword = $event.target.value"
        @enter="onEnterRepeatPasswordField"
      />
      <BaseHint :visible="v$.confirmPassword.required.$invalid && v$.confirmPassword.$dirty" type="error">
        Повторение пароля обязательно
      </BaseHint>
      <BaseHint :visible="v$.confirmPassword.sameAs.$invalid && v$.confirmPassword.$dirty" type="error">
        Пароли должны совпадать
      </BaseHint>
    </div>
    <div class="text-body-20 link form-update-pass__link">
      <RouterLink :to="{ name: 'auth' }" class="text-body-20 link">Войти</RouterLink>
    </div>
    <UIButton v-if="!isMobile"
              class="form-update-pass__btn"
              w100
              size="x15"
              type="submit"
              form="reset-form-confirm"
              :isLoading="isLoading"
    >Восстановить</UIButton>
    <Portal v-else to="action">
      <UIButton
        type="submit"
        size="x14"
        class="form-update-pass__btn"
        form="reset-form-confirm"
        :isLoading="isLoading"
        w100
      >Восстановить</UIButton>
    </Portal>
  </form>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required, sameAs } from '@vuelidate/validators';
import { ClientPassword } from '@/rpc-types/profi';
import { PASSWORD_LENGTH } from '@/consts/report';
import plural from '@/filters/plural';
import { Status } from '@/types/statuses';
import { TResetConfirmSubmit } from '@/components/micro-app/reset-password-confirm/ResetPasswordConfirm.vue';
import BaseHint from '@/components/legacy/base/BaseHint.vue';
import { UIInputPassword, UIButton } from '@avto-internal/avtocod-meta-ui';

interface IEmits {
  (e: 'reset-confirm:submit', value: TResetConfirmSubmit): void,
}
const props = defineProps({
  status: {
    type: String as PropType<Status>,
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<IEmits>();
const password = ref<ClientPassword>('');
const confirmPassword = ref<ClientPassword>('');
const errors = ref({
  password: '',
});

const rules = {
  password: {
    required,
    minLength: minLength(PASSWORD_LENGTH),
  },
  confirmPassword: {
    required,
    sameAs: sameAs(password),
  },
};

const v$ = useVuelidate(rules, { password, confirmPassword });

async function onSubmitResetConfirmForm(): Promise<void> {
  if (isLoading.value) {
    return;
  }

  v$.value.$touch();

  if (v$.value.$invalid) return;
  const resetData = {
    password: password.value,
  };

  emit('reset-confirm:submit', resetData);
}

function onPasswordFocus(): void {
  v$.value.password?.$reset();
  errors.value.password = '';
}

function onEnterPasswordField(event: KeyboardEvent) {
  event.preventDefault();
  v$.value.confirmPassword.$touch();
  onEnterKeydown();
}

function onEnterRepeatPasswordField(event: KeyboardEvent) {
  event.preventDefault();
  v$.value.password.$touch();
  onEnterKeydown();
}

function onEnterKeydown() {
  if (!v$.value.$invalid) {
    onSubmitResetConfirmForm();
  }
}

const maxSymbolsText = computed(() => plural(v$.value.password.minLength.$params.min, ['символ', 'символа', 'символов'], true));
const isLoading = computed(() => props.status === 'loading');
const inputCategory = computed(() => (props.isMobile ? 'invert' : 'stroke'));

</script>

<style lang="scss">
.form-update-pass {
  --fields-margin: #{gridCalc(2)};

  &--mobile {
    --fields-margin: #{gridCalc(1)};
  }
}

.form-update-pass__field {
  margin-top: var(--fields-margin);
}

.form-update-pass__field:first-of-type {
  margin-top: 0;
}

.form-update-pass__btn {
  margin-top: gridCalc(4);
}

.form-update-pass__link {
  margin-top: gridCalc(4);
  display: flex;
  justify-content: center;
}
</style>
