<template>
  <div class="view-reset-mobile">
    <LayoutLoginMobile>
      <template #headerBefore>
        <HeaderButtonBack class="view-reset-mobile__btn" :click-prop="clickBack" />
      </template>
      <BaseLoginContainerMobile>
        <h2 class="view-reset-mobile__title">Восстановление пароля</h2>
        <p class="view-reset-mobile__msg">от учетной записи сервиса Автокод Профи</p>
        <ResetPassword class="view-reset-mobile__form" :isMobile="true"/>
      </BaseLoginContainerMobile>
      <template #action>
        <BaseLoginContainerMobile>
          <PortalTarget name="action"></PortalTarget>
        </BaseLoginContainerMobile>
      </template>
    </LayoutLoginMobile>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LayoutLoginMobile from '@/layouts/layout-login-mobile/LayoutLoginMobile.vue';
import HeaderButtonBack from '@/components/legacy/common/mobile/header/HeaderButtonBack.vue';
import BaseLoginContainerMobile from '@/components/base/base-login-container-mobile/BaseLoginContainerMobile.vue';

import ResetPassword from '@/components/micro-app/reset-password/ResetPassword.vue';

export default defineComponent({
  name: 'PageReset',
  components: {
    HeaderButtonBack,
    LayoutLoginMobile,
    BaseLoginContainerMobile,
    ResetPassword,
  },
  methods: {
    clickBack() {
      this.$router.back();
    },
  },
});

</script>

<style lang="scss" scoped>
.view-reset-mobile {
  --custom-layout-login-mobile-main-justify-content: center;
}

.view-reset-mobile__title {
  text-align: center;
  margin: gridCalc(0, 0, 1);
}

.view-reset-mobile__form {
  margin-top: gridCalc(3);
}

.view-reset-mobile__msg {
  font-size: 14px;
  text-align: center;
  margin: 0;
}

</style>
