import PackageVersion from '@/services/packageVersion';
import { isMobile } from '@/utils/helpers';

const pckVer = new PackageVersion();

export default class AppState {
  packageVersion: string = pckVer.getVersion();
  isMobile: boolean = isMobile();
  isShowNavMenuSidebar: boolean = false;
}
