export function updateDescription(description: string): void {
  document.querySelector('meta[name="description"]')!.setAttribute('content', description);
}

export function updateTitle(title: string): void {
  document.title = title;
}

export function updateFullReportTitle(startOfTitle: string, reportBrand: string, type: string, query: string) {
  const typeValues: { [key: string]: string } = {
    VIN: 'по вин',
    GRZ: 'по госномеру',
    BODY: 'по номеру кузова',
  };
  updateTitle(`${startOfTitle} ${reportBrand} ${typeValues[type] || ''} ${query} — Автокод профи`);
}
