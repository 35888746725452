<template>
  <FormResetConfirm
    :status="status"
    :isMobile="isMobile"
    @reset-confirm:submit="onSubmitForm"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import FormResetConfirm from '@/components/particles/form-reset-confirm/FormResetConfirm.vue';

import { SEARCH_PARAMS } from '@/consts/search';
import { ClientPassword, Email, Token } from '@/rpc-types/profi';
import authStorage from '@/services/authTokenStorage';
import { FirebaseAnalyticsManager } from '@/services/firebaseAnalytics';
import AuthApi from '@/services/httpClient/api/profi/auth.api';
import { MindBox } from '@/services/mindBox';
import { webViewAndroid, webViewIOS } from '@/services/webView';
import { batchMapper } from '@/store/modules/batch';
import { deviceMapper } from '@/store/modules/device';
import { productsMapper } from '@/store/modules/products';
import { profileMapper } from '@/store/modules/profile';
import { reportsMapper } from '@/store/modules/reports';
import { reportsFacadeMapper } from '@/store/modules/reports-facade';
import { Status } from '@/types/statuses';
import eventBus from '@/utils/eventBus';
import { generateID } from '@/utils/helpers';

const authApi = new AuthApi();

export interface TResetConfirmSubmit {
  password: ClientPassword;
}

interface TResetFormData {
  isLoading: boolean;
  status: Status;
  email: Email;
  token: Token;
  isAlertOpen: boolean;
  alert: {
    alertTitle: string;
    alertContent: string;
  };
}

export default defineComponent({
  name: 'ResetPasswordConfirm',
  props: {
    isMobile: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data(): TResetFormData {
    return {
      isLoading: false,
      status: '',
      email: '',
      token: '',
      isAlertOpen: false,
      alert: {
        alertTitle: '',
        alertContent: '',
      },
    };
  },
  components: {
    FormResetConfirm,
  },
  computed: {
    isSuccess() {
      return this.status === 'success';
    },
  },
  methods: {
    ...batchMapper.mapActions(['fetchDataForAuthUser']),
    ...deviceMapper.mapActions(['setDeviceId']),
    ...productsMapper.mapActions(['updateProducts']),
    ...reportsMapper.mapActions(['fetchReportList']),
    ...reportsFacadeMapper.mapActions(['fetchPromoReportsCount']),
    ...profileMapper.mapMutations(['setProfile']),
    showAlert(message: string): void {
      eventBus.emit('pushNotification', { id: generateID(), msg: message });
    },
    async onSubmitForm(submitEvent: TResetConfirmSubmit): Promise<void> {
      this.status = 'loading';
      try {
        if (this.token) {
          const {
            data: { error, result },
          } = await authApi.confirmReset(this.email, submitEvent.password, this.token as Token);
          if (error) {
            this.status = 'error';
            const isNotValidToken = error.code === 29002;
            if (isNotValidToken) {
              this.showAlert(
                'Данная ссылка для сброса пароля недействительна или истекла. Пожалуйста, запросите новую ссылку для сброса пароля.'
              );
            } else {
              this.showAlert('Произошла ошибка. Попробуйте снова или обратитесь в техподдержку.');
            }
          } else {
            this.status = 'success';
            if (result) {
              authStorage.token = result.token;
              this.setProfile(result);

              webViewIOS.onLogin({ email: this.email, password: submitEvent.password });
              webViewAndroid.onLogin({ email: this.email, password: submitEvent.password });
              MindBox.auth(result.email, result.uuid);

              const dataForAuthUser = await this.fetchDataForAuthUser();
              // FIXME-VUE3 !92 исправить типы после обновления на vue3
              // @ts-ignore
              const { profileResponse } = dataForAuthUser;
              if (profileResponse) {
                const userUUID = profileResponse?.result?.uuid || '';
                FirebaseAnalyticsManager.setUserUUID(userUUID);
              }

              FirebaseAnalyticsManager.onLogin();
              await this.setDeviceId(result.uuid);
              await this.updateProducts();
              await this.fetchReportList({
                pagination: SEARCH_PARAMS.DEFAULT_PAGINATION,
                sort: SEARCH_PARAMS.DEFAULT_SORT,
              });

              await this.fetchPromoReportsCount();
              this.$router.push({
                name: 'home',
              });
            }
          }
        }
      } catch (error) {
        this.status = 'error';
        this.showAlert('Произошла ошибка. Попробуйте снова или обратитесь в техподдержку.');
      }
    },
  },
  mounted() {
    const { email, token } = this.$route.query;

    if (email && token) {
      this.email = email as string;
      this.token = token as string;
    } else {
      this.showAlert(
        'Данная ссылка для сброса пароля недействительна или истекла. Пожалуйста, запросите новую ссылку для сброса пароля.'
      );
    }
  },
});
</script>
