import Vue from 'vue';

import GContainer from '@/grid/GContainer.vue';
import GRow from '@/grid/GRow.vue';
import GCol from '@/grid/GCol.vue';
import GSpacer from '@/grid/GSpacer.vue';

Vue.component('g-container', GContainer);
Vue.component('g-row', GRow);
Vue.component('g-col', GCol);
Vue.component('g-spacer', GSpacer);
