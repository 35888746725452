import {
  Pagination,
  IRPCInsappGetPolicies, IRPCInsappGetCommissionInfo,
} from '@/rpc-types/profi';

import HttpClient from '@/services/httpClient/index';

export default class PoliciesApi extends HttpClient {
  fetchPoliciesHistoryList = (pagination?: Pagination) => (
    this.call<IRPCInsappGetPolicies>('insapp.getPolicies', { pagination }, 'profi')
  )

  fetchCommissionInfo = () => (
    this.call<IRPCInsappGetCommissionInfo>('insapp.getCommissionInfo', { }, 'profi')
  )
}
