import { AxiosInstance, AxiosResponse } from 'axios';
import FormData from 'form-data';
import ppSpectrumClient from '@/services/RESTClients/ppSpectrumClient';
import { IApiPPSpectrum, DataPP } from '@/store/modules/ppSpectrumdata/types';

const apiPPSpectrumFactory = (client: AxiosInstance) => ({
  checkStatus(form: FormData): Promise<AxiosResponse<DataPP, string>> {
    return client.post('/api/check.php', form);
  },
  registerInPartnerProgram(form: FormData): Promise<AxiosResponse<DataPP, string>> {
    return client.post('/api/signup.php', form);
  },
});

const apiPPSpectrum: IApiPPSpectrum = apiPPSpectrumFactory(ppSpectrumClient);

export default apiPPSpectrum;
