import { Module, createMapper } from 'vuex-smart-module';

import ProductsState from '@/store/modules/products/state';
import ProductsGetters from '@/store/modules/products/getters';
import ProductsMutations from '@/store/modules/products/mutations';
import ProductsActions from '@/store/modules/products/actions';

const products = new Module({
  state: ProductsState,
  getters: ProductsGetters,
  mutations: ProductsMutations,
  actions: ProductsActions,
});

export const productsMapper = createMapper(products);

export default products;
