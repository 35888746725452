import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import { Id, Pagination, ProductPrice, Promocode, ReferralLinkId, Sort, UUID } from '@/rpc-types/profi';
import { getProfiErrorDescription } from '@/services/appError';
import PaymentsApi from '@/services/httpClient/api/profi/payments.api';
import clients from '@/store/modules/clients';
import PaymentGetters from '@/store/modules/payments/getters';
import PaymentMutations from '@/store/modules/payments/mutations';
import { IPaymentsPayCarOwnerReportProps } from '@/store/modules/payments/payments.types';
import PaymentState from '@/store/modules/payments/state';
import eventBus from '@/utils/eventBus';
import { generateID } from '@/utils/helpers';

export default class PaymentActions extends Actions<PaymentState, PaymentGetters, PaymentMutations, PaymentActions> {
  api!: PaymentsApi;
  clients!: Context<typeof clients>;

  $init(store: Store<never>): void {
    this.api = new PaymentsApi();
    this.clients = clients.context(store);
  }

  async fetchPaymentsHistoryList({ pagination, sort }: { pagination?: Pagination; sort?: Sort }): Promise<void> {
    const {
      data: { result, error },
    } = await this.api.fetchPaymentsHistoryList(pagination, sort);

    // eslint-disable-next-line no-console
    if (error) console.warn('fetchPaymentsHistoryList error:', error);

    if (result) {
      result.payments.forEach((payment) => {
        this.mutations.setPaymentsHistoryItem({ payment });
      });
    }
  }

  async payAccess({
    product_uuid,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    product_uuid: string;
    bank_card_uuid: string;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) {
    const {
      data: { result, error },
    } = await this.api.payAccess({ product_uuid, bank_card_uuid, promocode, referral_link_id });

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('pay access error: ', error);
    }

    return {
      error,
      result,
    };
  }

  async payAdditionalReports({
    priceEntity,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    priceEntity: ProductPrice;
    bank_card_uuid: string;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) {
    const {
      data: { result, error },
    } = await this.api.payProduct({ price_uuid: priceEntity.uuid, bank_card_uuid, promocode, referral_link_id });

    // eslint-disable-next-line no-console
    if (error) console.warn('payAdditionalReports error:', error);

    if (result) {
      this.clients.mutations.incrementCurrentAccessRestOfReports(priceEntity.count);
    }

    return {
      error,
      result,
    };
  }

  async paySubscription({
    product_uuid,
    bank_card_uuid,
    promocode,
    referral_link_id,
  }: {
    product_uuid: UUID;
    bank_card_uuid: UUID;
    promocode?: Promocode;
    referral_link_id?: ReferralLinkId;
  }) {
    const {
      data: { result, error },
    } = await this.api.paySubscription({ product_uuid, bank_card_uuid, promocode, referral_link_id });

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('subscribe error: ', error);
    }

    return {
      error,
      result,
    };
  }

  async renewSubscription({ subscription_id, bank_card_uuid }: { subscription_id: Id; bank_card_uuid: UUID }) {
    const {
      data: { result, error },
    } = await this.api.renewSubscription({ subscription_id, bank_card_uuid });

    if (error) {
      // eslint-disable-next-line no-console
      console.warn('subscribe error: ', error);
    }

    return {
      error,
      result,
    };
  }

  async payOwnerReport({
    product_uuid,
    bank_card_uuid,
    delivery_email,
    first_name,
    patronymic,
    last_name,
    birth_date,
    passport,
    referral_link_id,
  }: IPaymentsPayCarOwnerReportProps) {
    try {
      const {
        data: { result, error },
      } = await this.api.payOwnerReport({
        product_uuid,
        bank_card_uuid,
        delivery_email,
        first_name,
        patronymic,
        last_name,
        birth_date,
        passport,
        referral_link_id,
      });

      if (error) {
        const errorMsg = error.data?.code
          ? `При оплате произошла ошибка. ${getProfiErrorDescription(error.data?.code)}`
          : 'При оплате произошла ошибка.';
        eventBus.emit('pushNotification', { id: generateID(), msg: errorMsg });
      }

      return {
        error,
        result,
      };
    } catch (e) {
      eventBus.emit('pushNotification', {
        id: generateID(),
        msg: 'Во время оплаты произошла ошибка сети, попробуйте снова',
      });
      return null;
    }
  }
}
