<template>
  <div
    class="mobile-apps"
  >
    <a class="mobile-apps__link"
       :href="appStoreLink"
       target="_blank"
    >
      <IconAppStore class="mobile-apps__icon" />
    </a>
    <a class="mobile-apps__link"
       :href="rustoreLink"
       target="_blank"
    >
      <IconRustore class="mobile-apps__icon"/>
    </a>
    <a class="mobile-apps__link"
       :href="appGalleryLink"
       target="_blank"
    >
      <IconAppGallery class="mobile-apps__icon"/>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import IconAppStore from '@/components/icons/IconAppStore.vue';
import IconAppGallery from '@/components/icons/IconAppGallery.vue';
import IconRustore from '@/components/icons/IconRustore.vue';
import { APP_GALLERY_LINK, APP_STORE_LINK, RUSTORE_LINK } from '@/consts/staticLinks';

export default defineComponent({
  components: {
    IconAppStore,
    IconAppGallery,
    IconRustore,
  },
  computed: {
    appStoreLink(): string {
      return APP_STORE_LINK;
    },

    rustoreLink(): string {
      return RUSTORE_LINK;
    },

    appGalleryLink(): string {
      return APP_GALLERY_LINK;
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-apps {
  --icon-color: #43485b;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-min(md) {
    flex-direction: row;
  }
}

.mobile-apps__link {
  flex: 188px 0 0;
  max-height: 56px;
  margin-left: gridCalc(4);

  &:hover {
    --icon-color: #067eff;
  }

  @include media-min(xl) {
    flex: 268px 0 0;
    max-height: 80px;
    margin-left: gridCalc(7);
  }
}

.mobile-apps__link:first-child {
  margin-bottom: gridCalc(2);

  @include media-min(md) {
    margin-left: 0;
    margin-bottom: 0;
  }
}

.mobile-apps__icon {
  color: var(--icon-color);
  width: 100%;
  height: 100%;
}
</style>
