import Cookies from 'js-cookie';

const PARTNER_PROGRAM_COOKIE_KEY = 'PARTNER_ID';
const PARTNER_PROGRAM_COOKIE_EXP_DAYS = 60;

export default function checkPartnerProgramIdentifier() {
  const params = new URLSearchParams(document.location.search);

  const partnerId = params.get('refLinkId') || params.get('a_aid');

  if (partnerId) setPartnerId(partnerId);
}

function setPartnerId(id: string) {
  Cookies.set(PARTNER_PROGRAM_COOKIE_KEY, id, {
    expires: PARTNER_PROGRAM_COOKIE_EXP_DAYS,
  });
}

/*
  Возвращает идентификатор партнерской программы
 */
export function getPartnerId() {
  return Cookies.get(PARTNER_PROGRAM_COOKIE_KEY);
}
