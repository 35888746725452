import { Module, createMapper } from 'vuex-smart-module';

import CommentsState from '@/store/modules/comments/state';
import CommentsGetters from '@/store/modules/comments/getters';
import CommentsMutations from '@/store/modules/comments/mutations';
import CommentActions from '@/store/modules/comments/actions';

const comments = new Module({
  state: CommentsState,
  getters: CommentsGetters,
  mutations: CommentsMutations,
  actions: CommentActions,
});

export const commentsMapper = createMapper(comments);

export default comments;
