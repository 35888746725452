import { requireAuth } from '@/router/utils';

const PageProfile = () => import('@/pages/common/profile/PageProfile.vue');
const PageProfileEdit = () => import('@/pages/common/profile/PageProfileEdit.vue');
const PageProfileDelete = () => import('@/pages/mobile/profile/PageProfileDelete.vue');
const PageProfileDeleteReasons = () => import('@/pages/mobile/profile/PageProfileDeleteReasons.vue');
const PageProfileSettings = () => import('@/pages/common/profile/PageProfileSettings.vue');
const PageConfirmPhone = () => import('@/pages/common/profile/PageProfileConfirm.vue');

export default [
  {
    path: '/profile',
    name: 'profile',
    component: PageProfile,
    beforeEnter: requireAuth,
    meta: {
      title: 'Профиль',
      description: 'Настройки, уведомления и редактирование профиля — Автокод Профи',
    },
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: PageProfileEdit,
    beforeEnter: requireAuth,
    meta: {
      title: 'Редактирование профиля',
    },
  },
  {
    path: '/profile/settings',
    name: 'profile-settings',
    component: PageProfileSettings,
    beforeEnter: requireAuth,
    meta: {
      title: 'Смена пароля',
    },
  },
  {
    path: '/profile/delete',
    name: 'profile-delete',
    component: PageProfileDelete,
    beforeEnter: requireAuth,
    meta: {
      title: 'Удаление профиля',
    },
  },
  {
    path: '/profile/delete/reasons',
    name: 'profile-delete-reasons',
    component: PageProfileDeleteReasons,
    beforeEnter: requireAuth,
    meta: {
      title: 'Причины удаления',
    },
  },
  {
    path: '/profile/confirm-phone',
    name: 'profile-confirm-phone',
    component: PageConfirmPhone,
    meta: {
      title: 'Подтверди номер телефона',
    },
    beforeEnter: requireAuth,
  },
];
