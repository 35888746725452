import { profileDeleteReasons, requireAuth } from '@/router/utils';

const PageProfile = () => import('@/pages/common/profile/PageProfile.vue');
const PageProfileEdit = () => import('@/pages/common/profile/PageProfileEdit.vue');
const PageProfileDelete = () => import('@/pages/desktop/profile/PageProfileDelete.vue');
const PageProfileDeleteReasons = () => import('@/pages/desktop/profile/PageProfileDeleteReasons.vue');
const PageProfileSettings = () => import('@/pages/common/profile/PageProfileSettings.vue');
const PageConfirmPhone = () => import('@/pages/common/profile/PageProfileConfirm.vue');

export default [
  {
    path: '/profile',
    name: 'profile',
    component: PageProfile,
    meta: {
      title: 'Профиль',
      description: 'Настройки, уведомления и редактирование профиля — Автокод Профи',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: PageProfileEdit,
    meta: {
      title: 'Редактирование профиля',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/profile/edit/delete',
    name: 'profile-delete',
    component: PageProfileDelete,
    meta: {
      title: 'Удаление профиля',
    },
    beforeEnter: requireAuth,
  },

  /* страница на проекте не используется */
  {
    path: '/delete-reasons/:type',
    name: 'profile-delete-reasons',
    component: PageProfileDeleteReasons,
    meta: {
      title: 'Удаление профиля',
    },
    beforeEnter: profileDeleteReasons,
  },
  {
    path: '/profile/settings',
    name: 'profile-settings',
    component: PageProfileSettings,
    meta: {
      title: 'Смена пароля',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/profile/confirm-phone',
    name: 'profile-confirm-phone',
    component: PageConfirmPhone,
    meta: {
      title: 'Подтверждение номера телефона',
    },
    beforeEnter: requireAuth,
  },
];
