import multiguard from 'vue-router-multiguard';

import { requireAuth, requireProductExist } from '@/router/utils';

const PageSubs = () => import('@/pages/common/subs/PageSubscription/PageSubscription.vue');
const PageSubsPayment = () => import('@/pages/common/subs/PageSubscriptionPayments/PageSubscriptionPayments.vue');
const PageSubsHistory = () => import('@/pages/mobile/subs/PageSubsHistory.vue');
const PageSubsUnsub = () => import('@/pages/common/subs/PageSubsUnsub.vue');
const PageSubsUnsubReasons = () => import('@/pages/mobile/subs/PageSubsUnsubReasons.vue');
const PageSubsUnsubFeedback = () => import('@/pages/mobile/subs/PageSubsUnsubFeedback.vue');
const PageSubsManagement = () => import('@/pages/mobile/subs/PageSubsManagement.vue');
const PageSubsOptions = () => import('@/pages/common/subs/PageSubsOptions.vue');
const PageSubs3dsMiddleware = () => import('@/pages/desktop/subs/PageSubs3dsMiddleware.vue');
const PageSubscriptionTariffs = () => import('@/pages/common/subs/PageSubscriptionTariffs/PageSubscriptionTariffs.vue');
const PageReportsPack = () => import('@/pages/common/subs/PageReportsPack/PageReportsPack.vue');
const PageSubscriptionPaymentsUnavailable = () =>
  import('@/pages/common/subs/PageSubscriptionPayments/PageSubscriptionPaymentsUnavailable.vue');

export default [
  {
    path: '/subs',
    name: 'subs',
    component: PageSubs,
    beforeEnter: requireAuth,
    meta: {
      title: 'Подписка и тарифы',
      description:
        'Ежемесячная подписка на безлимитное количество проверок автомобилей для профессионалов — Автокод Профи',
    },
  },
  {
    path: '/subs/tariffs',
    name: 'subs-tariffs',
    component: PageSubscriptionTariffs,
    beforeEnter: requireAuth,
    meta: {
      title: 'Тарифы',
    },
  },
  {
    path: '/subs/tariffs/payments',
    name: 'subs-tariffs-payments',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата',
    },
  },
  {
    path: '/subs/history',
    name: 'subs-history',
    component: PageSubsHistory,
    beforeEnter: requireAuth,
    meta: {
      title: 'История покупок',
    },
  },
  {
    path: '/subs/unsub',
    name: 'subs-unsub',
    component: PageSubsUnsub,
    beforeEnter: requireAuth,
    meta: {
      title: 'Отмена подписки',
    },
  },
  {
    path: '/subs/unsub/reasons',
    name: 'unsub-reasons',
    component: PageSubsUnsubReasons,
    beforeEnter: requireAuth,
    meta: {
      title: 'Отмена подписки',
    },
  },
  {
    path: '/subs/unsub/feedback',
    name: 'unsub-feedback',
    props: true,
    component: PageSubsUnsubFeedback,
    beforeEnter: requireAuth,
    meta: {
      title: 'Отмена подписки',
    },
  },
  {
    path: '/subs/management/unavailable',
    name: 'subs-management-unavailable',
    component: PageSubscriptionPaymentsUnavailable,
    beforeEnter: requireAuth,
    meta: {
      title: 'Возобновление подписки',
    },
  },
  {
    path: '/subs/additional-reports',
    name: 'subs-additional-reports',
    component: PageReportsPack,
    beforeEnter: requireAuth,
    meta: {
      title: 'Дополнительные отчеты',
    },
  },
  {
    path: '/subs/additional-reports/payment',
    name: 'subs-additional-reports-payment',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата отчетов',
    },
  },
  {
    path: '/subs/management',
    name: 'subs-management',
    props: true,
    component: PageSubsManagement,
    beforeEnter: requireAuth,
    meta: {
      title: 'Способ оплаты и автопродление',
    },
  },
  {
    path: '/subs/management/payments',
    name: 'subs-management-payments',
    component: PageSubsPayment,
    beforeEnter: multiguard([requireAuth, requireProductExist]),
    meta: {
      title: 'Возобновление подписки',
    },
  },
  {
    path: '/subs/options',
    name: 'subs-options',
    component: PageSubsOptions,
    beforeEnter: requireAuth,
    meta: {
      title: 'Настройка платежа',
    },
  },
  {
    path: '/subs/reg',
    name: 'subs-reg',
    redirect: { name: 'guest-buy' },
  },
  {
    path: '/subs/auth',
    name: 'subs-auth',
    redirect: { name: 'guest-buy' },
  },
  {
    path: '/subs/3ds-middleware',
    name: 'subs-3ds-middleware',
    component: PageSubs3dsMiddleware,
  },
];
