import { Actions } from 'vuex-smart-module';
import to from 'await-to-js';
import dayjs from 'dayjs';

import {
  Pagination, Sort, Identifier, Type, UUID, ReportListEntity,
} from '@/rpc-types/profi';

import { ShortReport } from '@/rpc-types/short-report-types';

import { IReportListEntityWithUserContent } from '@/types/entities/report';
import { IRPCError } from '@/services/httpClient/types';

import { SEARCH_PARAMS } from '@/consts/search';
import { TIME_MS_UPDATE_REPORT_LIST, MAX_TIME_TO_GENERATE_REPORT } from '@/consts/report';

import ReportsState from '@/store/modules/reports/state';
import ReportsMutations from '@/store/modules/reports/mutations';
import ReportsGetters from '@/store/modules/reports/getters';

import ReportApi from '@/services/httpClient/api/profi/report.api';
import TokenApi from '@/services/httpClient/api/profi/token.api';

import {
  getModel, getRegNumber, getVin, getYear,
} from '@/services/ReportStateManager/get-report-fields';
import wsClientWrapper, { WsClientWrapper } from '@/services/wsClient/wsWrapper';
import WsClient, { TWebSocketEvent } from '@/services/wsClient';
import authStorage from '@/services/authTokenStorage';


/*
Причина введения дополнительных переменных в том, что библиотека "vuex-smart-module" при создании
store на классовом синтаксисе для каждого action создает свой scope со своим "this".
Тем самым, нельзя во "this" хранить что-то общее.
Использовать "state" для хранения так же не получается, т.к. vuex не позволяет хранить в себе сложные
структуры, такие как экземпляр клиента webSocket или экземпляр abortController.
Поэтому, для хранения было принято использовать замыкание, при этом, используется объектный синтаксис,
что бы все actions имели доступ к одним и тем-же экземплярам через ссылку в объекте.
*/
const wsClientReportShort: {
  value: WsClient | null,
} = {
  value: null,
};
const abortControllerShortReport: {
  value: AbortController | null,
} = {
  value: null,
};
const createIntervalIdShortReport: {
  value: number,
} = {
  value: 0,
};

export default class ReportsActions extends Actions<
  ReportsState,
  ReportsGetters,
  ReportsMutations,
  ReportsActions
> {
  api!: ReportApi;
  wsClientWrapper!: WsClientWrapper;
  createInterval!: number;
  centrifugeTokenApi!: TokenApi;

  $init(): void {
    this.api = new ReportApi();
    this.centrifugeTokenApi = new TokenApi();
    this.wsClientWrapper = wsClientWrapper;
    this.createInterval = 0;
  }

  unsubscribeWsClientReportShort(): void {
    if (wsClientReportShort.value) {
      wsClientReportShort.value.unsubscribeAll();
      wsClientReportShort.value = null;
    }

    if (abortControllerShortReport.value) {
      abortControllerShortReport.value.abort();
      abortControllerShortReport.value = null;
    }
    window.clearInterval(createIntervalIdShortReport.value);
  }

  createReport({ query, type }: { query: Identifier, type: Type}): Promise<void> {
    let isReportUpdating = false;
    return new Promise(async (resolve, reject) => {
      window.clearInterval(this.createInterval);
      const { data: { error, result } } = await this.api.createReport(query, type);

      if (error) {
        // eslint-disable-next-line no-console
        console.warn('createReport error:', error);
        reject(error);
      }

      if (result) {
        const {
          uuid,
          max_generation_time,
          channel,
        } = result;

        const { data } = await this.centrifugeTokenApi.getToken();
        const centrifugeAccessToken = data.result?.token;

        if (centrifugeAccessToken) {
          const wsClient = this.wsClientWrapper.getInstance('report-create', centrifugeAccessToken);
          // Складываем в отдельный объект, что бы иметь доступ в другом aсtion,
          // в частности в `unsubscribeWsClientReportShort`
          wsClientReportShort.value = wsClient;

          wsClient.unsubscribeAll();

          const onSuccessReportUpdated = (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            { event, report }: { event: TWebSocketEvent, report: ShortReport },
          ) => {
            if (report?.is_ready) {
              this.mutations.setReportShort(report);
              resolve();
            }

            if (report?.is_completed) {
              this.mutations.setReportShort(report);
              wsClient.unsubscribe(channel);
            }
          };

          const failSubscribeHandler = (...error: any) => {
            // eslint-disable-next-line no-console
            console.error(error);
            wsClient.unsubscribe(channel);
          };

          wsClient
            .subscribe(channel)
            .listen(
              'report-updated',
              onSuccessReportUpdated,
              failSubscribeHandler,
            )
            .listen(
              'report-ready',
              onSuccessReportUpdated,
              failSubscribeHandler,
            );

          const step = 6;
          const timeout = (max_generation_time / step) * 1000;

          let count = 1;

          const reportUpdateHandler = async () => {
            if (isReportUpdating) return;

            isReportUpdating = true;
            const [errorShort] = await to(this.actions.fetchShortReport(uuid));
            isReportUpdating = false;
            if (errorShort) {
              reject(errorShort);
            }
            const report = this.state.reportShort;

            if (report.is_ready) resolve();

            if (report.is_completed) {
              window.clearInterval(this.createInterval);
              wsClient.unsubscribe(channel);
            }

            if (count >= step) {
              window.clearInterval(this.createInterval);
              wsClient.unsubscribe(channel);
              const errorTimeout: IRPCError = {
                code: 24001,
                message: 'Мы не смогли найти машину с данным номером.',
              };
              reject(errorTimeout);
            }
            count += 1;
          };

          this.createInterval = window.setInterval(reportUpdateHandler, timeout);
          // Складываем в отдельный объект, что бы иметь доступ в другом aсtion,
          // в частности в `unsubscribeWsClientReportShort`
          createIntervalIdShortReport.value = this.createInterval;
        } else {
          // eslint-disable-next-line no-console
          console.error('error, not exists centrifuge token');
        }
      }
    });
  }

  async fetchShortReport(uuid: UUID): Promise<void> {
    // Складываем в отдельный объект, что бы иметь доступ в другом aсtion,
    // в частности в `unsubscribeWsClientReportShort`
    abortControllerShortReport.value = new AbortController();
    const optionsClient = {
      signal: (abortControllerShortReport.value as AbortController).signal,
    };
    const { data: { result, error } } = await this.api.fetchReport(uuid, optionsClient);
    if (error) {
      // eslint-disable-next-line no-console
      console.warn('fetchShortReport error:', error);
      throw error;
    }

    if (result) {
      this.mutations.setReportShort(result);
    }
  }

  async fetchPromoReportsCount(): Promise<void> {
    try {
      const { data: { result, error } } = await this.api.fetchPromoReportsCount();

      if (error) {
        // eslint-disable-next-line no-console
        console.warn('fetchPromoReportsCount error:', error);
      }

      if (result) {
        const { short: promoShortReportCount } = result;
        this.mutations.setPromoShortReportCount(promoShortReportCount);
        this.mutations.setReportDataLoaded(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('fetchPromoReportsCount error:', error);
    }
  }

  async upgradeReport(uuid: UUID): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const { data: { result, error } } = await this.api.upgradeReport(uuid);

      if (error) {
        // eslint-disable-next-line no-console
        console.warn('upgradeReport error:', error);
        reject(error);
      }

      if (result) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { max_wait_to_ready_time, channel } = result;
        const timeToFetchData = 5000;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const time = max_wait_to_ready_time * 1000 + Date.now() - timeToFetchData;
        resolve();

        const pagination: Pagination = SEARCH_PARAMS.DEFAULT_PAGINATION;
        const sort: Sort = SEARCH_PARAMS.DEFAULT_SORT;
        const newReportsResponse = await this.api.fetchReportList(pagination, sort);

        if (newReportsResponse.data.result) {
          const report = newReportsResponse.data.result.reports_list[0];
          report.brand_name_original = getModel(this.state.reportShort) || '';
          report.year = getYear(this.state.reportShort) || 0;
          report.reg_num = getRegNumber(this.state.reportShort) || '';
          report.vin = getVin(this.state.reportShort) || '';
          report.wait_to_ready_time = MAX_TIME_TO_GENERATE_REPORT;
          this.mutations.setReportList(newReportsResponse.data.result.reports_list);

          const reportsUuids = newReportsResponse.data.result.reports_list.map(item => item.uuid);
          await this.actions.fetchReportsUserContent(reportsUuids);
        }
      }
    });
  }

  async fetchReportList({ pagination, sort }: {
    pagination?: Pagination,
    sort?: Sort,
  }): Promise<number> {
    this.mutations.setLoading(true);
    let response = 0;
    const { data: { result, error } } = await this.api.fetchReportList(pagination, sort);

    // eslint-disable-next-line no-console
    if (error) console.warn(error);

    if (result) {
      const paginationOfFetch = pagination || SEARCH_PARAMS.DEFAULT_PAGINATION;
      const indexOfFirstElement = (paginationOfFetch.page - 1) * paginationOfFetch.limit;
      if (indexOfFirstElement > this.state.list.length) {
        // eslint-disable-next-line no-console
        console.warn(`We trying add element on index ${indexOfFirstElement} in array with length ${this.state.list.length}`);
        this.mutations.setLoading(false);
        return response;
      }
      result.reports_list.forEach((report: IReportListEntityWithUserContent, indexInList: number) => {
        this.mutations.setReportListItem({ report, index: indexOfFirstElement + indexInList });
      });
      const reportsUuids = result.reports_list.map(item => item.uuid);
      this.actions.fetchReportsUserContent(reportsUuids);
      response = reportsUuids.length;
    }
    this.mutations.setLoading(false);
    return response;
  }

  async updateNotReadyReportsInList(): Promise<number> {
    if (!authStorage.isLoggedIn) return 0;
    const { data } = await this.centrifugeTokenApi.getToken();
    const centrifugeAccessToken = data.result?.token;
    if (!centrifugeAccessToken) {
      // eslint-disable-next-line no-console
      console.error('error, not exists centrifuge token');
      return 0;
    }
    const wsClient = this.wsClientWrapper.getInstance('report-list-update', centrifugeAccessToken);

    let isListUpdateNow: boolean = false;
    const updateReport = async () => {
      if (isListUpdateNow) return;

      isListUpdateNow = true;
      const uuidsNotComplete = this.state.list
        .filter(report => !report.is_completed)
        .map(report => report.uuid);

      const onSuccessListUpdated = (
        { event, list_entity }: {
            event: TWebSocketEvent,
            list_entity: ReportListEntity,
          },
      ) => {
        if (event === 'report-updated') {
          this.mutations.updateReportInList(list_entity);
        }
      };
        // eslint-disable-next-line no-console
      const failSubscribeHandler = (...error: any[]) => console.error(error);

      uuidsNotComplete.forEach((uuid) => {
        const channel = `report.${uuid}`;

        const wsChannels = wsClient.getChannels();
        if (!wsChannels[channel]) {
          wsClient
            .subscribe(channel)
            .listen(
              'report-updated',
              onSuccessListUpdated,
              failSubscribeHandler,
            )
            .listen(
              'report-ready',
              onSuccessListUpdated,
              failSubscribeHandler,
            );
        }
      });

      const uuidsNotReady = this.state.list
        .filter(report => !report.is_ready)
        .map(report => report.uuid);

      if (uuidsNotReady.length === 0) {
        isListUpdateNow = false;
        return;
      }

      const { data: { result, error } } = await this.api.fetchReportList(
        { page: 1, limit: uuidsNotReady.length }, undefined,
        { uuids: uuidsNotReady },
      );


      if (error) {
        // eslint-disable-next-line no-console
        console.warn('updateNotReadyReportsInList error:', error);
        isListUpdateNow = false;
      }
      if (result) {
        result.reports_list.forEach((report) => {
          if (dayjs() > dayjs(report.generation_start_time).add(5, 'seconds')) {
            this.mutations.updateReportInList(report);
          }
        });
        isListUpdateNow = false;
      }
    };

    await updateReport();

    return window.setInterval(updateReport, TIME_MS_UPDATE_REPORT_LIST);
  }

  async fetchReportsUserContent(uuids: UUID[]): Promise<void> {
    if (uuids.length === 0) {
      return;
    }

    const { data } = await this.api.fetchReportsUserContent(uuids);

    data.forEach((item) => {
      const [comments] = item;
      if (comments.error) {
        // eslint-disable-next-line no-console
        console.warn('fetchReportsUserContent comments error:', comments.error);
      } else {
        this.mutations.addCommentsToReportList({ uuids, comments: comments.result.comments });
      }
    });
  }

  async searchReports({
    query, type, pagination, sort,
  }: {
    query: Identifier,
    type?: Type,
    pagination?: Pagination,
    sort?: Sort,
  }): Promise<number> {
    let response = 0;
    const { data: { result, error } } = await this.api.searchReports(query, type, pagination, sort);

    // eslint-disable-next-line no-console
    if (error) console.warn('searchReports error:', error);

    if (result) {
      const paginationOfFetch = pagination || SEARCH_PARAMS.DEFAULT_PAGINATION;
      const indexOfFirstElement = (paginationOfFetch.page - 1) * paginationOfFetch.limit;
      if (indexOfFirstElement > this.state.list.length) {
        // eslint-disable-next-line no-console
        console.warn(`We trying add element on index ${indexOfFirstElement} in array with length ${this.state.list.length}`);
        return response;
      }
      result.reports_list.forEach((report: IReportListEntityWithUserContent, indexInList: number) => {
        this.mutations.setReportListItem({ report, index: indexOfFirstElement + indexInList });
      });
      const reportsUuids = result.reports_list.map(item => item.uuid);
      await this.actions.fetchReportsUserContent(reportsUuids);
      response = reportsUuids.length;
    }
    return response;
  }
}
