import { Actions } from 'vuex-smart-module';

import { Phone, Reason } from '@/rpc-types/profi';
import ProfileApi from '@/services/httpClient/api/profi/profile.api';
import { IRPCError } from '@/services/httpClient/types';
import ProfileGetters from '@/store/modules/profile/getters';
import ProfileMutations from '@/store/modules/profile/mutations';
import ProfileState from '@/store/modules/profile/state';
import { IProfile, IUpdatePassword } from '@/types/entities/profile';

export default class ProfileActions extends Actions<ProfileState, ProfileGetters, ProfileMutations, ProfileActions> {
  api!: ProfileApi;

  $init(): void {
    this.api = new ProfileApi();
  }

  async updateProfile(updatedProfile: IProfile): Promise<IRPCError | void> {
    const {
      data: { error, result },
    } = await this.api.updateProfile(updatedProfile);
    if (error) {
      throw error;
    }

    if (result) {
      this.mutations.updateProfile(result);
    }
  }

  async updatePassword({ old_password, new_password }: IUpdatePassword): Promise<IRPCError | void> {
    const {
      data: { error, result },
    } = await this.api.updatePassword(old_password, new_password);
    if (error) {
      throw error;
    }

    if (result) {
      this.mutations.updateProfile(result);
    }
  }

  async updateProfilePhone(phone: Phone): Promise<IRPCError | void> {
    const {
      data: { error, result },
    } = await this.api.updateProfilePhone(phone);
    if (error) {
      throw error;
    }

    if (result) {
      this.mutations.setNotVerifiedUuid(result.client_verify_uuid);
    }
  }

  async deleteProfile(reason: Reason): Promise<IRPCError | undefined> {
    try {
      const {
        data: { error },
      } = await this.api.deleteProfile(reason);
      if (error) {
        // eslint-disable-next-line no-console
        console.warn('updatePassword error', error);
        return error;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return undefined;
  }
}
