import { requireAuth, requireProductExist } from '@/router/utils';
import multiguard from 'vue-router-multiguard';

const PageSubsPayment = () => import('@/pages/mobile/apps/PageSubscriptionsPayments/PageSubscriptionPayments.vue');
const PageSubsOptions = () => import('@/pages/mobile/apps/PageSubsOptions.vue');
const PageSubscriptionPaymentsUnavailable = () => import('@/pages/mobile/apps/PageSubscriptionsPayments/PageSubscriptionPaymentsUnavailable.vue');
const PageSubs3dsMiddleware = () => import('@/pages/desktop/subs/PageSubs3dsMiddleware.vue');

export default [
  {
    path: '/apps/subs/tariffs/payments',
    name: 'apps-subs-tariffs-payments',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата',
      layout: 'LayoutWebview',
    },
  },
  {
    path: '/apps/subs/additional-reports/payment',
    name: 'apps-subs-additional-reports-payment',
    component: PageSubsPayment,
    beforeEnter: requireAuth,
    meta: {
      title: 'Оплата отчетов',
      layout: 'LayoutWebview',
    },
  },
  {
    path: '/apps/subs/management/payments',
    name: 'app-subs-management-payments',
    component: PageSubsPayment,
    beforeEnter: multiguard([requireAuth, requireProductExist]),
    meta: {
      title: 'Возобновление подписки',
      layout: 'LayoutWebview',
    },
  },
  {
    path: '/apps/subs/options',
    name: 'apps-subs-options',
    component: PageSubsOptions,
    beforeEnter: requireAuth,
    meta: {
      title: 'Настройка платежа',
      layout: 'LayoutWebview',
    },
  },
  {
    path: '/apps/subs/management/unavailable',
    name: 'apps-subs-management-unavailable',
    component: PageSubscriptionPaymentsUnavailable,
    beforeEnter: requireAuth,
    meta: {
      title: 'Возобновление подписки',
      layout: 'LayoutWebview',
    },
  },
  {
    path: 'apps/subs/3ds-middleware',
    name: 'apps-subs-3ds-middleware',
    component: PageSubs3dsMiddleware,
  },
];
