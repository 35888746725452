<script lang="ts">
import Vue from 'vue';
import createGrid from '@/grid/createGrid';

export default Vue.extend({ ...createGrid('g-container') });
</script>

<style lang="scss" scoped>
.g-container {
  display: flex;
  flex-flow: column nowrap;
}
</style>
