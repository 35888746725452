import Vue from 'vue';
import * as Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex-smart-module';

import root from '@/store/root';

Vue.use(Vuex);

const store = createStore(root, {
  strict: process.env.NODE_ENV === 'development',
  plugins: [
    createPersistedState({
      paths: ['device'],
      storage: window.localStorage,
    }),
    createPersistedState({
      paths: ['profile.notVerifiedPhone', 'profile.notVerifiedUuid'],
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
