import { Module, createMapper } from 'vuex-smart-module';

import ContentScrollPositionState from '@/store/modules/content-scroll-position/state';
import ContentScrollPositionMutations from '@/store/modules/content-scroll-position/mutations';

const contentScrollPosition = new Module({
  state: ContentScrollPositionState,
  mutations: ContentScrollPositionMutations,
});

export const contentScrollPositionMapper = createMapper(contentScrollPosition);

export default contentScrollPosition;
