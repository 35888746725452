import Vue from 'vue';
import Router from 'vue-router';

import {
  requireAuth, setMetaTags, routerErrorHandler, getAdmitadHandler, reportPageGuard,
} from '@/router/utils';

import auth from '@/router/desktop/auth';
import subs from '@/router/desktop/subs';
import about from '@/router/desktop/about';
import profile from '@/router/desktop/profile';
import ads from '@/router/desktop/ads';
import guestBuy from '@/router/desktop/guest-buy';
import referral from '@/router/desktop/referral';
import store from '@/store';
import { IdentifierType } from '@/components/legacy/common/general/check-auto/tooltip-data';

const ReportsPage = () => import('@/pages/desktop/ReportsPage.vue');
const NotFound = () => import('@/pages/desktop/errors/PageNotFound.vue');
const CheckAuto = () => import('@/pages/desktop/check-auto/PageCheckAuto.vue');
const PageReport = () => import('@/pages/desktop/report/PageReport.vue');
const PageReportGuest = () => import('@/pages/desktop/report/PageReportGuest.vue');
const PageReportPrint = () => import('@/pages/desktop/report/PageReportPrint.vue');
const PageHome = () => import('@/pages/desktop/PageHome.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home/:entry*',
      name: 'home',
      meta: {
        title: 'Главная',
      },
      component: PageHome,
      beforeEnter: requireAuth,
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsPage,
      meta: {
        title: 'Проверенные автомобили',
        description: 'Проверяйте юридическую чистоту, историю объявлений, определяйте рыночную стоимость автомобилей. Попробуйте наши инструменты для профессионалов авторынка уже сегодня!  ',
      },
      beforeEnter: requireAuth,
    },
    {
      path: '/reports/check-auto',
      name: 'check-auto',
      component: CheckAuto,
      meta: {
        title: 'Проверка автомобиля',
      },
      beforeEnter: requireAuth,
      props: (route: any) => ({
        type: route.query.type as IdentifierType,
        query: route.query.query as string,
        adsLink: route.query.ads_link,
        queryHashed: (route.query.hash === 'true') as Boolean,
      }),
    },
    {
      path: '/report/:uuid',
      name: 'report',
      component: PageReport,
      beforeEnter: reportPageGuard,
      props: true,
      meta: {
        title: 'Отчёт',
      },
    },
    {
      path: '/report/guest/:uuid',
      name: 'report-guest',
      component: PageReportGuest,
      props: true,
      meta: {
        title: 'Отчёт',
        layout: 'LayoutGuest',
      },
    },
    {
      path: '/report/print/:uuid',
      name: 'report-print',
      component: PageReportPrint,
      props: true,
      meta: {
        layout: 'LayoutPrint',
        title: 'Версия для печати',
      },
    },
    ...ads,
    ...auth,
    ...subs,
    ...about,
    ...profile,
    ...guestBuy,
    ...referral,
    {
      path: '*',
      component: NotFound,
    },
  ],
});

router.beforeEach(setMetaTags);
router.beforeEach(getAdmitadHandler(store));
router.onError(routerErrorHandler);

export default router;
