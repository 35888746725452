import { Module, createMapper } from 'vuex-smart-module';

import PoliciesState from '@/store/modules/policies/state';
import PoliciesGetters from '@/store/modules/policies/getters';
import PoliciesMutations from '@/store/modules/policies/mutations';
import PoliciesActions from '@/store/modules/policies/actions';

const policies = new Module({
  state: PoliciesState,
  getters: PoliciesGetters,
  mutations: PoliciesMutations,
  actions: PoliciesActions,
});

export const policiesMapper = createMapper(policies);

export default policies;
