<script lang="ts">
import Vue, { CreateElement, RenderContext, VNode } from 'vue';

export default Vue.extend({
  name: 'GSpacer',
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode {
    const { data, children } = context;
    data.staticClass = 'g-spacer';

    return h('div', data, children);
  },
});
</script>

<style lang="scss" scoped>
.g-spacer {
  flex-grow: 1;
}
</style>
