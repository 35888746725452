<template>
  <div :class="containerClasses">
    <transition-group
      :name="transitionName"
      :class="$style.list"
      tag="ul"
      @after-leave="afterLeave"
    >
      <li
        v-for="message in messages"
        :key="message.id"
        :class="$style.contentWrapper"
        :data-id="message.id"
      >
        <app-notification-item
          :message="message"
          @close="closeNotification"
          @click-action="onClickAction"
        />
      </li>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import eventBus from '@/utils/eventBus';

import { INotification } from '@/types/components/common-app-notification';

import AppNotificationItem from '@/components/legacy/common/desktop/notifications/AppNotificationItem.vue';
import { appMapper } from '@/store/modules/app';

interface IData {
  messages: INotification[];
}

export default defineComponent({
  name: 'AppNotifications',
  components: {
    AppNotificationItem,
  },
  data(): IData {
    return {
      messages: [],
    };
  },

  created() {
    eventBus.on('pushNotification', this.pushNotificationHandler);
  },

  beforeDestroy() {
    eventBus.off('pushNotification', this.pushNotificationHandler);
  },

  computed: {
    ...appMapper.mapState(['isMobile']),
    containerClasses() {
      const classes = [];
      classes.push(this.$style.container);

      if (this.isMobile) {
        classes.push(this.$style.containerMobile);
      }

      return classes;
    },

    transitionName() {
      /* todo поправить анимацию исчезновения уведомлений на мобилке */
      return this.isMobile ? '' : 'push';
    },
  },

  methods: {
    pushNotificationHandler(msg: INotification): void {
      this.messages.push(msg);
    },

    closeNotification(id: string) {
      this.messages = this.messages.filter((msg) => msg.id !== id);
    },

    onClickAction(id: string) {
      const message = this.messages.find((msg) => msg.id === id);

      if (message && message.action) {
        message.action();
      }
    },

    afterLeave(el: HTMLElement) {
      if (el.dataset.id) {
        eventBus.emit('closeNotificationAfterAction', el.dataset.id);
      }
    },
  },
});
</script>

<style lang="scss" module>
.container {
  position: fixed;
  bottom: rem($grid-gutter * 18);
  left: rem($grid-gutter * 4);
  z-index: $z-idx-super;
  margin: rem(-$grid-gutter * 1.25) 0;

  .contentWrapper {
    display: flex;
    padding: rem($grid-gutter * 1.25) 0;
  }
}

.containerMobile {
  top: rem($grid-gutter * 11);
  bottom: auto;
}
</style>

<style lang="scss" scoped>
.push {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-110%);
  }

  &-enter-active,
  &-move,
  &-leave-active {
    transition:
      transform 0.25s ease,
      opacity 1s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }
}
</style>
