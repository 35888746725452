/**
 * This file was automatically generated
 */

 export const empty_report_matrix = {
  identifiers: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    },
    manufacture: {
      vin: null
    }
  },
  identifiers_masked: {
    vehicle: {
      vin: null,
      reg_num: null,
      sts: null,
      pts: null,
      body: null,
      chassis: null
    }
  },
  tech_data: {
    manufacturer: {
      name: null
    },
    brand: {
      id: null,
      name: {
        original: null,
        rus: null,
        normalized: null
      },
      logotype: {
        uri: null
      }
    },
    model: {
      id: null,
      name: {
        original: null,
        normalized: null
      }
    },
    type: {
      name: null,
      type_id: null,
      code: null
    },
    body: {
      number: null,
      color: {
        name: null,
        type: null
      }
    },
    year: null,
    chassis: {
      number: null
    },
    engine: {
      fuel: {
        type: null,
        type_id: null
      },
      number: null,
      model: {
        name: null
      },
      volume: null,
      power: {
        hp: null,
        kw: null
      },
      standarts: {
        emission: {
          euro: null
        }
      }
    },
    weight: {
      netto: null,
      max: null
    },
    transmission: {
      type: null,
      type_id: null
    },
    drive: {
      type: null,
      type_id: null
    },
    wheel: {
      position: null,
      position_id: null,
      position_code: null
    },
    generations: [],
    date: {
      update: null
    }
  },
  additional_info: {
    vehicle: {
      category: {
        code: null,
        description: null
      },
      owner: {
        type: null,
        phone_number: null,
        enforcement_proceedings: {
          has_proceedings: null
        },
        geo: {
          country: null,
          region: null,
          city: null,
          street: null,
          house: null,
          postal_code: null
        },
        taxi_history: {
          probable_taxi_driver: null,
          taxi_licenses: {
            items: [],
            actuality: {
              date: null
            }
          },
          probable_use: {
            items: []
          }
        }
      },
      passport: {
        date: {
          receive: null
        },
        has_dublicate: null,
        number: null,
        org: {
          name: null
        }
      },
      sts: {
        date: {
          receive: null
        }
      },
      modifications: {
        was_modificated: null
      },
      notes: null,
      segment: {
        euro: {
          code: null,
          description: null
        }
      },
      exported: null,
      techreglament_category: {
        code: null,
        description: null
      }
    },
    catalog: {
      transdekra: {
        items: []
      },
      rsa: {
        items: []
      },
      tecdoc: {
        items: []
      },
      oats: {
        items: []
      }
    },
    identifiers: {
      vin: {
        checksum_validated: null
      }
    },
    driver: {
      mileages: {
        average_annual: {
          value: null,
          calculation_options: {
            period: {
              start: null,
              end: null
            },
            vehicle: {
              count: null
            },
            mileages: {
              count: null
            }
          },
          is_calculated: false
        }
      }
    }
  },
  registration_actions: {
    items: [],
    date: {
      update: null
    },
    count: 0,
    has_annulment: null
  },
  repairs: {
    history: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  gots_auctions: {
    items: [],
    date: {
      update: null
    }
  },
  carfax: {
    check: {
      exists: null,
      records: {
        count: null
      },
      vehicle: {
        model: {
          name: null
        }
      }
    }
  },
  calculate: {
    tax: {
      moscow: {
        yearly: {
          amount: null
        }
      },
      regions: {
        yearly: {
          amount: null
        }
      }
    },
    osago: {
      coefficients: {
        regional: {
          value: null
        }
      },
      price: {
        current: {
          region: {
            name: null
          },
          city: {
            name: null
          },
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        },
        moscow: {
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        },
        moscow_region: {
          yearly: {
            amount: null,
            min: null,
            max: null
          }
        }
      }
    }
  },
  car_price: {
    items: [],
    count: 0
  },
  fines: {
    items: [],
    has_fines: null,
    date: {
      update: null
    },
    count: 0
  },
  leasings: {
    items: [],
    used_in_leasing: null,
    date: {
      update: null
    },
    count: 0
  },
  customs: {
    history: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  taxi: {
    history: {
      items: [],
      count: 0
    },
    used_in_taxi: null
  },
  taxi_analytics: {
    probable_use: {
      is_used: null
    }
  },
  pledges: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  pledges_nbki: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  insurance: {
    osago: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  accidents: {
    statistics: {
      date: {
        update: null
      },
      items: [],
      count: 0
    },
    history: {
      date: {
        update: null
      },
      items: [],
      count: 0
    },
    insurance: {
      date: {
        update: null
      },
      items: [],
      count: 0
    },
    has_accidents: null
  },
  restrictions: {
    registration_actions: {
      date: {
        update: null
      },
      items: [],
      count: 0,
      has_restrictions: null
    },
    registration_actions_archive: {
      date: {
        update: null
      },
      items: [],
      count: 0,
      has_restrictions: null
    }
  },
  stealings: {
    is_wanted: null,
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  ownership: {
    history: {
      items: [],
      usage: {
        duration_iso: null,
        days: null
      },
      date: {
        update: null
      },
      count: 0
    }
  },
  mileages: {
    items: [],
    count: 0
  },
  diagnostic_cards: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  utilizations: {
    items: [],
    was_utilized: null,
    date: {
      update: null
    },
    count: 0
  },
  images: {
    photos: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  ads: {
    history: {
      items: [],
      date: {
        update: null
      },
      count: 0
    }
  },
  service_history: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  market_prices: {
    ads: {
      items: [],
      date: {
        update: null
      }
    },
    appraisal: {
      amount: {
        min: null,
        max: null,
        optimal: null
      },
      reasons: {
        used_in_taxi: null,
        accidents: {
          count: null
        },
        ownerships: {
          count: null
        }
      },
      date: {
        update: null
      }
    }
  },
  commercial_use: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  recall_campaigns: {
    items: [],
    date: {
      update: null
    },
    count: 0
  },
  arbitration: {
    count: 0,
    items: [],
    date: {
      update: null
    }
  },
  driver_licenses: {
    items: [],
    has_deprivations: null,
    date: {
      update: null
    },
    count: 0
  },
  executive_procedures: {
    items: [],
    count: 0
  }
}