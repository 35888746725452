import { Mutations } from 'vuex-smart-module';

import PPSpectrumdataState from '@/store/modules/ppSpectrumdata/state';
import { ClientPP, TStatus } from '@/store/modules/ppSpectrumdata/types';
import { Status } from '@/types/statuses';

export default class PPSpectrumdataMutations extends Mutations<PPSpectrumdataState> {
  setStatusInPP(check: TStatus) {
    this.state.statusInPP = check;
  }

  setStatusLoading(check: Status) {
    this.state.statusLoading = check;
  }

  setClientPP(clientPP: ClientPP) {
    this.state.clientPP = clientPP;
  }

  reset(): void {
    const state = new PPSpectrumdataState();
    Object.keys(state).forEach((key) => {
      // @ts-ignore
      this.state[key] = state[key];
    });
  }
}
