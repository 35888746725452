import { requireAuth } from '@/router/utils';

const PageAboutService = () => import('@/pages/common/about/PageAboutService.vue');
const PageUserAgreement = () => import('@/pages/common/about/PageUserAgreement.vue');
const PagePrivacyPolicy = () => import('@/pages/common/about/PagePrivacyPolicy.vue');
const LicenseAgreement = () => import('@/pages/common/about/PageLicenseAgreement.vue');

export default [
  {
    path: '/about',
    name: 'about-service',
    component: PageAboutService,
    beforeEnter: requireAuth,
    meta: {
      title: 'О нас',
      description: 'Сервис безлимитных детальных проверок автомобилей для профессионалов рынка. Присоединяйтесь к нам!',
    },
  },
  {
    path: '/about/agreement',
    name: 'user-agreement',
    component: PageUserAgreement,
    meta: {
      title: 'Пользовательское соглашение',
      description: 'Сервис безлимитных детальных проверок автомобилей для профессионалов рынка — Автокод Профи',
    },
  },
  {
    path: '/about/privacy',
    name: 'privacy-policy',
    component: PagePrivacyPolicy,
    meta: {
      title: 'Политика конфиденциальности',
      description: 'Политика обработки персональных данных пользователей сервиса Автокод Профи',
    },
  },
  {
    path: '/about/license',
    name: 'license-agreement',
    component: LicenseAgreement,
    meta: {
      title: 'Лицензионное соглашение',
      description: 'Лицензионное соглашение пользователей сервиса Автокод Профи',
    },
  },
];
