import { debounce } from 'lodash';

import { IBreakpoint, IBreakpointThresholds } from '@/types/plugins/breakpoint';

export default class Breakpoint implements IBreakpoint {
  xs = false;
  sm = false;
  md = false;
  lg = false;
  xl = false;

  height = 0;
  width = 0;

  scrollBarWidth: number;
  thresholds: IBreakpointThresholds;

  constructor(options: { scrollBarWidth: number, thresholds: IBreakpointThresholds }) {
    this.scrollBarWidth = options.scrollBarWidth;
    this.thresholds = options.thresholds;

    this.init();
  }

  get xsOnly(): boolean {
    return this.xs;
  }
  get smOnly(): boolean {
    return this.sm;
  }
  get mdOnly(): boolean {
    return this.md;
  }
  get lgOnly(): boolean {
    return this.lg;
  }
  get xlOnly(): boolean {
    return this.xl;
  }

  get smAndDown(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return (xs || sm) && !(md || lg || xl);
  }

  get smAndUp(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return !xs && (sm || md || lg || xl);
  }

  get mdAndDown(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return (xs || sm || md) && !(lg || xl);
  }

  get mdAndUp(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return !(xs || sm) && (md || lg || xl);
  }

  get lgAndDown(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return (xs || sm || md || lg) && !xl;
  }

  get lgAndUp(): boolean {
    const {
      xs, sm, md, lg, xl,
    } = this;
    return !(xs || sm || md) && (lg || xl);
  }

  init(): void {
    this.update();
    window.addEventListener(
      'resize',
      debounce(this.update.bind(this), 200),
      { passive: true },
    );
  }

  private update(): void {
    this.height = this.getClientHeight();
    this.width = this.getClientWidth();

    this.xs = this.width < this.thresholds.xs;
    this.sm = this.width < this.thresholds.sm && !this.xs;
    this.md = this.width < (this.thresholds.md - this.scrollBarWidth) && !(this.sm || this.xs);
    this.lg = this.width < (this.thresholds.lg - this.scrollBarWidth) && !(this.md || this.sm || this.xs);
    this.xl = this.width >= (this.thresholds.lg - this.scrollBarWidth);
  }

  private getClientWidth(): number {
    if (document === undefined) return 0;
    return Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );
  }

  private getClientHeight(): number {
    if (document === undefined) return 0;
    return Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
  }
}
